import { render, staticRenderFns } from "./UnclaimedGoodsClaim.vue?vue&type=template&id=2758ac65&scoped=true"
import script from "./UnclaimedGoodsClaim.vue?vue&type=script&lang=js"
export * from "./UnclaimedGoodsClaim.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2758ac65",
  null
  
)

export default component.exports