import { render, staticRenderFns } from "./InventoryStatistics.vue?vue&type=template&id=66ee2ea5&scoped=true"
import script from "./InventoryStatistics.vue?vue&type=script&lang=js"
export * from "./InventoryStatistics.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66ee2ea5",
  null
  
)

export default component.exports