import { render, staticRenderFns } from "./FeeModelById.vue?vue&type=template&id=62a3b0d6&scoped=true"
import script from "./FeeModelById.vue?vue&type=script&lang=js"
export * from "./FeeModelById.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62a3b0d6",
  null
  
)

export default component.exports