var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"20px","text-align":"center"}},[_c('div',{staticStyle:{"padding":"20px 10px","font-size":"16px"}},[(!_vm.authSrcStep2)?_c('div',{staticClass:"step-con"},[_c('div',{staticClass:"step-title"},[_vm._v("STEP1")]),_c('div',{staticClass:"step-msg"},[_vm._v("正在打开授权页面,请稍候...")]),_vm._m(0),_c('div',{staticClass:"auth-icon-con"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"auth-icon-hytx"},[_c('img',{attrs:{"src":_vm.$store.state.sysShow.logoInfo.logo,"width":"150","height":"auto"}})])])]):_vm._e(),(_vm.authSrcStep2)?_c('div',{staticClass:"step-con"},[_c('div',{staticClass:"step-title"},[_vm._v("STEP2")]),_c('div',{staticClass:"step-msg"},[_vm._v(_vm._s(_vm.$t('i18nn_67c2289162532917')))]),_vm._m(3),_c('div',{staticClass:"auth-icon-con"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"auth-icon-hytx"},[_c('img',{attrs:{"src":_vm.$store.state.sysShow.logoInfo.logo,"width":"150","height":"auto"}})])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-loading-icon"},[_c('i',{staticClass:"el-icon-loading"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-icon-az"},[_c('img',{attrs:{"src":require("../../../assets/images/amazon/800px-Amazon_logo.png"),"width":"150","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-icon-arrow"},[_c('img',{attrs:{"src":require("../../../assets/images/amazon/arrow-bothway.png"),"width":"30","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-loading-icon"},[_c('i',{staticClass:"el-icon-loading"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-icon-az"},[_c('img',{attrs:{"src":require("../../../assets/images/amazon/800px-Amazon_logo.png"),"width":"150","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-icon-arrow"},[_c('img',{attrs:{"src":require("../../../assets/images/amazon/arrow-bothway.png"),"width":"30","height":"auto"}})])
}]

export { render, staticRenderFns }