
export const calcHeight = (minHeight,cutHeight) => {
	//浏览器内容区基本高度
	const baseWinHeight = window.innerHeight || document.body.clientHeight;
	//表格高度
	// const tabelHeightCal = (offset) => {
		return (baseWinHeight - cutHeight) > minHeight ? (baseWinHeight - cutHeight) : minHeight
	// }
}


export const settings = (columns,option,nestedHeadersTop,hiddenColumns) => {
	let DataRowObj = {};
	columns.map(item => {
		DataRowObj[item.data] = null;
	});

	let dataObject = [DataRowObj];
	let colHeaders = columns.map(item => {
		return item.name;
	});
	let colHeadersEn = columns.map(item => {
		return item.nameEn;
	});
	let colWidths = columns.map(item => {
		if (item.width) {
			return item.width;
		} else {
			return 100;
		}
	});
	let nestedHeaders = nestedHeadersTop.concat([colHeadersEn,colHeaders]);
	let config = {
		data: dataObject,
		className: 'htLeft',
		licenseKey: 'non-commercial-and-evaluation',
		// stretchH: 'all',
		//   width: 805,
		// autoWrapRow: true,
		// height: 487,
		// maxRows: 60,
		manualRowResize: true,
		manualColumnResize: true,
		// rowHeaders: true,
		manualRowMove: true,
		manualColumnMove: true,
		contextMenu: true,
		// filters: true,
		// dropdownMenu: true,
		copyable: true,
		trimWhitespace: true,// false表示不去除空格（复制粘贴是时候无效，还是会有，又加了函数去空格
		columns: columns,
		// hiddenColumns: true,
		// hiddenColumns: {
		// 	columns: [3],
		// 	// indicators: true,
		// 	// exclude hidden columns from copying and pasting
		// 	copyPasteEnabled: false
		// },
		stretchH: 'all',
		width: '100%',
		autoWrapRow: true,
		// minHeight: 100,
		// height: option.height,
		height: 400,
		// height: 'auto',
		// height: '100%',
		colWidths: colWidths,
		rowHeights: 25,
		// rowHeaders: true,
		// colHeaders: true,
		minRows: 1,
		// maxRows: 22,
		rowHeaders: true,
		nestedHeaders: nestedHeaders,
		// colHeaders: colHeaders,
		contextMenu: {
			// 单元格右击展示菜单
			items: {
				// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
				// hsep6: '---------',
				// upload_img: {
				//   name: '上传图片',
				//   // callback: (key, selection, clickEvent) => {
				//   callback: function(key, selection, clickEvent) {
				//     console.log(arguments);
				//     _vue_this.openUpLoad();
				//     _vue_this.selCell = selection[0];
				//     console.log(_vue_this.selCell);
				//   }
				// },
				// hsep6: '---------',
				row_below: {
					name: '下面插入行(below)'
				},
				row_above: {
					name: '上面插入行(above)'
				},

				// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
				// hsep1: '---------',
				// col_left: {
				// 	name: '左侧插入列'
				// },
				// col_right: {
				// 	name: '右侧插入列'
				// },
				// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
				hsep2: '---------',
				remove_row: {
					name: '移除该行(remove)'
				},
				// remove_col: {
				// 	name: '移除该列'
				// },
				// "undo", "redo"
				hsep7: '---------',
				// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
				undo: {
					name: '撤销(undo)'
				},
				redo: {
					name: '恢复(redo)'
				},
				// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
				hsep3: '---------',
				make_read_only: {
					name: '只读(read_only)'
				},
				// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
				alignment: {
					name: '对齐方式(alignment)'
				},
				// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
				// 'borders':{
				//   name: '边框'
				// },
				hsep4: '---------',
				copy: {
					name: '复制(copy)'
				},
				cut: {
					name: '剪切(cut)'
				},
				// separator: Handsontable.plugins.ContextMenu.SEPARATOR,
				hsep5: '---------',
				clear_custom: {
					name: '清空所有单元格数据(clear)',
					callback: function() {
						this.clear();
					}
				},
			}
		},
		language: 'zh-CN'
	}
	
	if(hiddenColumns){
		config.hiddenColumns = hiddenColumns;
	}
	
	config = Object.assign(config,option?option:{});
	return {
		config:config,
		DataRowObj:DataRowObj
	};
}
