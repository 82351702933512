import html2canvas from "html2canvas";
// import Base64 from "base-64";
import Vue from 'vue'

import JSZip from 'jszip'
import FileSaver from 'file-saver'

import {
	jsPDF
} from "jspdf";

// import '@/utils/weiruanhheiti-normal';

import JsBarcode from 'jsbarcode';
// var JsBarcode = require('jsbarcode');
// console.log('JsBarcode',JsBarcode);
// var R_JsBarcode = JsBarcode;
import JQ from 'jquery';

import {
	base64ToBlob
} from "@/utils/fileZipDownload.js";

import {
	Loading
} from 'element-ui';

import {
	Notification,
	Message
} from 'element-ui';

// const fetchFont = function () {
//   this.addFont("/lib/jspdf/SourceHanSans-Normal", "SourceHanSans", "normal");
// };
// jsPDF.API.events.push(["addFonts", fetchFont]);

//库位
export const exportPdfByPlace = (name, DomId) => {
	let loadingInstance = Loading.service({
		lock: true,
		background: 'rgba(0,0,0,0.5)'
	});

	return new Promise((resolve, reject) => {

		let topMargin = 10;
		let leftMargin = 2;
		// let [imgX, imgY] = [595.28, 841.89]; // a4纸尺寸[595.28, 841.89]; 面单尺寸，4*6in[152.4*101.6mm]
		// 一英寸=72pt(点)=96px(像素) 
		let pdf = new jsPDF({
			// orientation: 'p',
			// orientation:'portrait',
			orientation: 'landscape',
			unit: 'mm',
			format: [150, 100], //'a4',
			// putOnlyUsedFonts:true,
			// floatPrecision: 16 // or "smart", default is 16
		});
		let dom_root_img_list = JQ(DomId).find("img");
		// let pdf = new jsPDF();
		for (let i = 0, ln = dom_root_img_list.length; i < ln; i++) {
			// let item  = listData[i];
			//logo
			// let logoImg = '/static/image/logo_en.png';
			// pdf.addImage(logoImg, 'JPEG', leftMargin, topMargin, 80,22);

			// let barCodeTagList = dom_root;
			let barCode = dom_root_img_list.eq(i);
			//判断一维码时候匹配。防止出现意外情况
			let code = barCode.attr('code');
			let img_src = barCode.attr('src');
			let width = barCode.width() / 2;
			let height = barCode.height() / 2;
			// let height_offset = 10; //高度偏移量
			// console.log("barCode", code, width, height);
			if (!code || !img_src) {
				Notification({
					message: '第' + i + "条:" + code + ',加载失败...',
					type: 'waning'
				});
				// return;
			}
			// Notification({
			// 	message: '第'+i+"条:"+code + ',正在准备...',
			// 	type: 'success'
			// });
			// const image = item.imageList[j];
			// let imgHeight = imgX / (image.width / image.height);
			// pdf.addImage(image.data, 'JPEG', 0, isAddpage, imgX, imgHeight);
			// let modPage = 0;
			// if (i == 0) {
			// 	modPage = 0;
			// } else {
			// 	modPage = i % 5; //每五个分一页
			// }
			// 对比数据索引和code索引一致
			// if(barCode && code==item.place){
			// pdf.addImage(img_src, 'JPEG', leftMargin, topMargin + (height + height_offset) * modPage, width,
			// 	height);
			pdf.addImage(img_src, 'JPEG', leftMargin, topMargin, width,
				height);
			// pdf.addImage({
			//    imageData: img_src,
			//    x: leftMargin,
			//    y: topMargin + (height + height_offset) * modPage,
			//    width: width,
			//    height: height,
			//    format: "jpg",
			// 	 compression: 'NONE'
			// });

			// }
			// if (i != 0 && modPage == 4) { //索引4时即为第五个
			// pdf.addPage();
			// }
			if (i < ln - 1) {
				pdf.addPage();
			}
		}
		let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
		pdf.save(name + "_" + dateStr + ".pdf");
		resolve();
		loadingInstance.close();
	});

}

//SKU
export const exportPdfBySku = (name, DomId, option) => {
	let loadingInstance = Loading.service({
		lock: true,
		background: 'rgba(0,0,0,0.5)'
	});

	let formatSize = [];
	if ('1' == option.size) {
		formatSize = [150, 50];
	} else if ('2' == option.size) {
		formatSize = [150, 80];
	} else if ('3' == option.size) {
		formatSize = [150, 100];
	} else if ('4' == option.size) {
		formatSize = [200, 100];
	} else if ('5' == option.size) {
		formatSize = [300, 100];
	} else if ('6' == option.size) {
		formatSize = [400, 100];
	} else {
		formatSize = [150, 100];
	}

	return new Promise((resolve, reject) => {

		let topMargin = 0;
		let leftMargin = 2;

		let scalCal = 3;
		// let [imgX, imgY] = [595.28, 841.89]; // a4纸尺寸[595.28, 841.89]; 面单尺寸，4*6in[152.4*101.6mm]
		// 一英寸=72pt(点)=96px(像素) 
		let pdf = new jsPDF({
			// orientation: 'p',
			// orientation:'portrait',
			orientation: 'landscape',
			unit: 'mm',
			format: formatSize, //4*6in
			// format: [595.28, 841.89],//'a4',
			// putOnlyUsedFonts:true,
			// floatPrecision: 16 // or "smart", default is 16
		});

		// pdf.setFont('weiruanhheiti');

		// console.log('getFontList',pdf.getFontList());
		let dom_bar_list = JQ(DomId).find(".codeBarItem");
		// let pdf = new jsPDF();
		for (let i = 0, ln = dom_bar_list.length; i < ln; i++) {
			// let item  = listData[i];
			//logo
			// let logoImg = '/static/image/logo_en.png';
			// pdf.addImage(logoImg, 'JPEG', leftMargin, topMargin, 80,22);
			// let dom_root_img_list = JQ(DomId).find("img");
			// let barCodeTagList = dom_root;
			let barCodeItem = dom_bar_list.eq(i);
			let barCode = barCodeItem.find('.code_img').find('img');
			//判断一维码时候匹配。防止出现意外情况
			let code = barCode.attr('code');
			let img_src = barCode.attr('src');
			let width = parseFloat((barCode.width() / scalCal).toFixed(2)); //数值类型
			let height = parseFloat((barCode.height() / scalCal).toFixed(2)); //数值类型
			// let height_offset = 10; //高度偏移量
			console.log("barCode", img_src, code, width, height);
			if (!code || !img_src) {
				// Notification({
				// 	message: '第'+i+"条:"+code + ',加载失败...',
				// 	type: 'waning'
				// });
				console.log('第' + i + "条:" + code + ',加载失败...');
				// return;
			}

			pdf.addImage(img_src, 'png', leftMargin, topMargin, width,
				height);

			if (option.showSkuName) {
				try {
					let goodsName = barCodeItem.find('.code_name').find('img');
					// pdf.setFontSize(20);
					// pdf.text(leftMargin, topMargin+60, goodsName);
					pdf.addImage(goodsName.attr('src'), 'png', leftMargin, topMargin + 60, parseFloat((goodsName.width() /
							scalCal).toFixed(2)),
						parseFloat((goodsName.height() / scalCal).toFixed(2)));
					// console.log(goodsName);
				} catch (err) {
					console.log(err);
					// Notification({
					// 	message: '第'+i+"条"+code + 'goodsName,加载失败...',
					// 	type: 'waning'
					// });
					console.log('第' + i + "条" + code + 'goodsName,加载失败...');
				}

			}

			if (option.showCus) {
				let userId = barCodeItem.find('.code_user').text();
				pdf.setFontSize(10);
				pdf.text(leftMargin, topMargin + 60 + 10, userId);
			}

			if (option.addText && option.addText.length > 0) {
				let addText_list = barCodeItem.find('.code_mark').find('span');
				pdf.setFontSize(10);
				if (addText_list.length >= 1) {
					pdf.text(leftMargin, topMargin + 60 + 10 + 10, addText_list.eq(0).text());
				}
				if (addText_list.length >= 2) {
					pdf.text(leftMargin + 100, topMargin + 60 + 10 + 10, addText_list.eq(1).text());
				}
			}

			// console.log(goodsName,userId,addText_list);

			// Notification({
			// 	message: '第'+i+"条:"+code + ',正在准备...',
			// 	type: 'success'
			// });
			// const image = item.imageList[j];
			// let imgHeight = imgX / (image.width / image.height);
			// pdf.addImage(image.data, 'JPEG', 0, isAddpage, imgX, imgHeight);
			// let modPage = 0;
			// if (i == 0) {
			// 	modPage = 0;
			// } else {
			// 	modPage = i % 5; //每五个分一页
			// }
			// 对比数据索引和code索引一致
			// if(barCode && code==item.place){
			// pdf.addImage(img_src, 'JPEG', leftMargin, topMargin + (height + height_offset) * modPage, width,
			// 	height);

			// pdf.addImage({
			//    imageData: img_src,
			//    x: leftMargin,
			//    y: topMargin + (height + height_offset) * modPage,
			//    width: width,
			//    height: height,
			//    format: "jpg",
			// 	 compression: 'NONE'
			// });

			// }
			// if (i != 0 && modPage == 4) { //索引4时即为第五个
			// pdf.addPage();
			// }
			if (i < ln - 1) {
				pdf.addPage();
			}
		}
		let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
		pdf.save(name + "_" + dateStr + ".pdf");
		resolve();
		loadingInstance.close();
	});

}

//sku图片-批量导出
export const exportImgBySku = (zipName, DomId) => {
	let loadingInstance = Loading.service({
		lock: true,
		background: 'rgba(0,0,0,0.5)'
	});
	const zip = new JSZip()
	return new Promise((resolve, reject) => {
		let scalCal = 3;
		let dom_bar_list = JQ(DomId).find(".codeBarItem");
		for (let i = 0, ln = dom_bar_list.length; i < ln; i++) {
			let barCodeItem = dom_bar_list.eq(i);
			let barCode = barCodeItem.find('.code_img').find('img');
			//判断一维码时候匹配。防止出现意外情况
			let code = barCode.attr('code');
			let img_src = barCode.attr('src');
			// let width = parseFloat((barCode.width() / scalCal).toFixed(2)); //数值类型
			// let height = parseFloat((barCode.height() / scalCal).toFixed(2)); //数值类型
			// let height_offset = 10; //高度偏移量
			console.log("barCode", img_src, code);
			if (!code || !img_src) {
				console.log('第' + i + "条:" + code + ',加载失败...');
			}
			let file_name = `${code}.png`;
			zip.file(file_name, base64ToBlob(img_src), {
				binary: true
			});
		}
		
		zip.generateAsync({
			type: 'blob'
		}).then(content => {
			let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
			loadingInstance.close();
			// 生成二进制流
			FileSaver.saveAs(content, zipName + '_' + dateStr + '.zip') // 利用file-saver保存文件  自定义文件名
			// saveAs(content, '文件下载.zip') // 利用file-saver保存文件  自定义文件名
			resolve();
		})
		
		
		// let fileList = [{
		// }];
		// fileZipAndDownloadReName(fileList, zipName);
		// zip.file(file_name, data, {
		// 	binary: true
		// });
		// loadingInstance.close();
	});
}


//箱唛
// export const exportPdfByShippingMark = (listData, name) => {
// 	let loadingInstance = Loading.service({
// 		lock: true,
// 		background: 'rgba(0,0,0,0.5)'
// 	});

// 	let topMargin = 20;
// 	let leftMargin = 20;

// 	// 一英寸=72pt(点)=96px(像素) 
// 	let pdf = new jsPDF({
// 		// orientation: 'p',
// 		unit: 'pt',
// 		format: 'a4',
// 		// putOnlyUsedFonts:true,
// 		// floatPrecision: 16 // or "smart", default is 16
// 	});
// 	// let pdf = new jsPDF();
// 	for (let i = 0, ln = listData.length; i < ln; i++) {
// 		let item = listData[i];
// 		//logo
// 		let logoImg = '/static/image/logo_en.png';
// 		let logoW = 80,
// 			logoH = 22;
// 		pdf.addImage(logoImg, 'JPEG', leftMargin, topMargin, logoW, logoH);

// 		pdf.setFontSize(30);
// 		pdf.text(leftMargin, topMargin + 80, item.cusName);
// 		pdf.setFontSize(18);
// 		pdf.text(leftMargin + 360, topMargin + 80, 'Total:' + item.totalPCS);
// 		pdf.setFontSize(18);
// 		pdf.text(leftMargin, topMargin + 100 + 18, item.goodsSku);
// 		pdf.setFontSize(18);
// 		pdf.text(leftMargin + 360, topMargin + 100 + 18, 'CTN/PCS:' + item.ctnPCS);
// 		pdf.setFontSize(36);
// 		pdf.text(leftMargin, topMargin + 300, item.packingNo);

// 		// barCode he listData数量应该一样，一个箱唛一个code
// 		let barCodeTagList = document.getElementsByClassName("barcode");
// 		let barCode = barCodeTagList[i];
// 		//判断一维码时候匹配。防止出现意外情况
// 		let code = barCode.getAttribute('code');
// 		console.log("code", code);
// 		if (barCode && code == item.code) {
// 			pdf.addImage(barCode, 'JPEG', leftMargin, topMargin + 300 + 36, 486, 142);
// 		}
// 		if (i < ln - 1) {
// 			pdf.addPage();
// 		}

// 	}
// 	let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
// 	pdf.save(name + "_" + dateStr + ".pdf");

// 	loadingInstance.close();
// }

//HTML导出
export const exportPdfByPrint = async (docName, domClassName) => {

	let loadingInstance = Loading.service({
		lock: true,
		background: 'rgba(0,0,0,0.5)'
	});

	const scale = 2; //缩放程度，清晰度，越大越清晰，图片也越大
	// let printEle, //截图按钮
	//     pdfEle, //待截取标签
	//     canvasBox //canvas显示区域
	// let _dom = document;
	// printEle = _dom.querySelector('#printEle')
	let pdfEle = document.querySelector(domClassName); //待截取标签
	// let canvasBox = document.querySelector('#canvasBox');//canvas显示区域

	//执行导出
	const canvas = await html2canvas(pdfEle, { //截取标签转换为canvas
		canvas: createCanvas(pdfEle, scale),
		useCORS: true, // 【重要】开启跨域配置
		background: '#FFFFFF'
	})
	downloadPdf(docName, canvas, scale);
	// pdfEle.hidden = true //隐藏之前的元素，更好对比
	// canvasBox.appendChild(canvas) //显示效果		
	loadingInstance.close();

}

//导出一维码
//关联编号扫码打印
export const exportPdfByBarCode = (code, sizeType) => {
	let Width = "100%";
	let Height = "100%";
	if (1 == sizeType) {
		// 0.1 英寸=2.54 毫米 = 9.6像素
		// Width = "70mm";
		// Height = "30mm";
		Width = 70 * 2.54;
		Height = 30 * 2.54;
	} else if (2 == sizeType) {
		// Width = "2.25in";
		// Height = "1.25in";
		Width = 2.25 * 10 * 2.54;
		Height = 1.25 * 10 * 2.54;
	} else {

	}

	console.log('Width,Height', Width, Height);

	// let pdf = new jsPdf({
	// 	unit: "in",
	// 	format: [2.25, 1.25]
	// });
	let pdf = new jsPDF('', 'pt', 'a4');

	// if (1 == sizeType) {
	// 	pdf = new jsPDF({
	// 		// orientation: "landscape",
	// 		orientation: 'l',
	// 		// orientation: 'p',
	// 		unit: "mm",
	// 		format: [70, 30]
	// 	});
	// } else if (2 == sizeType) {
	// 	pdf = new jsPDF({
	// 		// orientation: "landscape",
	// 		orientation: 'l',
	// 		unit: "in",
	// 		format: [2.25, 1.25]
	// 	});
	// }

	const img = new Image();

	// console.log('jsBarcode',JsBarcode);
	JsBarcode(img, code, {
		// height: Width,
		// width: Height,
		// displayValue: true // false-不显示jsbarcode生成的文本内容（因为文本内容无法调整字体间距），只显示条形码
	});
	console.log('img.src', img.src, pdf);
	pdf.addImage(img.src, "JPEG", 0, 0, Width, Height); // 插入条形码图片
	/** 通过jspdf来生成条形码底下的文本内容 **/
	pdf.setFontSize(14) // 设置字体大小
	pdf.setTextColor(0) // 设置字体颜色
	// pdf.text(code, 0, 0
	// // , {
	// 	// charSpace: 3.4 // 设置字体间距
	// // },
	// );
	pdf.text(code, 0, 0);
	// this.pdfData = pdf.output('bloburi')
	// console.log(pdf.output('bloburi'));
	// return pdf.output('blob');
	return pdf.output('bloburi');
	// return img.src;
}


//图片转pdf
export function ConvertImageToPdf(files, zipName, option) {
	let loadingInstance = Loading.service({
		lock: true,
		background: 'rgba(0,0,0,0.5)'
	});
	
	const zip = new JSZip()

	// let formatSize = [150, 100];
	// if ('3' == option.size) {
	// 	formatSize = [150, 150];
	// } else if ('4' == option.size) {
	// 	formatSize = [200, 150];
	// } else if ('5' == option.size) {
	// 	formatSize = [300, 150];
	// } else if ('6' == option.size) {
	// 	formatSize = [400, 150];
	// }

	return new Promise(async (resolve, reject) => {

		let topMargin = 5;
		let leftMargin = 5;

		let scalCal = 3;
		// let [imgX, imgY] = [595.28, 841.89]; // a4纸尺寸[595.28, 841.89]; 面单尺寸，4*6in[152.4*101.6mm]
		

		// pdf.setFont('weiruanhheiti');

		// console.log('getFontList',pdf.getFontList());
		// let dom_bar_list = JQ(DomId).find(".codeBarItem");
		// let pdf = new jsPDF();
		for (let i = 0, ln = files.length; i < ln; i++) {
			// let item  = listData[i];
			//logo
			// let logoImg = '/static/image/logo_en.png';
			// pdf.addImage(logoImg, 'JPEG', leftMargin, topMargin, 80,22);
			// let dom_root_img_list = JQ(DomId).find("img");
			// let barCodeTagList = dom_root;
			// let barCodeItem = files[i];
			let fileOneAll = files[i];
			const file = fileOneAll.data;
			const reader = new FileReader();
			const fileContents = await new Promise((resolve, reject) => {
				reader.onload = function(event) {
					resolve(event.target.result);
				};
				reader.onerror = function(event) {
					reject(new Error("文件读取错误。"));
				};
				// reader.readAsArrayBuffer(file);
				reader.readAsDataURL(file)
			});
			console.log('fileContents', fileContents);
			// let img_src = URL.createObjectURL(fileContents);
			let img_src = fileContents;
			// const imageWidth = 1000; // 设定图片的最大宽度
			// const imageHeight = 0; // 设置为 0，将根据宽度等比例计算高度
			const img = new Image();
			img.src = img_src;

			// img.onload = function() {
			// 	const width = img.width;
			// 	const height = img.height;
			// 	// 计算图片适应 PDF 页面的尺寸
			// 	const aspectRatio = width / height;
			// 	if (finalHeight === 0) {
			// 		finalHeight = finalWidth / aspectRatio;
			// 	} else if (finalWidth === 0) {
			// 		finalWidth = finalHeight * aspectRatio;
			// 	}
			// };

			const imgContents = await new Promise((resolve, reject) => {
				// let finalWidth = imageWidth;
				// let finalHeight = imageHeight;

				img.onload = function() {
					const width = img.width;
					const height = img.height;
					
					let finalWidth = width;
					let finalHeight = height;
					
					// 计算图片适应 PDF 页面的尺寸
					// const aspectRatio = width / height;
					// if (finalHeight === 0) {
					// 	finalHeight = finalWidth / aspectRatio;
					// } else if (finalWidth === 0) {
					// 	finalWidth = finalHeight * aspectRatio;
					// }
					resolve({
						finalWidth,
						finalHeight
					})
				};
			});

			console.log('imgContents', i, imgContents);
			let img_w = imgContents.finalWidth*0.26;
			let img_h = imgContents.finalHeight*0.26;

			let formatPdf = [];
			// if(1==option.type){//固定尺寸生成
			// 	formatPdf = formatSize;
			// } else {//根据图片尺寸生成
				formatPdf = [img_w+(leftMargin*2),img_h+(topMargin*2)];
			// }
			console.log('formatPdf',formatPdf);
			//横向和纵向
			let orientation = "";
			if(img_w>=img_h){
				orientation = "landscape";
			} else {
				orientation = "portrait";
			}
			// 一英寸=72pt(点)=96px(像素)
			let pdf = new jsPDF({
				// orientation: 'p',
				// orientation:'portrait',
				// orientation: 'landscape',
				orientation: orientation,
				// 1像素（px）等于约0.26毫米（mm）
				unit: 'mm',
				format: formatPdf, //4*6in
				// format: [595.28, 841.89],//'a4',
				// putOnlyUsedFonts:true,
				// floatPrecision: 16 // or "smart", default is 16
			});
			// 添加图片到 PDF
			// pdf.addImage(imgUrl, 'JPEG', 10, 10, finalWidth, finalHeight, null, 'SLOW')
			pdf.addImage(img_src, 'JPEG', leftMargin, topMargin, img_w, img_h);

			// pdf.output('arraybuffer');

			// let img_pdf = new Image();
			// // let barCode = barCodeItem.find('.code_img').find('img');
			// //判断一维码时候匹配。防止出现意外情况
			// // let code = barCode.attr('code');

			// img_pdf.src = img_src;

			// img_pdf.onload = () =>{

			// }

			// let width = parseFloat((barCode.width() / scalCal).toFixed(2)); //数值类型
			// let height = parseFloat((barCode.height() / scalCal).toFixed(2)); //数值类型
			// // let height_offset = 10; //高度偏移量
			// // console.log("barCode",img_src, code, width, height);
			// if (!img_src) {
			// 	// Notification({
			// 	// 	message: '第'+i+"条:"+code + ',加载失败...',
			// 	// 	type: 'waning'
			// 	// });
			// 	console.log('第' + i + "条:" + code + ',加载失败...');
			// 	// return;
			// }
			// // pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 0, 0, ...size) //将canvas转换为图片并添加到jsPDF中
			// pdf.addImage(img_src, 'JPEG', leftMargin, topMargin, width,
			// 	height);

			// if(option.showSkuName){
			// try {
			// 	// let goodsName = barCodeItem.find('.code_name').find('img');
			// 	// pdf.setFontSize(20);
			// 	// pdf.text(leftMargin, topMargin+60, goodsName);
			// 	pdf.addImage(goodsName.attr('src'), 'png', leftMargin, topMargin + 60, parseFloat((goodsName.width() /
			// 			scalCal).toFixed(2)),
			// 		parseFloat((goodsName.height() / scalCal).toFixed(2)));
			// 	// console.log(goodsName);
			// } catch (err) {
			// 	console.log(err);
			// 	// Notification({
			// 	// 	message: '第'+i+"条"+code + 'goodsName,加载失败...',
			// 	// 	type: 'waning'
			// 	// });
			// 	console.log('第' + i + "条," + '加载失败...');
			// }

			// }

			// if(option.showCus){
			// 	let userId = barCodeItem.find('.code_user').text();
			// 	pdf.setFontSize(10);
			// 	pdf.text(leftMargin, topMargin+60+10, userId);
			// }

			// if(option.addText && option.addText.length>0){
			// 	let addText_list = barCodeItem.find('.code_mark').find('span');
			// 	pdf.setFontSize(10);
			// 	if(addText_list.length>=1){
			// 		pdf.text(leftMargin, topMargin+60+10+10, addText_list.eq(0).text());
			// 	}
			// 	if(addText_list.length>=2){
			// 		pdf.text(leftMargin+100, topMargin+60+10+10, addText_list.eq(1).text());
			// 	}
			// }

			// console.log(goodsName,userId,addText_list);

			// Notification({
			// 	message: '第'+i+"条:"+code + ',正在准备...',
			// 	type: 'success'
			// });
			// const image = item.imageList[j];
			// let imgHeight = imgX / (image.width / image.height);
			// pdf.addImage(image.data, 'JPEG', 0, isAddpage, imgX, imgHeight);
			// let modPage = 0;
			// if (i == 0) {
			// 	modPage = 0;
			// } else {
			// 	modPage = i % 5; //每五个分一页
			// }
			// 对比数据索引和code索引一致
			// if(barCode && code==item.place){
			// pdf.addImage(img_src, 'JPEG', leftMargin, topMargin + (height + height_offset) * modPage, width,
			// 	height);

			// pdf.addImage({
			//    imageData: img_src,
			//    x: leftMargin,
			//    y: topMargin + (height + height_offset) * modPage,
			//    width: width,
			//    height: height,
			//    format: "jpg",
			// 	 compression: 'NONE'
			// });

			// }
			// if (i != 0 && modPage == 4) { //索引4时即为第五个
			// pdf.addPage();
			// }
			// if (i < ln - 1) {
			// 	pdf.addPage();
			// }
			
			let fileName = "error_Convert" + '_' + i;
			if (fileOneAll.name) {
				
				try {
					fileName = fileOneAll.name;
					fileName = fileName.substr(0,file.name.lastIndexOf('.'));
				} catch(err){
					console.log(i,err);
				}
				
			}
			zip.file(fileName+'.pdf', pdf.output('blob'), {
				binary: true
			});
		}
		// let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
		// pdf.save(name + "_" + dateStr + ".pdf");
		zip.generateAsync({
			type: 'blob'
		}).then(content => {
			let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
			loadingInstance.close();
			// 生成二进制流
			FileSaver.saveAs(content, zipName + '_' + dateStr + '.zip') // 利用file-saver保存文件  自定义文件名
		})
		resolve();
		loadingInstance.close();
	});
}

// const scale = 2 //缩放程度，清晰度，越大越清晰，图片也越大
// let printEle, //截图按钮
//     pdfEle, //待截取标签
//     canvasBox //canvas显示区域


// (function init(_dom) {
//     printEle = _dom.querySelector('#printEle')
//     pdfEle = _dom.querySelector('#pdfEle')
//     canvasBox = _dom.querySelector('#canvasBox')
//     printEle.addEventListener('click', clickHandler) //点击按钮生成截屏
// })(document)

// async function clickHandler(e) {
//     if (canvasBox.children.length) return //若canvas显示区域已经有标签则退出
//     const canvas = await html2canvas(pdfEle, { //截取标签转换为canvas
//         canvas: createCanvas(pdfEle),
//         background: '#FFFFFF'
//     })
//     downloadPdf(canvas)
//     pdfEle.hidden = true //隐藏之前的元素，更好对比
//     canvasBox.appendChild(canvas) //显示效果
// }

function downloadPdf(docName, canvas, scale) { //将canvas变成PDF并下载
	const size = [canvas.width / scale, canvas.height / scale] //pdf真实宽高
	//第一个参数表示横向与纵向，具体可看文档，我这里做了一个适配，宽比高长则是横向反之则是纵向
	// const doc = new jsPDF(size[0] / size[1] > 1 ? 'l' : 'p', 'px', size)
	const doc = new jsPDF('p', 'px', size)
	// 一英寸=72pt(点)=96px(像素)
	// const doc = new jsPDF({
	// 	// orientation: 'p',
	// 	unit: 'pt',
	// 	format: 'a4',
	// 	// putOnlyUsedFonts:true,
	// 	// floatPrecision: 16 // or "smart", default is 16
	// });

	//logo
	// let logoImg = '/static/image/logo_en.png';
	// doc.addImage(logoImg, 'JPEG', 10, 10, 92, 30);
	//内容
	doc.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 0, 0, ...size) //将canvas转换为图片并添加到jsPDF中
	let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
	doc.save(docName + "_" + dateStr + ".pdf");
	// doc.save(docName+".pdf"); //保存PDF
}

export function createCanvas(target, scale) { //target是待截取的标签，我们通过target生成对应大小的canvas
	let canvas = document.createElement("canvas");
	let context = canvas.getContext("2d")
	// let clientRect = target.getBoundingClientRect() // 获取标签相对可视区域的偏移量
	canvas.width = target.offsetWidth * scale; // 画布实际宽度
	canvas.height = target.offsetHeight * scale; // 画布实际高度
	canvas.style.width = target.offsetWidth + 'px' // 浏览器上显示的宽度
	canvas.style.height = target.offsetHeight + 'px' //浏览器上显示的高度
	context.scale(scale, scale); //等比缩放
	// context.translate(-clientRect.left, -clientRect.top); //通过translate取反位移
	return canvas
}

// HTML分页导出
export const exportPdfPageByPrint = async (docName, domClassName) => {

	let loadingInstance = Loading.service({
		lock: true,
		background: 'rgba(0,0,0,0.5)'
	});

	Message({
		message: 'loading export...',
		duration: 3000,
		type: 'success'
	});

	const scale = 2; //缩放程度，清晰度，越大越清晰，图片也越大

	let pdfEle = document.querySelector(domClassName);

	//执行导出
	const canvas = await html2canvas(pdfEle, { //截取标签转换为canvas
		canvas: createCanvas(pdfEle, scale),
		useCORS: true, // 【重要】开启跨域配置
		background: '#FFFFFF',
		// dpi: window.devicePixelRatio * 4, // 将分辨率提高到特定的DPI 提高四倍
		// scale: 4, // 按比例增加分辨率
	})
	//a4纸的尺寸[595.28,841.89]，
	let a4Width = 595.28,
		a4Height = 841.89;

	var contentWidth = canvas.width;
	var contentHeight = canvas.height;
	//一页pdf显示html页面生成的canvas高度;
	var pageHeight = contentWidth / a4Width * a4Height;
	//未生成pdf的html页面高度
	var leftHeight = contentHeight;
	//pdf页面偏移
	var position = 0;
	//a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
	var imgWidth = a4Width;
	var imgHeight = a4Width / contentWidth * contentHeight;
	var pageData = canvas.toDataURL('image/jpeg', 1.0);
	var pdf = new jsPDF('', 'pt', 'a4');
	//有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
	let pdfNum = 0;
	//当内容未超过pdf一页显示的范围，无需分页
	if (leftHeight < pageHeight) {
		pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
		pdfNum++;
	} else {
		while (leftHeight > 0) {
			pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
			leftHeight -= pageHeight;
			position -= a4Height;
			//避免添加空白页
			if (leftHeight > 0) {
				pdf.addPage();
			}
			pdfNum++;
		}
	}

	if (pdfNum > 30) {
		Notification({
			message: '共' + pdfNum + "页" + ',超过30页，可能导出异常',
			type: 'warning'
		});
	} else {
		Notification({
			message: '共' + pdfNum + "页" + ',导出完成...',
			type: 'success'
		});
	}

	let dateStr = Vue.prototype.$Utils.fomatterDate_YYYYMMdd(new Date());
	pdf.save(docName + "_" + dateStr + ".pdf");
	// pdf.save('content.pdf');
	loadingInstance.close();
	// html2canvas(pdfEle, {
	// 	onrendered: function(canvas) {
	// 		console.log('onrendered',canvas);
	// 		var contentWidth = canvas.width;
	// 		var contentHeight = canvas.height;
	// 		//一页pdf显示html页面生成的canvas高度;
	// 		var pageHeight = contentWidth / 595.28 * 841.89;
	// 		//未生成pdf的html页面高度
	// 		var leftHeight = contentHeight;
	// 		//pdf页面偏移
	// 		var position = 0;
	// 		//a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
	// 		var imgWidth = 555.28;
	// 		var imgHeight = 555.28 / contentWidth * contentHeight;
	// 		var pageData = canvas.toDataURL('image/jpeg', 1.0);
	// 		var pdf = new jsPDF('', 'pt', 'a4');
	// 		//有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
	// 		//当内容未超过pdf一页显示的范围，无需分页
	// 		if (leftHeight < pageHeight) {
	// 			pdf.addImage(pageData, 'JPEG', 20, 0, imgWidth, imgHeight);
	// 		} else {
	// 			while (leftHeight > 0) {
	// 				pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight)
	// 				leftHeight -= pageHeight;
	// 				position -= 841.89;
	// 				//避免添加空白页
	// 				if (leftHeight > 0) {
	// 					pdf.addPage();
	// 				}
	// 			}
	// 		}

	// 		pdf.save('content.pdf');
	// 	}
	// })
	// alert("正在导出,请稍后");
	// setTimeout(function(){location.reload();},2000); //延迟两秒刷新页面，根据实际情况，这一句可以不要
}


// export {
// 	exportPdfByShippingMark
// };