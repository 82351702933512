<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader"><h3 class="buyerHeaderTit">{{$t('i18nn_199f5bded0b23976')}}</h3></div> -->

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">退货管理</span>
						<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">

					<el-button type="success" @click="toCreate" size="small"
						icon="el-icon-plus">{{$t('i18nn_e8564657bbe9ca53')}}</el-button>

					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<!-- <span>{{ item.label }}</span> -->
						{{i18nFormatter(item.value)}}
					</el-menu-item>
				</el-menu>
			</div>
			<div class="filterAction">

				<ul class="filterConList">
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser ref="SelAgentUser" @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<li>
						<span>物流单号</span>
						<el-input type="text" v-model="filterData.tracking" size="small" clearable
							@keyup.enter.native="initData()" maxlength="300" :placeholder="$t('hytxs0000001')"
							style="width: 220px;" />
						<el-tooltip class="" effect="dark" :content="'多物流单号搜索'"
							placement="top">
							<el-button  icon="el-icon-s-operation" size="small" type="success" plain
								@click="openMultiInputAction()"></el-button>
						</el-tooltip>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;"></el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
					</li> -->
				</ul>
			</div>
			<!-- 多搜索显示 -->
			<div class="" style="padding: 0 10px 10px 10px;"
				v-if="filterData.trackingList && filterData.trackingList.length > 0">
			
				<ul class="filterConList" style="text-align: left;">
					<el-button icon="el-icon-close" size="small" type="success"
						@click="clearMultiList()">
						<span>{{$t('i18nn_4c4e546aa3683ff5')}}</span>
						(<span>多物流号搜索</span>)
						(<span>共<span>{{filterData.trackingList.length}}</span></span>)
					</el-button>
					<li v-for="(item, index) in filterData.trackingList" :key="index">
						<el-tag size="small" effect="plain">{{ item }}</el-tag>
					</li>
				</ul>
			</div>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh1">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

				<!-- "sendNo":"",//"发货编号",
            "recevieName":"",//"收件人名称",
            "whNo":"",//"仓库编号",
            "recevieMobile":"",//"收件人号码",
            "postCode":"",//"邮编",
            "country":"",//"国家",
            "province":"",//"省/州",
            "city":"",//"城市",
            "address1":"",//"地址一",
            "carTmsType":"",//"出车方式",
            "carPlanDate":"",//"预计到车日期",,-->

				<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">

						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger"
							v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning"
							v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success"
							v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>

				<el-table-column prop="returnNo" :label="$t('i18nn_48e4efd421dad5b7')">
					<template slot-scope="scope">
						<div>
							<div class="link-copy">
								<span class="link-text" @click="showDetListAction($event, scope.row, scope.$index)">
									{{ scope.row.returnNo }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')"
									placement="top">
									<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.returnNo)"></i>
								</el-tooltip>
							</div>

						</div>
					</template>
				</el-table-column>

				<el-table-column prop="tracking" :label="$t('i18nn_3e764f7ae7f5e850')">
					<template slot-scope="scope">
						<div>
							<div class="link-copy">
								<span class="link-text" @click="toExpLgsTrackView($event, scope.row, scope.$index)">
									{{ scope.row.tracking }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')"
									placement="top">
									<i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.tracking)"></i>
								</el-tooltip>
							</div>
						</div>
					</template>

				</el-table-column>

				<el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>

				<el-table-column prop="recordList" :label="'SKU'+'*'+'数量'" min-width="150">
					<template slot-scope="scope">
						<div v-if="scope.row.recordList" class="recDet">
							<div class="recItem" v-for="(item,index) in scope.row.recordList" :key="index" v-if="index<5">
								<span class="recItem-sku">{{item.goodsSku}}</span>&nbsp;*&nbsp;
								<strong class="recItem-num">{{item.quantity}}</strong>
							</div>
							<div v-if="scope.row.recordList.length>5">
								<el-link type="primary" @click="openDet($event, scope.row, scope.$index)">
									..
									<span>{{$t('i18nn_73bbf51487ec69e9')}}</span>
									<span>&nbsp;{{scope.row.recordList.length}}&nbsp;</span>
									<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column>



				<el-table-column prop="attachments" :label="$t('Storage.packing_list.attachment')+'/'+'签收照'" min-width="120">
					<template slot-scope="scope">
						<div>
							<div v-if="scope.row.signAttachment && scope.row.signAttachment.length>0">
								<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.signAttachment[0].url"
									:preview-src-list="scope.row.signAttachment.map(itemPre=> { return itemPre.url})">
									<div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</div>
							<div class="recDet">
								<div class="recItem" v-if="scope.row.signAttachment && scope.row.signAttachment.length>0">
									<div @click="openSignFile(scope.row)">
										<el-link type="primary" icon="el-icon-paperclip">签收照片</el-link>
										<el-badge :value="scope.row.signAttachment.length" type="primary">
										</el-badge>
									</div>
								</div>
								<div class="recItem" v-if="scope.row.otherAttachment && scope.row.otherAttachment.length>0">
									<div @click="openUploadFile(scope.row)">
										<el-link type="primary" icon="el-icon-paperclip">{{$t('Storage.packing_list.attachment')}}</el-link>
										<el-badge :value="scope.row.otherAttachment.length" type="primary">
										</el-badge>
									</div>
								</div>
							</div>
						</div>
						<!-- <div>
							<div v-if="scope.row.attachments && scope.row.attachments.length>0">
								<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.attachments[0].url"
									:preview-src-list="scope.row.attachments.map(itemPre=> { return itemPre.url})">
									<div slot="error" class="image-slot" style="font-size: 40px;"><i
											class="el-icon-picture-outline"></i></div>
								</el-image>
							</div>
							<div @click="openUploadFile(scope.row)">
								<div v-if="scope.row.attachments">
									<el-link
										type="primary">...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.attachments.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span></el-link>
								</div>
							</div>
						</div> -->
					</template>
				</el-table-column>

				<el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')"></el-table-column>

				<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover">
							<div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="commitDate" :label="$t('i18nn_0ae081c3b8c4d4a1')"></el-table-column> -->

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="280px" fixed="right" align="left">
					<template slot-scope="scope">
						<div>

							<div style="margin-bottom: 5px;">
								<!-- <el-button
									@click="commitAction($event, scope.row, scope.$index)" type="primary"
									size="small" icon="el-icon-sell" v-if="'0' == scope.row.status">{{$t('Storage.DropShipping.submit')}}</el-button> -->

								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="small"
									icon="el-icon-edit" v-if="'0' == scope.row.status||'10' == scope.row.status">
									{{$t("FormMsg.Edit")}}</el-button>

								<el-button @click="delAction($event, scope.row)" type="danger" size="small" icon="el-icon-minus"
									v-if="'0' == scope.row.status||'10' == scope.row.status">
									{{$t("FormMsg.Delete")}}</el-button>

								<el-button @click="commitCancelAction($event, scope.row, scope.$index)" type="danger" size="small"
									icon="el-icon-sold-out" v-if="'10' == scope.row.status">{{$t('i18nn_b4e5993f97fe04ee')}}</el-button>

							</div>


							<div>
								<el-dropdown size="small" @command="rowMoreAction($event, scope.row, scope.$index)">
									<el-button type="primary" size="small" style="margin:0 0px;">
										<span>{{$t('i18nn_c3525eb110db58aa')}}</span><i class="el-icon-arrow-down el-icon--right"></i>
									</el-button>
									<el-dropdown-menu slot="dropdown">

										<el-dropdown-item command="showDetList" icon="el-icon-view">
											{{$t('i18nn_4c0e3495d9042ebf')}}
										</el-dropdown-item>
										<el-dropdown-item divided command="openUploadFile" icon="el-icon-upload2">
											{{$t('FormMsg.upload_attachment')}}
										</el-dropdown-item>
										<el-dropdown-item divided command="openSignFile" icon="el-icon-paperclip">
											{{$t('i18nn_ef712dc1dd7c6dac')}}
										</el-dropdown-item>

										<el-dropdown-item divided command="openQARecList" icon="el-icon-service">
											{{$t('i18nn_10bc8a2b45aa5636')}}
										</el-dropdown-item>

									</el-dropdown-menu>
								</el-dropdown>
							</div>


							<!-- <div style="margin-bottom: 5px;">
                <el-button @click="showDetList($event, scope.row)" type="primary" size="mini" icon="el-icon-view">{{$t('i18nn_4c0e3495d9042ebf')}}</el-button>
                <el-button @click="openUploadFile($event, scope.row)" type="success" size="mini" icon="el-icon-upload2">{{$t('FormMsg.upload_attachment')}}</el-button>
              </div>
							
							<div style="margin-bottom: 5px;">
								<el-button @click="openSignFile($event, scope.row, scope.$index)" type="primary" size="mini" icon="el-icon-tickets">{{$t('i18nn_ef712dc1dd7c6dac')}}</el-button>
								
								<el-button @click="openQARecList($event, scope.row)"  type="primary" plain size="mini" icon="el-icon-service">{{$t('i18nn_10bc8a2b45aa5636')}}</el-button>
							</div> -->

							<!-- <div v-if="'0' == scope.row.status">
                <el-button @click="shipmentsAction($event, scope.row, scope.$index)" type="success" size="mini" icon="el-icon-sell">{{$t('Storage.invoice_list.Submit_instructions')}}</el-button>
              </div> -->
							<!-- <div v-if="'30' == scope.row.status||'36' == scope.row.status">
                <el-button @click="downloadBillLading($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-download">{{$t('Storage.invoice_list.Download_lading_bill')}}</el-button>
              </div> -->
						</div>

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination"
					v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		
		<!-- 多条输入框 -->
		<el-dialog :title="'物流单号'" append-to-body :close-on-click-modal="false"
			:visible.sync="dialogMultiInputVisible" width="1000px">
			<div style="">
				<h3>{{$t('i18nn_380912455c6e2f06')}}</h3>
				<el-input type="textarea" :autosize="{ minRows: 20 }" v-model="filterData.trackingListStr" size="small"
					clearable show-word-limit maxlength="10000" :placeholder="'一行输入一个'"
					style="width: 100%;vertical-align: middle; " />
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogMultiInputVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="sureMultiInputAction()">{{$t('i18nn_b5511889be42a3da')}}</el-button>
			</div>
		</el-dialog>

		<!-- 发货轨迹查询 -->
		<whQueryExpLgsTrack2 :openTime="openTimeExpLgsTrack" :row="ExpLgsTrackRow"></whQueryExpLgsTrack2>

		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="0"> -->
		<el-drawer :wrapperClosable="false" :title="$t('FormMsg.details_View')" append-to-body :visible.sync="dialogShowDet"
			:direction="'rtl'" size="1200px">
			<div style="">
				<WarehouseExchangeReturnDetList :detId="detId"></WarehouseExchangeReturnDetList>
			</div>
			<!-- <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogShowDet = false">{{$t('FormMsg.Close')}}</el-button></div> -->
		</el-drawer>
		<!-- </el-dialog> -->

		<!-- 售后 -->
		<QAModelRecList :openTime="QAMoRecListOpenTime" :type="'50'" :relationId="QAMoRecListRelationId"
			:relationNo="QAMoRecListRelationNo" :whNo="QAMoRecListWhNo" @success="QAMoRecListSuccess"></QAModelRecList>

		<!-- 签收附件 -->
		<whFileView :openTime="openTimeSignFile" :row="selSignFileData" :title="$t('i18nn_ef712dc1dd7c6dac')"
			:typeCode="'65'"></whFileView>

		<!--  附件上传 -->
		<whFileUpload :openTime="FileUploadOpenTime" :relationId="fileRelationId" @success="FileUploadSuccess">
		</whFileUpload>

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhExchangeReturnPageList"
			:expHttpFilter="pageFilterData()"></whExcelCustom>

	</div>
</template>
<script>
	import {
		WarehouseExchangeReturn_status
	} from '@/i18n/langStatus.js';
	import {
		i18nStatus
	} from '@/i18n/FormatI18n.js';

	// import WSkuInfo from '@/components/StorageCenter/WSkuProduct/WSkuInfo.vue';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';

	import WarehouseExchangeReturnDetList from './WarehouseExchangeReturnDetList.vue';
	import whFileUpload from '@/components/StorageCenter/components/whFileUpload.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import QAModelRecList from '@/components/StorageCenter/QA/QAModelRecList.vue';
	import whFileView from '@/components/StorageCenter/components/whFileView.vue';

	import whQueryExpLgsTrack2 from '@/components/StorageCenter/components/whQueryExpLgsTrack2.vue';

	export default {
		components: {
			whExcelCustom,
			// WSkuInfo,
			WarehouseExchangeReturnDetList,
			whFileUpload,
			SelAgentUser,
			whNoSelect,
			QAModelRecList,
			whFileView,
			whQueryExpLgsTrack2
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},

				dialogShowDet: false,
				detId: null,

				openTimeExpLgsTrack: '',
				ExpLgsTrackRow: {},

				fileRelationId: '',
				FileUploadOpenTime: '',
				
				//输入弹窗
				dialogMultiInputVisible: false,

				//签收附件
				openTimeSignFile: '',
				selSignFileData: {},

				//售后
				QAMoRecListOpenTime: "",
				QAMoRecListRelationId: "",
				QAMoRecListRelationNo: "",
				// QAMoRecListUserId:"",
				QAMoRecListWhNo: "",

				loading: false,

				loading_det: false,

				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					statusList: WarehouseExchangeReturn_status
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					agentUser: "",
					workNo: '',
					status: '',
					// packingNo: '',
					keyword: "",
					whNo: '',
					trackingList:[],
					tracking:'',
					trackingListStr:'',
					// putWhNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// declareNameCh: ''
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
			if (this.$route.query.workNo) {
				this.filterData.workNo = this.$route.query.workNo;
				// this.initData();
			}
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
			},
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			i18nFormatter(value) {
				return i18nStatus(value, this.selectOption.statusList)
			},

			//导出 excel
			exportExcel() {
				let columns = [{
						title: this.$t('Storage.packing_list.status'),
						key: 'statusName'
					},
					{
						title: this.$t('i18nn_48e4efd421dad5b7'),
						key: 'returnNo'
					},
					{
						title: this.$t('i18nn_3e764f7ae7f5e850'),
						key: 'tracking'
					},
					{
						title: '仓库',
						key: 'whNo'
					},
					{
						title: 'SKU * 数量',
						key: 'recordList',
						types: 'custom',
						formatter: function(row) {
							if (row.recordList) {
								return row.recordList
									.map(item => {
										return item.goodsSku + ' * ' + item.quantity;
									})
									.join(' , \n');
							} else {
								return '';
							}
						}
					},
					{
						title: this.$t('i18nn_0ae081c3b8c4d4a1'),
						key: 'commitDate'
					},
					{
						title: this.$t('15b3627faddccb1d'),
						key: 'remark'
					},
				];
				let Data = this.tableData;

				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'WarehouseExchangeReturn';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();

				// excelUtilsNew.exportExcel(columns, Data, 'WhDropShipping', { height: 20 });
			},

			toCreate() {
				this.$router.push({
					name: "createOrder",
					query: {
						orderType: '50'
					}
				})
			},

			// //打开编辑
			openEdit(event, row, index) {
				event.stopPropagation();
				// this.openDioalog(row, this.$t('FormMsg.Edit'));
				this.$router.push({
					name: "createOrder",
					query: {
						orderType: '50',
						single: true,
						id: row.id
					}
				})
				// this.getPageDetData(row.id);
			},

			//行操作更多
			rowMoreAction(command, row, index) {
				console.log('rowMoreAction', command);
				// event.stopPropagation();
				if ('openUploadFile' == command) {
					this.openUploadFile(row, index);
				} else if ('showDetList' == command) {
					this.showDetList(row, index);
				} else if ('openSignFile' == command) {
					this.openSignFile(row, index);
				} else if ('openQARecList' == command) {
					this.openQARecList(row, index);
				}


				// else if('toExpLgsTrackView'==command){
				// 	this.toExpLgsTrackView(row,index);
				// }
			},

			//删除
			delAction(event, row) {
				event.stopPropagation();
				this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						this.delDataAction(event, row);
					})
					.catch(() => {});
			},
			//删除
			delDataAction(event, row) {
				event.stopPropagation();
				console.log('delDataAction', row);
				this.postData(this.$urlConfig.WhExchangeReturnBaseDel + '/' + row.id, {}, 'delete', () => {
					// this.initData();
					// this.$message.success("");
				});
			},

			//撤销
			commitCancelAction(event, row) {
				event.stopPropagation();
				// let parm = [];
				this.$confirm('确定撤销吗？', this.$t('tips.tipsTitle'), {

						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.WhExchangeReturnRevocation, {
							"id": row.id
						}, '', () => {
							this.$message.success(this.$t('tips.submitSuccess'));
						});
					})
					.catch(() => {});
			},

			//下载提货单
			downloadBillLading(event, row) {
				event.stopPropagation();
			},

			//售后
			openQARecList(row) {
				// event.stopPropagation();
				this.QAMoRecListRelationId = row.id;
				this.QAMoRecListRelationNo = row.returnNo || row.workNo; //兼容旧workNo
				// this.QAMoRecListUserId = row.userId;
				this.QAMoRecListWhNo = row.whNo;
				this.QAMoRecListOpenTime = new Date().getTime();
			},
			QAMoRecListSuccess() {
				this.initData();
			},

			//查看签收附件
			openSignFile(row) {
				// event.stopPropagation();
				this.selSignFileData = row;
				this.openTimeSignFile = new Date().getTime();
			},

			//发货单轨迹查询
			toExpLgsTrackView(event, row) {
				event.stopPropagation();
				let ExpLgsTrackRow = {
					carrier: row.carrier,
					tracking: row.tracking
				}

				this.ExpLgsTrackRow = ExpLgsTrackRow;
				this.openTimeExpLgsTrack = new Date().getTime();
			},

			//提交信息
			postData(url, formData, type, callback) {
				// HttpTypelet _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('i18nn_bc868e024b80d2e3'));
					console.log(data);

					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						// this.dialogFormVisible = false;
						this.getPageData();
						if ("delete" == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
								type: 'success'
							});
						}
						callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

			handleClick(tab, event) {
				console.log(tab, event);
				// this.form.sendRecordDtoList = [];
			},

			handleSelectStatus(key, keyPath) {
				// console.log(key, keyPath);
				this.filterData.status = key;
				this.initData();
			},
			
			//打开的输入框
			openMultiInputAction() {
				// this.filterData.orderNumberListStr = "";
				this.dialogMultiInputVisible = true;
			},
			//确定的输入框
			sureMultiInputAction() {
				this.dialogMultiInputVisible = false;
				this.filterData.tracking = '';
				let trackingList = [];
				if (this.filterData.trackingListStr) {
					trackingList = this.filterData.trackingListStr.split('\n').filter(function(s) {
						return s && s.trim();
					});
				}
				this.filterData.trackingList = trackingList;
				this.initData();
			},
			//清除搜索订单号的值
			clearMultiList() {
				this.filterData.trackingListStr = '';
				this.filterData.trackingList = [];
				this.initData();
			},

			//分页的筛选项数据
			pageFilterData() {
				
				let trackingList = [];
				
				if (this.filterData.trackingListStr) {
					trackingList = this.filterData.trackingList;
				} else if (this.filterData.tracking) {
					trackingList = [this.filterData.tracking];
				}
				
				return {
					workNo: this.filterData.workNo ? this.filterData.workNo : null,
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					status: this.filterData.status ? this.filterData.status : null,
					// packingNo: this.filterData.packingNo ? this.filterData.packingNo : null,
					trackingList: trackingList.length > 0 ? trackingList : null,
					
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null
				};
			},

			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;

				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				this.$http
					.put(this.$urlConfig.WhExchangeReturnPageList, filterData)
					.then(({
						data
					}) => {
						// console.log('分页，请求成功');
						// console.log(data);
						this.loading_load = false;
						//表格显示数据
						let tableData = data.rows;

						tableData.forEach(item => {
							let signAttachment = [];
							let otherAttachment = [];
							if (item.attachments) {
								item.attachments.forEach(item2 => {
									if ('65' == item2.type) {
										signAttachment.push(item2)
									} else {
										otherAttachment.push(item2)
									}
								})
							}
							item.signAttachment = signAttachment;
							item.otherAttachment = otherAttachment;
						});
						this.tableData = tableData;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);

					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			showDetListAction($event, row) {
				$event.stopPropagation();
				this.showDetList(row);
			},
			//明晰
			showDetList(row) {
				this.dialogShowDet = true;
				this.detId = row.id;
			},

			//打开附件上传
			openUploadFile(row) {
				this.fileRelationId = row.id;
				this.FileUploadOpenTime = new Date().getTime();
			},
			//附件上传成功回调
			FileUploadSuccess(data) {
				this.initData();
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	/deep/ .el-input-number,
	.el-select {

		// width: 100px;
		.el-input__inner {
			text-align: left;
		}
	}

	.form_msg {
		color: #e6a23c;
	}
</style>