module.exports = {
	"i18nn_38d84b35d4836a92": "创建时",
	"i18nn_246e8486702689b8": "初始化微信分享",
	"i18nn_c074db443e169104": "监听路由变化",
	"i18nn_5f5d2d5861866f89": "避免首次获取不到",
	"i18nn_70e6e0b7ca198366": "如果您使用",
	"i18nn_58d2c1bf5829535c": "改写了已有页面的",
	"i18nn_7060ca2fc8279505": "那么建议您在",
	"i18nn_4b0727f8db77be1d": "的",
	"i18nn_373222bafdc59d8c": "统计代码执行前先调用",
	"i18nn_f4b9a92ed6eba312": "将该页面的自动",
	"i18nn_7f60c280ca3cf89e": "统计关闭",
	"i18nn_f47ba4ab8f8737c3": "防止页面的流量被统计双倍",
	"i18nn_1e6fc9d258352566": "用于发送某个",
	"i18nn_ad66ec7a4a1379a4": "统计请求",
	"i18nn_7f8cc5c86bdabe1c": "首次进入页面即执行",
	"i18nn_470bbdcc42b395ec": "选择商品类目",
	"i18nn_f0306a16ca426bbd": "可选的层级",
	"i18nn_005085930f991fb0": "下拉展示的层级",
	"i18nn_3d18b30564d305d7": "一级分类数据",
	"i18nn_2eccef01f0c2b163": "编译挂载前",
	"i18nn_826e79803d1bc2fe": "选项框大小",
	"i18nn_f0584015c235d1c1": "布局大小",
	"i18nn_a544f827b30a69f4": "布局间隔",
	"i18nn_43dc54253f7448e0": "初始赋值",
	"i18nn_d6f79d965aae9814": "值改变",
	"i18nn_b9707d860db20227": "选择一级分类",
	"i18nn_4c4e546aa3683ff5": "清空",
	"i18nn_94b44280fa1d5493": "清空操作",
	"i18nn_00384cd4bb9a2292": "触发父组件方法",
	"i18nn_9a3c1c445117dbb4": "选择二级分类",
	"i18nn_59c682bd0cf21bca": "清空区县",
	"i18nn_998f2f4329ec0971": "清空仓库",
	"i18nn_0897e206b059fa49": "显示区县",
	"i18nn_f8ca169fd7bcd7b8": "显示仓库",
	"i18nn_7fb6ada66a1fccca": "选择",
	"i18nn_0298aa6682db2d0d": "一级分类信息",
	"i18nn_3401b0539851b242": "类目请求请求成功",
	"i18nn_758239e08104dfb8": "商品类目请求失败",
	"i18nn_7e17672ef3d69a37": "二级分类信息",
	"i18nn_c08547dbd9309a2e": "空",
	"i18nn_36fc2cb9f1efdfd9": "二级分类信息请求成功",
	"i18nn_37cfdcf4c24cce7a": "二级类目请求有误",
	"i18nn_50651d1991159001": "二级分类信息请求失败",
	"i18nn_add07e4854572ed2": "三级类目",
	"i18nn_b1f5481a8729a352": "三级类目请求成功",
	"i18nn_3a7d834707ea72d3": "三级类目请求失败",
	"i18nn_29799f539ee6b906": "请输入手机号",
	"i18nn_2f647e039073d7d4": "请选择国际区号号",
	"i18nn_fbc06cfe55187764": "搜索您的国家和地区",
	"i18nn_7dff8d8ff4a48380": "常用国家",
	"i18nn_e691da78ee2e02f5": "地区",
	"i18nn_cc68ee555944f23d": "厂家",
	"i18nn_31334768d4d5f7cc": "个人",
	"i18nn_7c16b2aaeb0ee6e7": "手机区域代码",
	"i18nn_09a6f3a7fdcdaae1": "手机区域代码成功",
	"i18nn_6b0ab3e65c3be5f6": "手机区域代码请求失败",
	"i18nn_77616a6cdefbb60c": "切换到热门",
	"i18nn_1e7246dd6ccc5539": "搜索",
	"i18nn_bd2c6213d88d3688": "输入筛选",
	"i18nn_907adf16d5a6e3f3": "获取银行取现方式信息",
	"i18nn_b41baf4161dcbf7a": "获取银行取现方式成功",
	"i18nn_328771b9159af957": "恭喜",
	"i18nn_c4c80676f41cada0": "提现申请获取银行取现方式成功",
	"i18nn_31fa870d0b559f50": "正在处理",
	"i18nn_204ffab8a6e01870": "确定",
	"i18nn_8e7225aa0e809856": "获取银行取现方式失败",
	"i18nn_4eb3309d6692dbd4": "请重试",
	"i18nn_0cf5a5f3ee03c180": "滚动条整体样式",
	"i18nn_7871bf9ad50c6f22": "高宽分别对应横竖滚动条的尺寸",
	"i18nn_4fe8f1059a031171": "滚动条里面小方块",
	"i18nn_7d4288aac4b8006b": "滚动条里面轨道",
	"i18nn_543a597c8b5342e7": "省份数据",
	"i18nn_9075502784cc4b3e": "选择省份",
	"i18nn_c7fee137468893c7": "清空市",
	"i18nn_e6b1c2a1fc07e7d3": "选择城市",
	"i18nn_82808948d26efcf1": "选择区县",
	"i18nn_c6467851c112980c": "选择仓库",
	"i18nn_f74a3a59a7d5364e": "省份信息",
	"i18nn_7557b5b6a660da92": "省份信息读取成功",
	"i18nn_d9b86f5c1d349e5c": "省份信息请求失败",
	"i18nn_5ec78bef9d15434f": "省份信息请求成功",
	"i18nn_538606c127a812d7": "获取省份信息失败",
	"i18nn_36354660b7fc15b1": "城市信息",
	"i18nn_ccf8300d69affc7a": "城市信息请求成功",
	"i18nn_271b7085dfabfab6": "城市信息请求失败",
	"i18nn_ce6f99466b1a54f0": "区县信息",
	"i18nn_205964e5f8c20982": "区县信息请求成功",
	"i18nn_996c5bb9df173125": "区县信息请求失败",
	"i18nn_9f969e07af219e35": "仓库信息",
	"i18nn_862c7231ea467e38": "仓库信息请求成功",
	"i18nn_de8931d48511795c": "仓库信息请求失败",
	"i18nn_f04d208f84e6c343": "省份",
	"i18nn_e05b5d049b64b040": "城市",
	"i18nn_6773ddd95bc0757b": "区",
	"i18nn_92de017242c28a56": "县",
	"i18nn_c944a6686d996ab3": "仓库",
	"i18nn_ab46b83080b1bef8": "获取城市信息失败",
	"i18nn_599c4ec0106e9ec1": "获取区县信息失败",
	"i18nn_084ee4095468a454": "获取仓库信息失败",
	"i18nn_1ecc8be9ba1c940a": "点击上传",
	"i18nn_42a17cc85f956d55": "选取文件",
	"i18nn_a67037f1a5e99c8d": "上传到服务器",
	"i18nn_2f75d79503294e52": "上传文件大小不能超过",
	"i18nn_35c31bde1e2c8b2c": "注意",
	"i18nn_c0e3a584888c5ea6": "最多可上传",
	"i18nn_c70b6428abb8c93d": "件",
	"i18nn_3a74667815adc8bf": "最大",
	"i18nn_24799f4c6ce60dbd": "请选取完文件后点击上传到服务器",
	"i18nn_ad4f1f9c62389f04": "每个文件限定的大小",
	"i18nn_46ddcffae70a5c3b": "清空图片",
	"i18nn_46312513d60a4d12": "覆盖默认的上传行为",
	"i18nn_f815e09651e471f8": "实现自定义上传",
	"i18nn_dffbf7d48cf860a2": "上传选项",
	"i18nn_7d35d659ad32a051": "没有返回",
	"i18nn_97e0de817d74acab": "获取",
	"i18nn_874b22782fe58a33": "配置信息",
	"i18nn_b3742c8e82f10c3e": "参数失败",
	"i18nn_904b1edd118e1b35": "分片上传文件",
	"i18nn_02a03d22b029f499": "调用此处",
	"i18nn_2e4e32c5dd570bfa": "执行两次",
	"i18nn_7f2f052a5da435ca": "上传",
	"i18nn_5fba7f16556a9197": "文件状态改变时的钩子",
	"i18nn_2a50e3872522d075": "添加文件",
	"i18nn_8389ed823b47a9c9": "上传成功和上传失败时都会被调用",
	"i18nn_a974598ef531812b": "不校验格式",
	"i18nn_d9d6266f40f67534": "未知文件",
	"i18nn_21508344d0cf56ec": "重复上传时出现",
	"i18nn_486db80b86de2c1a": "文件上传成功的函数",
	"i18nn_d34e97bbd9523da6": "为",
	"i18nn_99d6111748eae069": "的元素",
	"i18nn_b15f1d04b9c614ee": "为下标",
	"i18nn_dac6d2e1b7f9595e": "原数组",
	"i18nn_f5797a71c8dc42f6": "文件个数和返回",
	"i18nn_b1e0fb9c9e07cee6": "个数相等时",
	"i18nn_5106bcad470f79d3": "多图片会重复调用成功方法",
	"i18nn_b9143f93c45fee7f": "删除重复",
	"i18nn_669600b917b45f04": "方法的实现代码相当酷炫",
	"i18nn_563f7ec62931b0d7": "实现思路",
	"i18nn_a1792e462520f7a7": "获取没重复的最右一值放入新数组",
	"i18nn_5e981dea2122a654": "检测到有重复值时终止当前循环同时进入顶层循环的下一轮判断",
	"i18nn_58179f501fd44ca0": "上传文件之前的钩子",
	"i18nn_63bc87384ee1bdc5": "参数为上传的文件",
	"i18nn_a7c0a534a79af36b": "若返回",
	"i18nn_410278c6a1528e67": "或者返回",
	"i18nn_9f7ace149cfcb06d": "且被",
	"i18nn_48fbbc37d0b71cb7": "则停止上传",
	"i18nn_d60a3f24e8329e20": "小于",
	"i18nn_027eacedab3f8d19": "图片尺寸不能大于",
	"i18nn_b180cd998ccbe52e": "文件超出个数限制",
	"i18nn_987cf2f4c71691ee": "文件超出个数限制时的钩子",
	"i18nn_10a3c6e1c105c148": "文件删除",
	"i18nn_dbcffdf31e86d2d8": "排序",
	"i18nn_04ea6d4ff8e5c4bb": "最多",
	"i18nn_09049355527ef6a2": "张",
	"i18nn_dfd0cad4cb8eb442": "支持",
	"i18nn_b379748a6ce5496b": "格式",
	"i18nn_f5bcb37f7eeeb184": "图片上传到服务器",
	"i18nn_925ae664f039f3de": "拖拽排序",
	"i18nn_ffe5914bcfae348c": "图片排序",
	"i18nn_5ff6dd524e285e56": "拖拽层",
	"i18nn_67fa4fa1b234d4a7": "请拖拽图片进行排序",
	"i18nn_a6dcecbe146242e2": "确认排序",
	"i18nn_4b3340f7872b411f": "取消",
	"i18nn_6ae6dd62d24bcb8a": "文件类型",
	"i18nn_f156a9536049f461": "文件",
	"i18nn_229b11e2f431750a": "阿里云",
	"i18nn_7dcf1197e1d2108c": "文件列表",
	"i18nn_cc8603c183589ab1": "侦听变化可初始化的",
	"i18nn_31439cad15cc246e": "可上传的文件数目",
	"i18nn_42d2e83f1c79aa4d": "容器宽度",
	"i18nn_7499c732802bf588": "图片弹窗展示",
	"i18nn_cbf80fed884d4aad": "拖拽插件不建议直接改变父组件的传值",
	"i18nn_1e1ed96b29e55429": "所以另建一个新数组",
	"i18nn_9832bb6f43f34fc5": "保存开启拖拽之前排序的数组",
	"i18nn_3421132d2b09ec1d": "拖拽开启开关",
	"i18nn_695da70c3f20778b": "开启拖拽",
	"i18nn_4b543fe0e4c09ea9": "数组深拷贝",
	"i18nn_df0c51c85504148f": "取消拖拽",
	"i18nn_3a09b6f334222d7c": "拖拽保存",
	"i18nn_6f579f3a8a1d652f": "缓慢滚动",
	"i18nn_a3851d8f06926278": "滚动到顶部",
	"i18nn_7b108cdca9ee7832": "变量",
	"i18nn_25a0d7bacb0096bc": "滚动",
	"i18nn_f37e9981ad7d9969": "每次的断点续传",
	"i18nn_63281f364a610887": "不仅仅一张图片一次",
	"i18nn_781b09010d31b8f9": "获取图片",
	"i18nn_e7bf2fd407589e91": "选取的图片信息",
	"i18nn_cdd9a1a7c74dfcfc": "异步读取计算机文件信息",
	"i18nn_d4263545654ce610": "使用",
	"i18nn_28d985ad0af33256": "压缩图片并转换",
	"i18nn_bebed2749046a347": "将图片转换为",
	"i18nn_54379cfde85bc70c": "并压缩",
	"i18nn_5a95a47cf6e694e7": "表示允许跨域",
	"i18nn_fa6b7849ba570629": "转换图片像素大小",
	"i18nn_02ccd2cf723f9272": "是",
	"i18nn_e676df0ca86bb7ae": "绘制图案的",
	"i18nn_00b3af4c32634497": "通过",
	"i18nn_246c1c720a155951": "获取图片的",
	"i18nn_eaf2cb6a7e187ada": "转换文件",
	"i18nn_9e0768039755bdd6": "将",
	"i18nn_0598f6eca0da5aa1": "转换为文件",
	"i18nn_b7a11317ab65382f": "解码",
	"i18nn_aa89371f7759a327": "还有一个",
	"i18nn_36ff068066920375": "做",
	"i18nn_0f27274d94b7fffb": "转码",
	"i18nn_b49b41c94f40954a": "转成文件",
	"i18nn_33e1668b0e24f46f": "图片压缩方法",
	"i18nn_d85b6a83b6f57b2d": "压缩",
	"i18nn_dfdc82bdeed15ed0": "进行最小压缩",
	"i18nn_2364f1faf538b459": "压缩后调用方法进行",
	"i18nn_602b175d07c4544a": "转",
	"i18nn_14c1c5156019ed76": "方法写在下边",
	"i18nn_7c805dc66bdc9ed8": "对象",
	"i18nn_5f690db8971ba973": "自动压缩",
	"i18nn_662d5f7574a5d59b": "判定图片大小是否小于",
	"i18nn_fa56f9fedf271c51": "调用方法获取",
	"i18nn_e1ac5c1843d0185e": "定义滚动条轨道",
	"i18nn_73f96ab3340fe950": "内阴影",
	"i18nn_3dca70270d1a74e9": "圆角",
	"i18nn_8a8f72a194eeb1f9": "定义滑块",
	"i18nn_49344113a3b43b1b": "不加",
	"i18nn_449b3774806c33c3": "全局",
	"i18nn_260a8c930f333201": "加",
	"i18nn_0a2fdbe73decdee3": "非全局",
	"i18nn_71229f26cb88e30e": "校验格式",
	"i18nn_a05ac587acc6763a": "校验文件大小",
	"i18nn_f6e62f9642018cbb": "首页",
	"i18nn_e56ddb7ad8d6586e": "尾页",
	"i18nn_8e2be23dd3b81a31": "信息",
	"i18nn_6a82fdcc3fecddc2": "用",
	"i18nn_c8840b2ed17fc9ee": "互易天下",
	"i18nn_1b499d5c744131c7": "厂家共享平台",
	"i18nn_1dc4a69b5a4078eb": "求购大厅",
	"i18nn_8421526d274c0341": "棉",
	"i18nn_5354b8b40c686fc4": "绑定的值",
	"i18nn_b742c82472e2ef35": "表格数据",
	"i18nn_8ccadbd008827b22": "分页数据",
	"i18nn_9eefb7335988c499": "查询",
	"i18nn_42abc1e524ae69dd": "排序方式",
	"i18nn_41f871978d079858": "排序字段",
	"i18nn_229f6b78d63e1bac": "降序",
	"i18nn_2536c7b6fb36b80c": "升序",
	"i18nn_bbc49a6941f59913": "分类",
	"i18nn_b8d1610b4726176f": "品名",
	"i18nn_b3853278ad6d5611": "采摘类型",
	"i18nn_05e29fb623110c69": "批号",
	"i18nn_ec834692ec6c8e38": "颜色",
	"i18nn_8d82ee4ab711fb75": "比强",
	"i18nn_ff8a4206aa17e719": "马克隆值",
	"i18nn_6cc2563839443db8": "回潮",
	"i18nn_73ecfc7be38d4bcf": "含杂",
	"i18nn_0bffd1282cb3456e": "交货地",
	"i18nn_266d5c5e4cd4a9f2": "价格区间最小",
	"i18nn_b0301d3bab660115": "价格区间最大",
	"i18nn_0d76cd7a958441df": "每页显示数目变化",
	"i18nn_dd3b072647434206": "每页",
	"i18nn_117aa3f27e5e430c": "条",
	"i18nn_8dfb22636adecc2a": "分页的条数",
	"i18nn_b18897c10c4eae3f": "请求数据",
	"i18nn_abc7a3d149718367": "这里手动触发一下",
	"i18nn_6509d9c0082bc83c": "分页更新",
	"i18nn_b5a8e089049df396": "事件",
	"i18nn_c904eca04ed8d05c": "并且把值传进去",
	"i18nn_9214ca0902929e8e": "页数变化",
	"i18nn_c936d3b74e41b901": "当前页",
	"i18nn_8d369ba038658da9": "请求分页数据",
	"i18nn_2f5702841ed342ab": "当前页显示数目",
	"i18nn_168c2364555142eb": "商品状态",
	"i18nn_5780e6a092c399c3": "下架",
	"i18nn_e0199d6ecc3ee81a": "正常",
	"i18nn_38e8c4d45d6ca3c1": "在库待售",
	"i18nn_ed2b2c9b6ff98b31": "违规",
	"i18nn_31e8ec91f4c1e966": "禁售",
	"i18nn_303124d23b20ec0a": "商品审核",
	"i18nn_91cfca219e1b41ce": "不通过",
	"i18nn_f2d95c318721642c": "审核中",
	"i18nn_2e3ee00867931849": "市场类型",
	"i18nn_4cf78c5cac4a485f": "互易大宗",
	"i18nn_25e25543c26526d5": "撮合集市",
	"i18nn_674e5faed21eb561": "棉分页",
	"i18nn_776496ec446f21f9": "请求成功",
	"i18nn_4217bc1acbacd2ba": "表格显示数据",
	"i18nn_bc4bced036fdb495": "当前数据总条数",
	"i18nn_2854e5bf29a64ed3": "当前页数",
	"i18nn_d34cf82368ba6d89": "当前页条数",
	"i18nn_323ee425eca7208c": "请求失败",
	"i18nn_ee5a0b69f9323d4a": "加粗",
	"i18nn_d656c6a9c3cbd795": "斜体",
	"i18nn_15881fe9a4aa9352": "下划线",
	"i18nn_412470089bec1dfd": "删除线",
	"i18nn_c812fd61ee56d868": "引用",
	"i18nn_21414c06d3e4ff2c": "代码",
	"i18nn_7431e76029678ec7": "标题",
	"i18nn_57a7de477b2ebe97": "有序列表",
	"i18nn_49132db903eec70f": "无序列表",
	"i18nn_4b9484089a14fff2": "段落格式",
	"i18nn_653d92fa8f1011d3": "段落",
	"i18nn_32badce0902c46a7": "字体大小",
	"i18nn_d48da5af833f1c63": "字体",
	"i18nn_dbd7312ad2825e55": "字体颜色",
	"i18nn_03e7724664b15858": "背景颜色",
	"i18nn_2c6b89b19200de97": "对齐",
	"i18nn_5ed646cd1d97c310": "超链接",
	"i18nn_104709397ba68e7e": "表格",
	"i18nn_33d4cfe5cfd81678": "兼容性有问题",
	"i18nn_2674282277c3714e": "图片",
	"i18nn_f5817042904f4a12": "视频",
	"i18nn_407a5bd47a532136": "还原",
	"i18nn_27ae8de28d3c3821": "查看源码",
	"i18nn_04e722a308964860": "预览",
	"i18nn_fae4e00e66853b23": "当前已经输入",
	"i18nn_4ebc25f9925a902b": "个字符",
	"i18nn_daaaeb1b7b22b126": "提示",
	"daaaeb1b7b22b126": "提示",
	"i18nn_6576ce14f2a92b83": "排序完成之后记得点击",
	"i18nn_a0cf2fef18330d96": "如何一次性添加多张图片",
	"i18nn_cc504b75f45b6c8e": "系统",
	"i18nn_f9f65e4fadee5183": "方式",
	"i18nn_92988957fcbb8456": "键",
	"i18nn_ed635542b3fb8fba": "选中多个图片",
	"i18nn_754c78f5c569030e": "鼠标拖动",
	"i18nn_5f612e85ebd5642e": "选择图片拖拽到",
	"i18nn_38f3e4e3906f4cde": "号处",
	"i18nn_513129bb8856e39f": "确",
	"i18nn_138162c29671c3cd": "定",
	"i18nn_fbf379655e68a5a2": "文章源码",
	"i18nn_4e9fc68608c60999": "关闭",
	"i18nn_c517fbaec10de990": "富文本编辑器样式",
	"i18nn_3f0ecebbd9d2f23b": "可用",
	"i18nn_c388459f4b4d4b1a": "但是",
	"i18nn_5811faae5c52bdc0": "移动端",
	"i18nn_88f9b3417c8cba24": "小程序",
	"i18nn_750494addd790b4a": "表格过长时",
	"i18nn_d0b19bf1ee29a341": "展示不行",
	"i18nn_f86a8104e2fb8111": "图片可收缩",
	"i18nn_576dbfd7de5c8816": "不兼容",
	"i18nn_736b9d8693e29999": "自定义字体大小",
	"i18nn_c53d0b17c0399897": "自定义字体类型",
	"i18nn_481e4318f3bb1637": "将字体加入到白名单",
	"i18nn_399a045892a8f9a0": "拖动图片会转化为",
	"i18nn_d79b54a98b79b8c1": "位",
	"i18nn_ce6e0b43e891250d": "后端不支持",
	"i18nn_cc446c9868b9bda0": "位转图片并上传",
	"i18nn_e24642aea868fc42": "前端上传转换为",
	"i18nn_8bf1de8dc482bdf1": "标签才可以",
	"i18nn_5b27f3993d58438d": "请输入内容",
	"i18nn_06f0c71344e7183c": "添加图片",
	"i18nn_3547ab0eda19fe64": "添加图片有问题",
	"i18nn_b79cb734afe08e40": "失去焦点",
	"i18nn_2d0a131edd6feb69": "返回的",
	"i18nn_67acefa1d6a78e23": "获取焦点",
	"i18nn_5a3cd7da611f6553": "可以输入",
	"i18nn_2c94eb948dd388f4": "输入文本改变事件",
	"i18nn_5d2f8ccdc5abf8e7": "返回的编辑对象",
	"i18nn_ed1dbfbdb704fc24": "添加表格",
	"i18nn_c5329b923a1a6213": "上传图片",
	"i18nn_9b1a4a3b2513a12d": "上传视频",
	"i18nn_b83b775c30e763e6": "添加视频",
	"i18nn_6c47edbd9e030ff5": "提交上传文件",
	"i18nn_fd7d693ca5ce068b": "上传图片回调",
	"i18nn_cd47fe86827ac5fc": "上传文件成功",
	"i18nn_6f1f00781c566d8f": "上传文件返回的",
	"i18nn_34d2cb61b69affe1": "设置光标在最后",
	"i18nn_4c871e84a0564f9c": "这个方法可以获取光标位置",
	"i18nn_a9c5de692b5d3d8f": "多图上传",
	"i18nn_24c8f46012a25c89": "新增",
	"i18nn_e33c9b93c36fd250": "删除",
	"i18nn_760f75dc397a9cb8": "确定添加",
	"i18nn_598ade86cbdf4868": "光标放置后面",
	"i18nn_217d41539ee86758": "否则自动往前",
	"i18nn_1aa9b398f37906a4": "文件名",
	"i18nn_9cad0c89a033c539": "初始化",
	"i18nn_ee97f044c1f043b9": "在",
	"i18nn_d1c90aa33249c258": "中要为",
	"i18nn_2918e54ff488ec32": "标签添加",
	"i18nn_c4395ffd696b7a01": "并且侦听该事件",
	"i18nn_ea5174c2d4777af3": "打开上传文件有误",
	"i18nn_4a4497edde0d7206": "请联系客服",
	"i18nn_ca1c2845b3c08111": "认证",
	"i18nn_166108de2d7c2027": "订单合同等",
	"i18nn_bdaf0b4164ce179e": "头像",
	"i18nn_2bbc6b12fbdf9019": "参数配置",
	"i18nn_b2fd3b9c18f697dd": "文件尺寸设置",
	"i18nn_23ffd33f78e6f41f": "删除图片",
	"i18nn_a3159ee97638c70b": "图片处理",
	"i18nn_2f2b1c77f45d1a43": "根据图片信息自动旋转",
	"i18nn_f17d5c1c032e68c3": "图片自动裁剪",
	"i18nn_ae4b2d07e3367db4": "参考",
	"i18nn_d30b41ab51955fc4": "裁剪",
	"i18nn_156c6fd2ea701c2d": "不裁剪",
	"i18nn_c378a7bc81d60366": "等比缩放",
	"i18nn_263c9f297c574aff": "填充透明",
	"i18nn_af98bda0cb94f7f5": "非头像",
	"i18nn_1f60b8e1b3488db6": "清除视频",
	"i18nn_b13a5c1f6801e639": "视频宽度",
	"i18nn_d7f57908ee860528": "视频高度",
	"i18nn_14599c81fe433b35": "确定清除该视频吗",
	"i18nn_235e127fd6efba7d": "切换显示",
	"i18nn_e6244665d3792248": "认证列表",
	"i18nn_85e8e84f3e8ab46b": "开通仓库权限",
	"i18nn_10986afa1c3bc094": "请填写下面信息",
	"i18nn_39fd599a04a43a76": "所有信息均必填",
	"i18nn_3de99fdf145a9ee8": "请严格按照营业执照上的信息填写",
	"i18nn_43f9ebb5f4690181": "保证数据信息准确性",
	"i18nn_896ba18e1a7c9a07": "公司信息",
	"i18nn_1df68c34677c2aae": "公司名称",
	"i18nn_00b8608bd21b2160": "介绍人",
	"i18nn_ed00b7cc66d3c1c0": "联系人信息",
	"i18nn_ea4756bc1642e0f1": "名称",
	"i18nn_4cf3d3e9cdca9259": "联系地址",
	"i18nn_8292010ff4193c50": "微信",
	"i18nn_b2d4c7fffe79258e": "邮箱",
	"i18nn_032abee95f72da18": "其他信息",
	"i18nn_2a2e2fbcc567efc6": "附件上传",
	"i18nn_3d5ce01c5f6e820a": "营业执照",
	"i18nn_e47aee84d771133a": "未过期并加盖公司有色公章",
	"i18nn_6f45df3d183b85ca": "有色合同章",
	"i18nn_d9479d928db0e37e": "申请许可证或一般户证明",
	"i18nn_176b98a165621d5f": "须显示对应银行的支行信息",
	"i18nn_dcac03939c37a894": "并附有银行公章",
	"i18nn_5c22aef16867441f": "法人",
	"i18nn_7c0f5566e47f193d": "经营者",
	"i18nn_888660c2b94c2d80": "个体户",
	"i18nn_59978c21e5b43454": "身份证正反面照片需清晰明了",
	"i18nn_4442771eba7574b1": "上传营业执照证",
	"i18nn_9f904564c255cde6": "开户许可证",
	"i18nn_0b3f536c7bea3feb": "身份证正面",
	"i18nn_9436668b05dbd114": "身份证反面",
	"i18nn_62c64c2d0f877b17": "提交申请",
	"i18nn_7a644041c4fde483": "上传厂家证照",
	"i18nn_20ba48c609b26cfa": "电子邮箱",
	"i18nn_15b3627faddccb1d": "备注",
	"i18nn_c38333badc226309": "用户",
	"i18nn_ff2df2d80b2fdce5": "统一社会信用代码",
	"i18nn_137894ffbbae8cc9": "营业执照起始时间",
	"i18nn_2f0c59a45ff9eb9f": "营业执照截止时间",
	"i18nn_22fcf6ba10fde069": "省份编码",
	"i18nn_24060d47f29be600": "城市编码",
	"i18nn_6e7fe1f78f2137ed": "区县编码",
	"i18nn_06b62801ebebb86e": "公司详细地址",
	"i18nn_c5d19fba2f94f8e7": "法人姓名",
	"i18nn_7d4d10199f48fbd1": "法人身份证号",
	"i18nn_d8b1fe0ba2d66d2d": "法人手机号",
	"i18nn_08ec14c52d8f156c": "业务负责人姓名",
	"i18nn_64ee4e517274c0d2": "业务负责人手机号",
	"i18nn_99f4ad64860f91cd": "创建人编码",
	"i18nn_8ed435ced73dc9bf": "基本信息",
	"i18nn_2ad108ab2c560530": "请选择",
	"i18nn_245b9e94e95f7e86": "请添加",
	"i18nn_35573b806a56a6f9": "申请状态详情",
	"i18nn_77e27940d6adbaa6": "重新认证",
	"i18nn_5241f70c3b7363f2": "重新申请的信息",
	"i18nn_ace52c1bdd99c30b": "查询厂家申请详情",
	"i18nn_3dd482a4ea3922d4": "初次申请",
	"i18nn_45cfcf29840849e8": "查询认证信息",
	"i18nn_c384a1e43378299e": "查询厂家申请",
	"i18nn_68f3d2d4957ee429": "银行列表",
	"i18nn_0dd064b09f5fc5ff": "查询用户信息",
	"i18nn_5ac45dfa3fa44417": "获得合伙人手机号",
	"i18nn_8d7ea163fec625ee": "表单",
	"i18nn_a236026c65338284": "就是子组件传过来的值",
	"i18nn_5e5e7e2d16922ed3": "认证提交",
	"i18nn_1cedf24d2e4fd2a0": "申请",
	"i18nn_aec73adff10d4d74": "申请请求",
	"i18nn_5039985a20eec943": "控股人信息转换为字符串",
	"i18nn_912d4a62f89a6ee2": "申请成功",
	"i18nn_7b7f9a6febb66cbe": "去互易仓",
	"i18nn_333b175b5093e881": "申请失败",
	"i18nn_d1ce84becd11893b": "申请请求失败",
	"i18nn_66092bebf417bdb8": "查询仓库申请详情",
	"i18nn_d816fbcc52e724d5": "查询认证详情请求",
	"i18nn_514f4e80f3e21b6c": "查询认证详情成功",
	"i18nn_8a1c7e5df328a455": "您还没有开通仓库权限",
	"i18nn_223f38a02e72fd91": "请先申请开通",
	"i18nn_2daa844434da27d2": "设置有仓库权限",
	"i18nn_9f7aa354186d068e": "请重新登录",
	"i18nn_a9e4df17d34ba1ea": "您的仓库权限已经审核通过",
	"i18nn_257b96627dab5b4f": "查询认证详情请求失败",
	"i18nn_510f64662337e624": "查询申请详情",
	"i18nn_6bcf61241d8b0acf": "申请已通过",
	"i18nn_342b6b21301193e6": "查询厂家申请详情请求",
	"i18nn_56b46351e946246b": "获取开通厂家支付账户信息",
	"i18nn_356db73daadf580d": "查询厂家申请详情请求失败",
	"i18nn_b6bc23d88757aecf": "获取开通个人支付账户信息失败",
	"i18nn_1d89b3f39386d872": "找回密码",
	"i18nn_ace46c7b30cb9a6e": "获取验证码",
	"i18nn_4e37193770939b6b": "恭喜您",
	"i18nn_70c9cf3bb62dbfbd": "找回密码成功",
	"i18nn_d1d7b80ea41c31bd": "请牢记您的新密码",
	"i18nn_08806c68bf7fcf6a": "秒后跳转到登录页",
	"i18nn_ced8f3a726d82f7b": "去登录",
	"i18nn_7a695ba20b15ed95": "找回失败",
	"i18nn_0107586ac6351faa": "请返回重试",
	"i18nn_db25bc1cbe55dd27": "在使用的页面引入加密插件",
	"i18nn_04152d1213c1a758": "请输入用户名",
	"i18nn_7cded08b7116a70d": "长度在",
	"i18nn_a1f1ba9fb3797498": "到",
	"i18nn_b978bd71e959b620": "请保持密码一致",
	"i18nn_f1ee1d97e869e447": "请输入验证码",
	"i18nn_eae360b6837f15e9": "请选择登录类型",
	"i18nn_ffbcb95f39aa1b47": "选择区域代码",
	"i18nn_2acd08433821c0f8": "手机国际区号",
	"i18nn_1b3345f9dd965016": "发送验证码成功",
	"i18nn_ba97bd1c15259293": "倒计时",
	"i18nn_122ac6751288f906": "倒计时五秒到登录页",
	"i18nn_4733de26e1c2e985": "验证码已发送",
	"i18nn_cdf92fd92be6533d": "有效时间五分钟",
	"i18nn_6e3a89fd80c16b60": "去登陆",
	"i18nn_9c572a3ef9b36757": "手机获得验证码",
	"i18nn_a034ab2810999eb7": "验证码已请求成功",
	"i18nn_0f00eaea829fd543": "获得验证码失败",
	"i18nn_7ed6be9d24f606ec": "验证请求",
	"i18nn_2e3c70a1029b0f4e": "验证成功",
	"i18nn_ec04cac591a1850c": "验证码存在错误",
	"i18nn_7e012b5e969da937": "密码前端加密",
	"i18nn_b2474240b4563afa": "修改密码请求",
	"i18nn_6bc538df4da5b85e": "密码格式限制",
	"i18nn_881860104a8f271e": "比对密码是否一致",
	"i18nn_86f62ce872f87de1": "修改成功",
	"i18nn_aaf11ed618ec2053": "注册成功",
	"i18nn_dffe304884c9a6ad": "密码登录",
	"i18nn_5ae52617ed71ffb7": "下次自动登录",
	"i18nn_a2dce77193d8d064": "登",
	"i18nn_f0b2eacbbd5eafa9": "录",
	"i18nn_f657f7be1cc33bd2": "忘记密码",
	"i18nn_0f12dbc1cd5f49e2": "新用户注册",
	"i18nn_2f973bff4e3b4e88": "默认密码登录",
	"i18nn_792ac745850d2622": "定时器",
	"i18nn_03be70c48bd28eb1": "清除登录信息",
	"i18nn_9b94946c061f6b48": "离开本页面",
	"i18nn_873c848bc07ac0e6": "清空定时器",
	"i18nn_b57a6fcc49b7bbc1": "轮询查询",
	"i18nn_4d815ad327e8b95d": "二维码",
	"i18nn_e4438caa92dbb748": "轮询登录",
	"i18nn_e0857a20198a5892": "运行页面运行时间",
	"i18nn_4979c49c828afa62": "页面停留时间累积",
	"i18nn_d71806f07427e99e": "切换登录方式",
	"i18nn_2ec7e785f09c336f": "获得可扫码的二维码",
	"i18nn_9aa7aa827e01b057": "获取登录二维码成功",
	"i18nn_bb456d91d96d66fa": "获取登录二维码失败",
	"i18nn_52afb9bd150ddc88": "轮询调用二维码登录",
	"i18nn_34adc74240d41576": "调用二维码登录",
	"i18nn_5fe6cc0473e0d313": "登录失败",
	"i18nn_067069df240beace": "去注册",
	"i18nn_617157aee3084320": "提交登录",
	"i18nn_e5368dfd7865a5f1": "登录成功",
	"i18nn_3e1538efa1088ddb": "短信验证码",
	"i18nn_1163c8b809761f29": "没有认证",
	"i18nn_112bee240f7d0858": "重新初始化客服组件",
	"i18nn_0fff44c827a4f3b6": "登录",
	"i18nn_fcfa7c04e26f129a": "判断合伙人是否开户",
	"i18nn_4f5ff4a5043e10fb": "厂家是否开户",
	"i18nn_f0e1c61956db8a10": "判断合伙人公约是否已经阅读",
	"i18nn_185ac08ed6acf361": "判断仓库权限",
	"i18nn_ce3c2e3ca1cfe363": "存在历史跳转",
	"i18nn_fa491681fd06faa1": "跳转到历史",
	"i18nn_160c39ee3203a740": "获取登录信息失败",
	"i18nn_1064ae7463ff50c0": "如果第一次存不到缓存",
	"i18nn_1ce0bd70ee14c382": "执行第二次",
	"i18nn_06807a01d43e37a9": "第二次存不到",
	"i18nn_4913ccaad8c4cae5": "刷新浏览器",
	"i18nn_d119d3d93509a03d": "再次取不到刷新浏览器",
	"i18nn_9ad1e57df6ecca3c": "判断合伙人",
	"i18nn_3f621a26b8218f81": "合伙人",
	"i18nn_f302ed3011ec4e3a": "和",
	"i18nn_a85c3196b1042a5e": "厂家认证",
	"i18nn_cf92715a60fabee5": "都",
	"i18nn_b5270f958518c444": "未认证审核通过",
	"i18nn_94c23ce72c716f50": "厂家审核通过",
	"i18nn_44d6f2dd8045c564": "认证提示",
	"i18nn_8eeb82ba339f0daf": "完成合伙人或厂家认证",
	"i18nn_19e39d2317257787": "可以享受更多收益",
	"i18nn_81815e947bb7793b": "点此去认证",
	"i18nn_988794ecaef531dd": "或",
	"i18nn_b759369dd86a4c7c": "查询开户状态数据",
	"i18nn_9219b1f73e2ff140": "查询开户状态数据成功",
	"i18nn_7ce573968e11cebc": "合伙人开户提示",
	"i18nn_283e0a94f1fa2944": "您的个人开户尚未完成",
	"i18nn_11f7edf30a51cfbd": "点此去个人开户",
	"i18nn_492ff5e77f754b18": "个人开户提示",
	"i18nn_0d6e3e4d92b1b3ca": "厂家开户提示",
	"i18nn_6ab0930920f92646": "您的厂家开户尚未完成",
	"i18nn_356d4449fc72c887": "点此去厂家开户",
	"i18nn_7d842f63515c8e9b": "查询开户状态数据失败",
	"i18nn_0fa3a046baf31028": "查询合伙人公约是否已经阅读",
	"i18nn_4fb77494835ade50": "尊敬的合伙人",
	"i18nn_f8d73650da15a0f9": "您好",
	"i18nn_bc8601f4ad01775c": "平台合伙人公约",
	"i18nn_638885bb94d879b4": "已更新",
	"i18nn_96353aa3ead60f0f": "请前往阅读并确认",
	"i18nn_07750f84b8e86475": "确认后我们将为您发放合伙人证书",
	"i18nn_63a2c5ecd34724fb": "前往阅读",
	"i18nn_0fb7ba49e82b597b": "查询合伙人公约是否已经阅读失败",
	"i18nn_b360807dbe727e6e": "关于我们",
	"i18nn_199ac0d41bf80eaa": "人才招聘",
	"i18nn_07c9d82ce42fdb94": "联系我们",
	"i18nn_e077a24a00af68c3": "交易流程",
	"i18nn_b7a2279756eebc0a": "法律说明",
	"i18nn_bb4053a733c92425": "商家入驻",
	"i18nn_259b11dcddccfbb6": "沪",
	"i18nn_f2bba2fa536a7ce7": "备",
	"i18nn_4335d39df11a6971": "号",
	"i18nn_3c16e728db4f24e0": "底部关于我们",
	"i18nn_e6a250894a04db4c": "详情",
	"i18nn_a707d7e7b661c902": "不存在",
	"i18nn_db54eaad9d29d07f": "底部关于我们链接",
	"i18nn_c64f10fd6de5dce3": "信息请求失败",
	"i18nn_f7d3f280c292a8d0": "头",
	"i18nn_0f0de129ce37f7a9": "内容区域",
	"i18nn_395c9ca9564361b8": "尾",
	"i18nn_5b6f28d3282af680": "切换语言",
	"i18nn_20fb9e9f515afc13": "切换语言成功",
	"i18nn_f58968344270d7b8": "建议重新刷新浏览器",
	"i18nn_8d2f2e05f1d8ff5e": "注册协议",
	"i18nn_d98d75ed0b1e7fef": "合伙人公约",
	"i18nn_e016a1c7bf268efb": "甲方",
	"i18nn_430449303cf0f5c9": "互易天下网络科技有限公司",
	"i18nn_6a8f37cb38523424": "乙方",
	"i18nn_2a8f1b7f2305a877": "互易天下网络科技有限公司是以厂家到厂家到我家",
	"i18nn_cd6132f37ab005dd": "厂",
	"i18nn_3f0b8b1f9d5df2d7": "家共享而设立的一家交易服务平台",
	"i18nn_082356af685374e6": "下称",
	"i18nn_e504b9c36ebc2f6d": "平台",
	"i18nn_6c5121995163832b": "平台网址",
	"i18nn_35e4b20da5235989": "互易天下手机",
	"i18nn_6e4e8f8497b94273": "互易天下微信公众号",
	"i18nn_cb4356ed8dde861b": "互易天下小程序",
	"i18nn_08a4ff9a64b61b9b": "互易天下微商号",
	"i18nn_cd9cf6180fb17e0b": "进入了解",
	"i18nn_3a509053ffe958c0": "平台通过厂",
	"i18nn_eb545e5819745929": "家接单生产",
	"i18nn_efebecf3564feb7f": "用户消费者集采定制",
	"i18nn_e88e791b562d63e5": "合伙共营",
	"i18nn_18109d6bbd4380f1": "仓储货的物流",
	"i18nn_8a967bde547fff46": "金融配资等功能一体化服务",
	"i18nn_d7d1aaa9f07d87ce": "以",
	"i18nn_773c597e2c3d4a01": "让千万厂家交互起来",
	"i18nn_28b1d6118eeb2b23": "为使命",
	"i18nn_9e641ee4ec777702": "真",
	"i18nn_0df90bded79afc49": "产品",
	"i18nn_8bc133c0c6fe6e99": "实价格",
	"i18nn_741c45ff6e53699d": "零距离",
	"i18nn_c2203ee5dfae4d5c": "为宗旨",
	"i18nn_cd3930ce22517879": "用一切服务一切",
	"i18nn_102efe214a898d5b": "用一切造福",
	"i18nn_9f13c329037e62f3": "一切",
	"i18nn_3fd643ad8df4fae1": "来实现三亿家庭生活成本减半的宏伟愿景",
	"i18nn_328cc4e89ad30fa4": "本着以遵守",
	"i18nn_9418be86066b60a7": "中华人民共和国",
	"i18nn_71307d89ef052d78": "有关法律",
	"i18nn_69b07f8efc9989a3": "法规为原则",
	"i18nn_d4801fd5d75ec644": "实",
	"i18nn_8fbed2331b57d1a1": "简单",
	"i18nn_da5a251c55247f12": "透明",
	"i18nn_9d4ceba61eaa1abb": "共有价值观为前提",
	"i18nn_88f0539ec2a2c26f": "站在平台与合伙人不同立场和共同利益考虑",
	"i18nn_d35f532cf9e60897": "制定互易天下",
	"i18nn_bd1ff10dc0bf303a": "以昭共同信守",
	"i18nn_db2a83c04d0d85ee": "一",
	"i18nn_fa6e7d1f41d39100": "合伙人定义",
	"i18nn_4db1716be0ea59bd": "互易天下平台合伙人是指",
	"i18nn_2f6bd901322272fa": "认同互易天下平台文化内涵",
	"i18nn_864d00076a814fa0": "坚定不移执行互易天下平台模式",
	"i18nn_9a9ded56d1676f80": "且具有",
	"i18nn_d2fb068a76ed195c": "法律按年龄明确划分的具有完全民事行为能力或限制民事行为能力在征得其法定代理人同意或由其法定代理人代理民事活动的人",
	"i18nn_5fb08ff5d5491fec": "都可以成为互易天",
	"i18nn_544d970d1844a46c": "下平台的合伙人",
	"i18nn_c9e78713e31e5c0c": "二",
	"i18nn_cc277817a865fb65": "合伙人类别",
	"i18nn_a8ee05f2241e32ab": "互易天下平台合伙人共分为四类",
	"i18nn_86e64c86a65fa0cc": "全职制",
	"i18nn_8f4498e538e7f052": "合伙制",
	"i18nn_a6eadfa2120bdbda": "合作制",
	"i18nn_a2340b22cc0900a8": "顾问",
	"i18nn_afa25ce3ec064f71": "制四类合伙人",
	"i18nn_ae02aee2d329424d": "全职制合伙人",
	"i18nn_a1b2fa40008dc02a": "该类合伙人与平台建立有劳动关系",
	"i18nn_482bbc1edfb4212b": "即",
	"i18nn_9070762c69ee216f": "既为平台员工",
	"i18nn_d58b6fd167ce23f3": "又是平台的合伙人",
	"i18nn_f0172799d05770b5": "其主要职责为平台",
	"i18nn_fc6fe7dce4d7869f": "方案执行和服务",
	"i18nn_9c2dd6d92e53081e": "合伙制合伙人",
	"i18nn_0c60ea2141a1d830": "该类合伙人与平台均无意建立任何性",
	"i18nn_1fd152641b716e8b": "质的劳动关系或雇佣关系",
	"i18nn_69987c10a79424ed": "但其认同平台模式",
	"i18nn_fd2e503d7f311ad2": "愿与平台一同共",
	"i18nn_9f403a997905a3f8": "创未来",
	"i18nn_1decd776deac80a4": "其主要职责为签厂家上传平台展定销",
	"i18nn_fe7856ecd128e1aa": "服务厂家撮合交易参与共营",
	"i18nn_a0da04db4f9323b6": "三",
	"i18nn_e2965b00b95ade08": "合作制合伙人",
	"i18nn_9e7b43bd051ccd6e": "该类合伙人与平台资源共享",
	"i18nn_1e250536b237c7a1": "风险共",
	"i18nn_f466a0b5543471e8": "担",
	"i18nn_90a1cd47c5e78b72": "成为平台共同命运体",
	"i18nn_1fd665c408f54616": "其职责为集采共销合作交易",
	"i18nn_11071e8d339b611c": "四",
	"i18nn_87be271d737f2641": "顾问制合伙人",
	"i18nn_d48ae53d526a6ef6": "该类合伙人具备强大的社会资源和专",
	"i18nn_c716cbc3d235e289": "业能力",
	"i18nn_42c06f1fff7eb653": "为频台的发展提出指导性建议或意见",
	"i18nn_f373aca4d3dd4352": "其主要职责为",
	"i18nn_bcfcc3a8f3358c60": "为平台牵线搭桥引荐资金和人脉资源",
	"i18nn_fb5dbbb1d9dee111": "为平台提出合理化的建议和指导性意见",
	"i18nn_4b414c64a0518911": "上述四类合伙人",
	"i18nn_c812fa907d921480": "在未经平台书面授权或认可的情况下",
	"i18nn_a2e03d3b3202d9a4": "合伙人对外所作任何承诺或意思表示所引发的法律责任由合伙人自行承担",
	"i18nn_a5ad37739b6be6ec": "与平台无关",
	"i18nn_396ed5fde95a69b9": "如何成为平台合伙人",
	"i18nn_238d22b88394aad3": "首先通过",
	"i18nn_a937e3ffc6bd147d": "电脑",
	"i18nn_71a8a4989ac2f256": "端网址",
	"i18nn_7d96efaefe09f6bd": "手机",
	"i18nn_fe2e06b044b1b893": "公",
	"i18nn_d3cf51da3d884626": "众号",
	"i18nn_083712f321b18cf5": "微商号",
	"i18nn_34273845f42f425f": "进入互易天下平台申请合伙人注册",
	"i18nn_c7da087ac749280b": "签",
	"i18nn_e475afa432358ba0": "内容包括",
	"i18nn_10d17b36777cbaab": "合伙人做法",
	"i18nn_cf076b50ebdf2cc0": "要做的内容",
	"i18nn_44c4363e6e150a6d": "好处",
	"i18nn_12a5eff3be7934b7": "晋级",
	"i18nn_7ef158db3b9455aa": "提成分配",
	"i18nn_d9dd1c1febd19fe3": "信用保证金缴纳",
	"i18nn_6ca336250aa9d0cd": "淘汰及退出",
	"i18nn_6f1f4f57ff384b50": "平台审核颁发合伙人信用代码",
	"i18nn_0d16e6ec2ffed2ea": "成为平台正式合伙人",
	"i18nn_6180bde89c6b9b46": "合伙人要做的内容",
	"i18nn_89dd9ca495acc0dd": "推广平台签厂家",
	"i18nn_912cdf1562b4eb64": "撮合成交参与共营",
	"i18nn_eb1a70c1286b62ed": "参与分配拿提成",
	"i18nn_ba63a51c7e0b156c": "具体做法",
	"i18nn_888fa171ec33b1f1": "合伙人推广平台签厂家来平台",
	"i18nn_728648c80c48afe5": "辅助厂家在平台注册",
	"i18nn_fdf7f58baa50347b": "上传产品",
	"i18nn_d01646d3514e6d41": "包括产品名称",
	"i18nn_043132e6ae3f8a83": "规格型号",
	"i18nn_6867f16a2ac5e825": "价格",
	"i18nn_845120789f11f174": "出厂价",
	"i18nn_7ee2103a7671ec55": "批发价",
	"i18nn_cc855b641bb8f525": "代理价",
	"i18nn_b3e2dd1d22720f53": "零售价",
	"i18nn_2ecf13188fa0631c": "质量",
	"i18nn_81821301b16c8a7f": "退货",
	"i18nn_d0a8aa1d10016d23": "收发货",
	"i18nn_0d5eafa1fa619354": "收付款等要求及标准",
	"i18nn_8a20a92f41c62d31": "厂家公约",
	"i18nn_fa23a64d3dae273e": "签约",
	"i18nn_d93c3f2ed0f6b693": "平台收取成交服务费收费标准",
	"i18nn_7357ebb73b1215a7": "帮助厂家",
	"i18nn_a664406e8c731da6": "销售产品",
	"i18nn_efaccf8bb55d83bb": "协助用户消费者下单订制",
	"i18nn_09834ed12622607a": "集采共营",
	"i18nn_77c043016d325c68": "五",
	"i18nn_620b6f3a382ecc0b": "成为平台合伙人和成为一",
	"i18nn_0a3ba2889627f990": "二级合伙人身份的好处",
	"i18nn_0cfcd0903095a00f": "撮合成交拿提成",
	"i18nn_9d4f80aa5fcf2600": "参与共营赚利润",
	"i18nn_a914fd1fc9a283d2": "合伙人签厂家用户越多",
	"i18nn_45f7ba081ac491b5": "成交量就越大",
	"i18nn_14cf9fb9d400716a": "成交服务费就多",
	"i18nn_c5c48b89fc36472e": "提成",
	"i18nn_7683dda9d0e8b37e": "也就越多",
	"i18nn_d5a677daf96988c8": "成为一",
	"i18nn_1e683ce4087f9954": "二级合伙人身份分配越多",
	"i18nn_72afe7fb4bdd4229": "参与共营赚取利润越",
	"i18nn_0dc4093ceeda8961": "大",
	"i18nn_21d38395dfa6bfdc": "分红越多",
	"i18nn_3d86ac98afd3c98b": "六",
	"i18nn_b3b24749a91c0f44": "合伙人晋级",
	"i18nn_97340a49a87440e3": "所有通过平台注册申请并通过",
	"i18nn_114a976e83dba35a": "已签",
	"i18nn_f8e2481d45f58642": "获取合伙",
	"i18nn_f4fd62977f5e219d": "人信用代码的合伙人",
	"i18nn_5d78dda700452eb4": "均为平台正式合伙人",
	"i18nn_a5d2f00920f5e634": "正式合伙人",
	"i18nn_90e44f0cd824c6fe": "签同款产品",
	"i18nn_268e50d838264ca8": "类目",
	"i18nn_6928ab4690f07fc8": "的厂家",
	"i18nn_9b01a3f56b339e8b": "个月达",
	"i18nn_efba320d0caba717": "家以上到平台入驻并上传产品展定销",
	"i18nn_b569b77b36cab65f": "二级代理",
	"i18nn_90022e7e07067117": "名正式合伙人",
	"i18nn_7a0a7af295124dca": "家同行业以上厂家可",
	"i18nn_26580997df11462c": "申请成为二级代理资格",
	"i18nn_679b1f787ae60390": "一级代理",
	"i18nn_74d9d1935c5da8b6": "由",
	"i18nn_5a393ac00b703b18": "个二级代理资格",
	"i18nn_e2eda3a58e77a1d3": "家以上产业链",
	"i18nn_7efb93c6892a72e8": "厂家可申请成为一级代理资格",
	"i18nn_d4165e218d09f19c": "平台拓展分公司",
	"i18nn_c984984f86ade471": "平台在海内外拓展分公司",
	"i18nn_3d47d43d47d3e67d": "每个分公司必须由",
	"i18nn_32aabce6a48fa9c2": "个一级代理",
	"i18nn_058c0cfc47bc6d33": "个二级代理",
	"i18nn_25feef33c757c8ef": "个正式合伙人",
	"i18nn_ba8202deab400178": "找",
	"i18nn_80424b99fc81a62f": "个厂家在平台上展定销",
	"i18nn_dbd9bda0b7dae7ae": "根据平台上厂家提供的供需信息进行撮合服务",
	"i18nn_ed85fa380d2e164a": "通过平台上的金",
	"i18nn_565ec323455e7479": "融",
	"i18nn_90354e648ee81925": "仓储",
	"i18nn_e9c15e7660b4dbf9": "运输等配套资源",
	"i18nn_64c8a8d6619ca8ad": "服务厂家撮合成交",
	"i18nn_8f4e0871030b7c68": "七",
	"i18nn_a820da4109959705": "平台成交服务费收费标准",
	"i18nn_a8bed931f5b7ad6b": "平台制订了五个成交服务费收费标准",
	"i18nn_347c8ce0b0babefd": "由供方合伙人找厂家商",
	"i18nn_382252986497b355": "议",
	"i18nn_7b31746772e8ec31": "厂家并选择收费标准",
	"i18nn_1d946eb520d38b8e": "一星",
	"i18nn_32924b06a28ba407": "成交额的",
	"i18nn_0924078429bf522a": "二星",
	"i18nn_dffc307c1f23d57b": "三星",
	"i18nn_4f60a3a57543ab0b": "五星",
	"i18nn_f3828f9b73412e7d": "自定义",
	"i18nn_c6576c6d0eac12a8": "八",
	"i18nn_a372852a589b5893": "平台成交服务费收费分配比例",
	"i18nn_c8f135cb47650bf3": "正式合伙人的供方",
	"i18nn_8c0433326b89cc2a": "需方合伙人",
	"i18nn_95c6fc87672fdf73": "全职减半",
	"i18nn_a089d6570c8a50e6": "供需合伙人为同一人只拿一头",
	"i18nn_b70c8d1f9c0af0f9": "平台和一",
	"i18nn_9eff6efdcf332948": "二级代理各",
	"i18nn_7ac1f356500fba03": "供需方合伙人不在同一个二",
	"i18nn_b61c03d043c62e32": "级代理的名下",
	"i18nn_d0f707166bff7c04": "所有二级代理平均分配此级别的分配",
	"i18nn_2cb2400dc38279b9": "所有二级代理也不再同一个一级代理名下的",
	"i18nn_59b2a2915f3d0b11": "所有一级代理平均分配此级",
	"i18nn_af7d031b3b342e2a": "别的分配",
	"i18nn_3e6108abecb95544": "分配到平台及一",
	"i18nn_397d77939b36ff03": "二级代理后的分配由各自按自定的方案",
	"i18nn_6ee7a3e31af0a693": "分配",
	"i18nn_5d53f5b6117f5ad8": "九",
	"i18nn_7fd753c6f7d2a5d3": "信用保证金",
	"i18nn_a3b4e8d10ad55be5": "正式合伙人缴纳信用保证金国内为人民币",
	"i18nn_0ffc78c1cedf6eba": "万元",
	"i18nn_0e95d20940921996": "海外为",
	"i18nn_3a6e2b33b574a286": "千美金",
	"i18nn_1ad9d639d66f654f": "以提成金额的",
	"i18nn_185bd8ab7bc97f6f": "扣留作为合伙人信用保证金缴纳方式",
	"i18nn_456eab08dae4f81c": "直至合伙人信用保证金交足为止",
	"i18nn_b977ac60be37cb6d": "国内缴纳",
	"i18nn_36eb08b73f29480a": "万元信用保证金",
	"i18nn_d79c7f2072b4dd24": "海外",
	"i18nn_b98c1e3d365c47ca": "万美金",
	"i18nn_4c8ff9605e60fe84": "万美",
	"i18nn_68fd50d0ed8b84a9": "金",
	"i18nn_170f2d88c49ef1b5": "平台分公司",
	"i18nn_71827d3678b69b71": "万",
	"i18nn_46392b6afe7e0471": "美金",
	"i18nn_0b7774e764cf1000": "十",
	"i18nn_59e36d01a5abbc90": "账户管理",
	"i18nn_5ac06f05852acdc6": "为了使合伙人权益得以保障和体现",
	"i18nn_99185f86a2fdd4db": "平台为合伙人设立保证金账",
	"i18nn_fa4d0b41f7b0d4b7": "户",
	"i18nn_c2c13bbe7f2a4dae": "收益账户",
	"i18nn_a30caadc678acc23": "所有合伙人缴纳信用保证金进入保证金专户",
	"i18nn_b72ed8a176eea28e": "所有用于合伙人接收收益分红及分配提成在当次交易行为结束后自动分配",
	"i18nn_5cd481d9924ab7e0": "的进入合伙人收益账户",
	"i18nn_8f87224ce0fbd4bb": "实行分户专项管理",
	"i18nn_f13d130c9587787a": "本着为合伙人保密原则",
	"i18nn_ad8e6a7c2809873b": "合伙人信用保证金账户开立后",
	"i18nn_36e9435fb8cc49e5": "从合伙",
	"i18nn_7ed28719fc8db8f5": "人每笔应收的交易服务费分成中扣留的",
	"i18nn_93112b5e5d42db9b": "转入其保证金账户",
	"i18nn_d11b2301ab78f749": "直到其保证金账户金额达到所属合伙人级别所要求的信用保证金金额为",
	"i18nn_3e5a12305a12edea": "止",
	"i18nn_a0d23526891e0584": "信用保证金不计息",
	"i18nn_9590f060aa9721fd": "保证金按扣缴方式执行的截止时间为",
	"i18nn_80b0c3338d06194b": "年",
	"i18nn_9954ce6437e66f41": "月",
	"i18nn_43d13c53a89c5324": "日止",
	"i18nn_17b6fee096c996fb": "合伙人到期不续约",
	"i18nn_a4ba4bf2fd70ee3d": "淘汰或终止后",
	"i18nn_c542d75cfbd0fba6": "合伙人方可对",
	"i18nn_a05225eca8f6e206": "其保证金账户款项申请提现",
	"i18nn_d8ebcaf3388ea2fb": "十一",
	"i18nn_0801f008763f8f15": "淘汰机制",
	"i18nn_117bf276d635ee58": "以下任一情形出现时",
	"i18nn_feedba7cb76f972d": "平台有权力淘汰或终止与合伙人的合作",
	"i18nn_3f509a93b32fd953": "并根据情节严重性",
	"i18nn_97a55452b2d0116a": "扣除信用保证金",
	"i18nn_f11df866602c729a": "本公约生效后三个月内",
	"i18nn_81feaba88f025156": "合伙人所签厂家数量不足",
	"i18nn_0bc8557f94665a04": "家",
	"i18nn_5eecd414376ff2cd": "合伙人所签厂家在平台上完成的",
	"i18nn_5adc14e6e0fdd421": "有效交易次数",
	"i18nn_19bfdbea32080db0": "或金额",
	"i18nn_85f98f06e15622f7": "为零的",
	"i18nn_9d3ca05d9f9b06ed": "合伙人对签到平台的厂家产品的价格",
	"i18nn_056d0abfe8796d95": "质量不实",
	"i18nn_e7fc4f2b82fba8d9": "发生产",
	"i18nn_56e7d9c7afbbd121": "品售后问题不负责与厂家协商解决的",
	"i18nn_8dca96adb3d48663": "合伙人利用平台从事非法商业活动",
	"i18nn_245d3cb7d46b100a": "宣传不实信息给平台不良影响的等",
	"i18nn_48271ca007768dd9": "未经平台同意",
	"i18nn_89c7d6e1ae192ad0": "以平台名义或双方共同名义对外开展与平",
	"i18nn_6e7587a110dfc099": "台无关活动的",
	"i18nn_96a9e71d8a7a0881": "因合伙人个人的行为而直接或间接给平台带来民事",
	"i18nn_cc3b41cc72da1911": "行政",
	"i18nn_7ba4ade1ccb742da": "或刑事法律责任",
	"i18nn_0657f64fd9dcd925": "并造成损失的",
	"i18nn_9f63e166d4834186": "针对于本大条中的",
	"i18nn_94d595f0a6a1c12a": "小条",
	"i18nn_67add2353b457382": "平台有权扣除合伙人保证金",
	"i18nn_a74f3266b1a1c772": "十二",
	"i18nn_d72b426cd7130758": "双方约定",
	"i18nn_1ea51a7f18278909": "平台有权制定合伙人准入的条件",
	"i18nn_ab9ceae7c811ce60": "审核合伙人资质",
	"i18nn_32ef4158db3cdf4e": "制定合作涉及的运营规则与退出机制",
	"i18nn_a24ec27332f767be": "并有权根据实际需",
	"i18nn_129b6b0629df7b19": "要适用修订相应规则",
	"i18nn_adb76a3968136e2d": "机制",
	"i18nn_bbdb25136b1c49bb": "制定平台的产品或服务交易规则",
	"i18nn_bc0940606bc2530c": "对于平台运作的规则",
	"i18nn_489e93d5e5b2951f": "及其产品",
	"i18nn_833e79ef081ff3aa": "服务等有最终解释权",
	"i18nn_b4ab969c28963b46": "平台所有商品数据资源与合伙人实行共享",
	"i18nn_67c250e17be45cfb": "提供合伙人推广及成交过程中所需的资料与服务",
	"i18nn_1bc6208e61b1a0c5": "如信息",
	"i18nn_f4c3e91a86d23afe": "服务",
	"i18nn_e7c5c872e6c2effe": "仓储物流",
	"i18nn_b1d97120606bb674": "资金服务等",
	"i18nn_052838380b61816c": "所有服务甲方均拥有最终解释权",
	"i18nn_bca49daf0d694e16": "撮合交易服务费",
	"i18nn_12f595dd54e3cabb": "当合伙人推荐的厂家在平台发生交易",
	"i18nn_08dae0e0a7266acb": "时",
	"i18nn_0bf6504606c38c92": "根据厂家交易订单的商品类目及厂家在订单中供需方角色不同",
	"i18nn_43d391028e658f35": "合伙人将按比例获得对应的服务费分配",
	"i18nn_02c1c0a507012ebe": "双方均应对本公约内容及项下的合作事宜保守秘密",
	"i18nn_663eea3ddfccac53": "如果",
	"i18nn_86b6709076588aca": "任何一方需要向任何第三方",
	"i18nn_b934f1cb213adecb": "包括媒体",
	"i18nn_419e57660fa67d89": "披露与双方合作有关的关键",
	"i18nn_bfb1fde233da0fcd": "必须事先征得对方书面同意",
	"i18nn_b039e8edd989af80": "双方应对在讨论",
	"i18nn_b259d9d61753193d": "签订",
	"i18nn_c0251ce6788ee43d": "执行本公约过程中所获悉的属于",
	"i18nn_74ae946b19ce1319": "对方的且无法自公开渠道获得的文件及资料",
	"i18nn_3034a6a684fde8fe": "包括商业秘密",
	"i18nn_7125c04ad76103fa": "公司计",
	"i18nn_baf85ef9349c05da": "划",
	"i18nn_4633f280b5630071": "运营活动",
	"i18nn_75fba0825ef06612": "财务信息",
	"i18nn_6becddeb062aaf22": "技术信息",
	"i18nn_45e13f8f60933edc": "经营信息及其他商业秘密",
	"i18nn_9b0066623179dc21": "予",
	"i18nn_c79bca7697acbdaa": "以保密",
	"i18nn_d87ddb906729f87b": "未经该等资料和文件的原提供方书面同意",
	"i18nn_710af0f9440ed3f8": "任何一方均不得",
	"i18nn_03da3028f894f2d2": "以任何形式向任何第三方泄露该等文件及资料的全部或部分内容",
	"i18nn_499e1a59b5f256dd": "但",
	"i18nn_cdb9a974df82eef1": "法律",
	"i18nn_2e2edfd4eab56b11": "法规另有规定或监管机构要求的除外",
	"i18nn_de488fca42124938": "根据法律",
	"i18nn_7626e861baccae67": "法规规定",
	"i18nn_040a6b8d39f84bd7": "或监管机构要求披露信息的",
	"i18nn_12b3046dc70dceff": "被要",
	"i18nn_9175ad8cff37c934": "求披露信息的一方在采取任何信息披露行动前告知对方",
	"i18nn_f41eb717bc1dfbac": "并采取合理",
	"i18nn_316152fc08c0da25": "努力将该等披露程度减少至最低",
	"i18nn_381860a57bd19810": "十三",
	"i18nn_bd6c2e98769d4620": "其它",
	"i18nn_cf2db82165da1b4d": "甲乙双方确认",
	"i18nn_74173fb619507494": "在本公约中虽以合伙人相称",
	"i18nn_cc1bb411f7963777": "但甲乙双方为平等",
	"i18nn_5571298be765209a": "主体之间的业务合作",
	"i18nn_441ed2abdfb63f6d": "协作关系",
	"i18nn_f92e0cb623da52b5": "合伙人作为平台推广的合作伙伴",
	"i18nn_9fe767cfd3cd3bcc": "并不构成法律意义上的合伙关系或其他投资关系",
	"i18nn_f2c156a2fb44b55a": "本公约自甲方盖章",
	"i18nn_681c55bb14a4d1f2": "乙方签字之日起成立并生效",
	"i18nn_2524308e07d746a1": "本公约用中文书写",
	"i18nn_e6fdbeb061f9d0d3": "壹式贰份",
	"i18nn_8f3c5dbaaf83e39e": "双方各执壹份",
	"i18nn_4aed7d6987de577c": "各份具有同等法",
	"i18nn_4e6db2b4b153efa9": "律效力",
	"i18nn_979b413670d2860e": "本公约由以下双方于",
	"i18nn_5d7247f43e5c5cfe": "日签订",
	"i18nn_eb5d5a99b240f54b": "因签订",
	"i18nn_c21f80d95586a2f4": "履",
	"i18nn_8ee6e5467f6f1538": "行本公约引发的一切争议由甲方所在地管辖的人民法院解决",
	"i18nn_13cf87fb6937eb46": "十四",
	"i18nn_e34486fbf5843a7f": "有效期限",
	"i18nn_ac03b4ce8e7e007a": "本公约有效期限为",
	"i18nn_8948a5da9ed15431": "自",
	"i18nn_95cb0445a7d96ce8": "日起至",
	"i18nn_bab352df1c2bac01": "乙方合伙人编号",
	"i18nn_3e81fe8dabec8f2c": "本公约期限届满前",
	"i18nn_bb3d670df5390f32": "个月",
	"i18nn_f5d3c1aa43ac979d": "双方未就续期事宜提出书面异议的",
	"i18nn_4ab9342118c93729": "合作期限自动延续",
	"i18nn_4bc3c046369fd863": "本公约由平台最终解释",
	"i18nn_e9b236ea1f7fda4f": "本页以下为签字盖章页",
	"i18nn_78834068a3660d26": "无正文",
	"i18nn_a449d26c32ec2ca0": "盖章",
	"i18nn_bb5514b4f2828409": "甲方授权代表",
	"i18nn_3b0f44e4c6735d29": "签字",
	"i18nn_8758fd50c87d6c9c": "地址",
	"i18nn_2d13b6f878f30be3": "电话",
	"i18nn_3578997e3a1087af": "签订日期",
	"i18nn_ac2c5e612fb71dbb": "日",
	"i18nn_2b5688384315e441": "乙方名称",
	"i18nn_e4f49ccfbf632041": "乙方签字",
	"i18nn_5a9fb0915ecea987": "联系电话",
	"i18nn_5901732d5153a40f": "阅读",
	"i18nn_20a5eb4c1cf66611": "秒之后可操作",
	"i18nn_c6f16a932dc37e57": "我已阅读",
	"i18nn_904f5fd4732c0ec1": "已阅读确认",
	"i18nn_1f5b8c6eb3a1e9ec": "开始倒计时",
	"i18nn_cce168301447d1ba": "进行中",
	"i18nn_9b2077cfd69c98be": "倒计时初始换",
	"i18nn_f2d88286137980f3": "已阅读",
	"i18nn_c6a422bfbd7a259f": "合伙人公约已阅读提交",
	"i18nn_2721a95dbb3df010": "合伙人公约已阅读失败",
	"i18nn_158eb06e6609b0b7": "用户注册",
	"i18nn_a1805b0f0117f109": "用户名",
	"i18nn_de0f748d866726e3": "已有账号",
	"i18nn_05682dbef5eeb0a8": "客服",
	"i18nn_08cc7d24752ec42c": "在线客服",
	"i18nn_6839940dac08c794": "注册",
	"i18nn_79444f5839826bf0": "用户名长度在",
	"i18nn_5a9aefbc03c778f7": "请输入",
	"i18nn_22a5895a0978844b": "字母和数字组合的密码",
	"i18nn_e097bb9b1216bfd8": "联系客服",
	"i18nn_7482e9abfd1ac67c": "验证重复密码",
	"i18nn_c9946fd976ed060f": "请检查提交数据",
	"i18nn_ec6569171730677e": "播放失败声音",
	"i18nn_6d1ee1adad751621": "注册提交",
	"i18nn_b97755954a17a764": "显示协议",
	"i18nn_c39705483df9ffdb": "注册请求",
	"i18nn_ddaf6df4419b69a5": "请选择国际区号",
	"i18nn_aa1885a3ade2546d": "注册失败",
	"i18nn_a4e96672f059a795": "第一次存不到缓存",
	"i18nn_a358cd5f57ab067c": "已注册成功",
	"i18nn_4639f3a6e07c00b3": "下一步",
	"i18nn_fbe2f7bf0e7aa139": "您想",
	"i18nn_cb7e41fd1feae9d5": "认证厂家",
	"i18nn_86c45eefdac6e2ea": "认证完成后",
	"i18nn_c7da3d779b7c09ef": "您可以展示",
	"i18nn_fa3352ed2c26ed60": "推广",
	"i18nn_792a7b820e4d978c": "订购",
	"i18nn_bbee2572b3cf6bba": "销售",
	"i18nn_d2fcb76d57206ef6": "报价等",
	"i18nn_13fc2e4b355357dd": "认证合伙人",
	"i18nn_bb02b24075180755": "您可以邀请厂家入驻平台",
	"i18nn_d6446677c75949ba": "享受收益等",
	"i18nn_87143dc9fc2e5b2f": "开通仓库",
	"i18nn_a4e93eab295e80f9": "开通仓库后",
	"i18nn_3e199baddb9a3e4f": "体验仓储",
	"i18nn_eeb1f61b0d4e9282": "物流",
	"i18nn_57495c18e2baebdc": "转运",
	"i18nn_d1b7d51dbf161c83": "快递等一站式服务",
	"i18nn_6416685506bcd152": "去网站首页",
	"i18nn_3efb3a8f711a3238": "随便逛逛",
	"i18nn_ffc145fd3af04e0c": "购买厂家商品",
	"i18nn_1c598a42b6bb8c77": "厂家认证后",
	"i18nn_359c834d8f0c25e3": "方可交易",
	"i18nn_4d9fdb27057bc527": "商户认证会有更多惊喜等着你哦",
	"i18nn_4f5bb4ff8b3d804b": "确认",
	"i18nn_5e31c5dc9f86182e": "安全提示",
	"i18nn_ad56506c11ba83ba": "请妥善保管您在互易天下平台的账号和密码",
	"i18nn_52cd94bf8bf01ca9": "感谢您选择互易天下",
	"i18nn_f08eba7edcb8b649": "欢迎注册",
	"i18nn_57810f3904cf54f0": "互易天下用户注册协议",
	"i18nn_5be1d9322af32101": "注册协议注册协议注册协议注册协议注册协议注册协议",
	"i18nn_f5b58637ea2847ff": "本协议是您与互易天下",
	"i18nn_847f063538ae47c2": "简称",
	"i18nn_44cbed56206a3684": "本站",
	"i18nn_1617e69bdeb3610d": "网址",
	"i18nn_50e396256589cdc2": "所有者互易天下网络科技有限公司",
	"i18nn_b1d880b6c53a8b59": "以下简称为",
	"i18nn_75a9d8d5c827d109": "之间就互易天下服务等相关事宜所订立的契约",
	"i18nn_d11fa26e00422b29": "请您仔细阅读本注册协议",
	"i18nn_57f8c68a66b6528a": "您点击",
	"i18nn_39e4d72bf3f44e5e": "同意并继续",
	"i18nn_540a0fe90cb60295": "按钮后",
	"i18nn_94fa28fdfe1436a0": "本协议即构成对双方有约束力的法律文件",
	"i18nn_080a48f195815c62": "第",
	"i18nn_56d240221524ba15": "本站服务条款的确认和接纳",
	"i18nn_a72391cf0f8d175e": "本站的各项在线服务的所有权和运作权归互易天下所有",
	"i18nn_ee488258c67d0751": "您同意所有注册协议条款并完成注册程序",
	"i18nn_f9d30539f422a8f7": "才能成为本站的正式用户",
	"i18nn_59ff278506af8adb": "您确认",
	"i18nn_8c3ba316859326e4": "本协议条款是处理双方权利义务的契约",
	"i18nn_d175ff3ab16e95aa": "始终有效",
	"i18nn_f749eef5d936255c": "法律另有强制性规定或双方另有特别约定的",
	"i18nn_ea1b21d072680ce5": "依其规定",
	"i18nn_749dec61fc4fa71a": "您点击同意本协议",
	"i18nn_423bb7a1d940d1f5": "即视为您确认自己具有享受在线购买商品及享受服务等相应的权利和行为能力",
	"i18nn_6b12e36363211903": "能够独立承担法律责任",
	"i18nn_db2d3ef553a2e8e8": "互易天下保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务",
	"i18nn_a2886ebc43e802fc": "关闭用户账户",
	"i18nn_05f742626881858c": "清除或编辑内容或取消订单的权利",
	"i18nn_69d3dd8956fe8109": "本站服务",
	"i18nn_c1db3b4dfc3f1380": "互易天下通过互联网依法为您提供钢材",
	"i18nn_5a90bf08ed58d528": "木材",
	"i18nn_afb8b6445456be11": "棉花等信息和服务",
	"i18nn_01f880df733f7777": "您在完全同意本协议及本站规定的情况下",
	"i18nn_66b14a4891099d9c": "方有权使用本站的相关服务",
	"i18nn_4d20e95ad039b9b3": "用户信息",
	"i18nn_4f141b148cc1f9fd": "您应本着个人",
	"i18nn_589c69448c96f2f2": "厂家诚信向本站提供注册资料",
	"i18nn_1997bf6f724c8ec8": "您同意",
	"i18nn_5c869db57790b0d4": "提供的注册资料真实",
	"i18nn_ae47d38aaadb40aa": "准确",
	"i18nn_bca9aee06ca1eabd": "完整",
	"i18nn_45ea35f5a291efcb": "合法有效",
	"i18nn_fa3e9a18afc823c8": "注册资料如有变动的",
	"i18nn_ea45a514c64bd41c": "应及时更新其注册资料",
	"i18nn_c4e2c9af94a18570": "如果您提供的注册资料不合法有效",
	"i18nn_b23a73776a75c5a6": "不真实",
	"i18nn_caa2a52bfea9d262": "不准确",
	"i18nn_89ecdc30541cd9fd": "不详尽的",
	"i18nn_4ef986878a954c6a": "您需承担因此引起的相应责任及后果",
	"i18nn_67daac682d06b34f": "并且互易天下保留终止您使用互易天下各项服务的权利",
	"i18nn_8ac21bdef7cfd94a": "您知悉并同意",
	"i18nn_b73f7ffc29e2cf44": "为方便您享受本站提供的浏览",
	"i18nn_e590103d2be0a214": "下单锁货等服务",
	"i18nn_b5ce31d47062f89c": "本站将储存您在使用时的必要信息",
	"i18nn_1be0f2a3afeff1f6": "包括但不限于您及厂家的真实姓名",
	"i18nn_b590883f49941893": "通信地址",
	"i18nn_c2d9cc88561f8cdc": "联系方式",
	"i18nn_35eaaae7173876b5": "营业执照等",
	"i18nn_f720ba7209821a76": "本站将予以严格保密",
	"i18nn_eb00b6fa88df4c6b": "未经您的授权或法律法规另有规定的情形外",
	"i18nn_00c0a029c9a5ac6d": "本站不会向第三方披露您或厂家的隐私信息",
	"i18nn_82dfae68029e9cce": "您注册成功后",
	"i18nn_fc9c4e460812357f": "将产生密码等账户信息",
	"i18nn_32b2f589c4aea314": "您可以根据本站提示修改您的密码",
	"i18nn_8d5d2c47c4995c5b": "您应谨慎合理的保存",
	"i18nn_4c5418237b921823": "使用密码",
	"i18nn_f4bf488838ab0b62": "您若发现任何非法使用您账号或存在安全漏洞的情况",
	"i18nn_6725fc584ea69f40": "请立即通知本站",
	"i18nn_70a2579e4c577932": "否则",
	"i18nn_37ec75a6e88cfd66": "由于您自身的疏忽而导致账号信息泄露等后果",
	"i18nn_81a50fd2c2cfb8fb": "由您自行承担",
	"i18nn_56dfa540bc7f88c6": "您充分理解并同意",
	"i18nn_5b924000df14a370": "互易天下拥有通过邮件",
	"i18nn_d939d4dbba69d4e5": "短信",
	"i18nn_f17c35875e6d8212": "电话等形式",
	"i18nn_b9fb1c5b6ca36a08": "向在本站注册",
	"i18nn_f70bea56cd1529db": "购物的您发送订单信息",
	"i18nn_a11ebcc06e655b59": "促销活动等告知信息的权利",
	"i18nn_8970a1dc84c478d5": "您若将在本站注册获得的账户借给他人使用",
	"i18nn_25b98478c8bbc0c7": "则必须承担由此产生的全部责任",
	"i18nn_93aff52c515abf4a": "且实际使用人需承担连带责任",
	"i18nn_916aec9f00ae8811": "您充分理解",
	"i18nn_c9401b95b0a95520": "为配合行政监管机关",
	"i18nn_f84c87a3e5974d12": "司法机关执行工作",
	"i18nn_885a04901305d91b": "在法律规定范围内",
	"i18nn_fc86a23586ebf0ea": "互易天下有权使用您的注册信息",
	"i18nn_40d127140e2b07b5": "密码等信息",
	"i18nn_ff714cb2bcf69d6c": "登陆进入您的注册账户",
	"i18nn_6f4daff5a477fb65": "进行证据保全",
	"i18nn_e6441e361daa8e8b": "包括但不限于公证",
	"i18nn_eb81c10d79989051": "见证等",
	"i18nn_6355fc03899b758b": "厂家账号",
	"i18nn_62be0e5b38d91df3": "为享有包括采购等更多的服务和平台操作功能",
	"i18nn_44deb7c398d86106": "您可以申请厂家账号",
	"i18nn_d9bd7084aa298ef2": "首先需具备注册资格",
	"i18nn_2d735d596a67d630": "您必须确保系依照中华人民共和国法律法规设立的合法组织",
	"i18nn_7202e08a92262d76": "并具有在中华人民共和国境内取得工商经营主体资格",
	"i18nn_f0e42634e00f9888": "并按平台提示上传相应证件",
	"i18nn_17392f136c88f3b3": "如您没有前述主体资格",
	"i18nn_09d0e8fe78eae4a8": "本站有权拒绝您注册或认证厂家账户的申请",
	"i18nn_ee5040ed3069d235": "据此造成的损失由您自行承担",
	"i18nn_c87622872c85a469": "在您按照互易天下注册页面提示填写信息",
	"i18nn_f57805133dd40d81": "阅读并同意本协议并完成全部注册程序",
	"i18nn_1be80dcaafc0f92e": "您即为互易天下厂家账号",
	"i18nn_ec25c06d1b055cf1": "在您按照互易天下认证页面上传符合要求的证件并经互易天下审核通过后",
	"i18nn_daf2c5e69342a6b7": "您即为互易天下认证后的厂家账号",
	"i18nn_aae301f0604c7fe0": "您在成功完成厂家账号认证后",
	"i18nn_764c488a4bc81158": "您可以通过互易天下平台在线交易",
	"i18nn_348f6f4fa4b0f5f2": "发布资源信息",
	"i18nn_1534e2ceb7641965": "发布求购信息",
	"i18nn_bad66de9d9f0f07a": "下载资源单等厂家会员服务",
	"i18nn_fd340dd763af3114": "您申请或认证厂家账号时",
	"i18nn_edc3906c2df1c65c": "您设置的厂家信息不得侵犯或涉嫌侵犯他人合法权益",
	"i18nn_b0d827734869f320": "如您连续",
	"i18nn_ea4c0766739039c5": "个月未有使用手机号和密码登录互易天下及信息更新记录或实际使用互易天下服务的行为",
	"i18nn_dcaaf526f0186532": "互易天下保留对您中止或终止提供服务并注销账户的权利",
	"i18nn_63feebdf9f8317f6": "账户说明",
	"i18nn_946af3623f53e067": "您应对您的手机号和密码的安全",
	"i18nn_540e58a650e9014d": "以及对通过其手机号和密码实施的行为负责",
	"i18nn_e93f591f99eb7d6b": "您在互易天下注册的手机号和密码仅限于您自身内部人员进行使用",
	"i18nn_9efc42cbab44a7e2": "不得给予任何第三方使用",
	"i18nn_8a205a5810035959": "否则由此造成的损失由您自行承担",
	"i18nn_8356d005135c3671": "且互易天下保留暂停或终止服务",
	"i18nn_2fd4605eed633bc1": "除非有法律规定或司法裁定",
	"i18nn_3cf4abe1bab9593a": "且征得互易天下的同意",
	"i18nn_28644062d111b04b": "手机号和密码不能以任何方式转让",
	"i18nn_77fa364cf4a6e826": "赠与或继承",
	"i18nn_e23f00adfc1c40d9": "您遗忘或丢失在互易天下注册的密码时",
	"i18nn_2168ed76503d5311": "可与互易天下客户服务人员取得联系",
	"i18nn_218e22fcebaaac47": "在提供相关证明资料并经互易天下审核确认后",
	"i18nn_4f637ee4a38e94a2": "可找回密码",
	"i18nn_fb349d677c1cdc07": "信息的修改",
	"i18nn_04a51265b063cf52": "您注册为厂家账号后",
	"i18nn_0441811b67bace22": "如注册登记信息发生变更",
	"i18nn_4bb17ed394ba09ad": "您必须及时",
	"i18nn_0e7dda6b4ca6c6ad": "主动联系互易天下进行更新",
	"i18nn_6c2e062931c41b66": "因您未及时更新",
	"i18nn_c26311abd0a76987": "造成您不能享受相关服务的",
	"i18nn_eeb6e302d6b54ae9": "或造成账号及密码泄露等所有责任由您自行承担",
	"i18nn_0314b1e17596d402": "用户依法言行义务",
	"i18nn_5b28c75dd2f53f11": "本协议依据国家相关法律法规规章制定",
	"i18nn_22b8ebf08650b6de": "您同意严格遵守以下义务",
	"i18nn_721d7b94cc715f74": "不得传输或发表",
	"i18nn_46a477ce2810860f": "煽动抗拒",
	"i18nn_b76d99503414cbd3": "破坏宪法和法律",
	"i18nn_4b28eb649b386cf9": "行政法规实施的言论",
	"i18nn_442c09a112856924": "煽动颠覆国家政权",
	"i18nn_bf911542eabc962f": "推翻社会主义制度的言论",
	"i18nn_953dcc37a323a844": "煽动分裂国家",
	"i18nn_068ba2308aa6c4ea": "破坏国家统一的的言论",
	"i18nn_201aa99ff5cfc514": "煽动民族仇恨",
	"i18nn_c8d958b19be8c4ee": "民族歧视",
	"i18nn_f4e81d831a14f788": "破坏民族团结的言论",
	"i18nn_429818aa524c71a6": "从中国大陆向境外传输资料信息时必须符合中国有关法规",
	"i18nn_e34a7819b80a914c": "不得利用本站从事洗钱",
	"i18nn_7867cfa781410949": "窃取商业秘密",
	"i18nn_0e23a34fa9b859bc": "窃取个人信息等违法犯罪活动",
	"i18nn_83a92e9a857d5756": "不得干扰本站的正常运转",
	"i18nn_cbca82a8ce59465c": "不得侵入本站及国家计算机信息系统",
	"i18nn_0eb3ccfe7db52feb": "不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论",
	"i18nn_514434e7272fcae9": "不得教唆他人从事本条款所禁止的行为",
	"i18nn_0afa127195edc24c": "不得利用在本站注册的账户进行非法牟利经营活动",
	"i18nn_37ba1da998009ce8": "您应不时关注并遵守本站不时公布或修改的各类合法规则规定",
	"i18nn_1f4037d9acd6f84b": "本站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知您的权利",
	"i18nn_611d8b9bd1772698": "若您未遵守以上规定的",
	"i18nn_c5963da7148011bf": "本站有权作出独立判断并采取暂停或关闭帐号等措施",
	"i18nn_b10ac26f4cd53aea": "您须对自己在本站的言论和行为承担法律责任",
	"i18nn_44566798e85d4051": "您若在本站上散布和传播反动",
	"i18nn_a6e757d6312039e4": "色情或其它违反国家法律的信息",
	"i18nn_23b5f9ce0d48673d": "本站的系统记录有可能作为您违反法律的证据",
	"i18nn_b9b850a26aa3fc61": "商品信息",
	"i18nn_d430ed5f1fd86582": "本站上的货物价格",
	"i18nn_f9db93b87e08763b": "数量",
	"i18nn_0065f7b5452a359b": "是否有货等商品信息将根据市场行情及销售情况随时发生变动",
	"i18nn_850cd73094d2765e": "本站不作特别通知",
	"i18nn_ae88867720c619c8": "由于网站货物信息数量庞大",
	"i18nn_661a588c5b9caa79": "虽然本站会尽最大努力保证您所浏览商品信息的准确性",
	"i18nn_ede444f1380c40f7": "但由于网络及电脑终端兼容性等客观原因存在",
	"i18nn_7996d7085d24284b": "本站网页显示的信息可能会有一定的滞后性或差错",
	"i18nn_528e55db5929b0ee": "对此情形请您知悉并理解",
	"i18nn_b02302fc52e11ab7": "互易天下欢迎纠错",
	"i18nn_0d17ea8133d5f70e": "并会视情况给予纠错者一定的奖励",
	"i18nn_32054cc7f7ee7f36": "责任限制及不承诺担保",
	"i18nn_acfdb3a5fcb125c4": "除非另有明确的书面说明",
	"i18nn_31a4bba7da32d20b": "本站及其所包含的或以其它方式通过本站提供给您的全部信息",
	"i18nn_e2b0171973515a26": "资料",
	"i18nn_e17d27d6953f7e51": "货物",
	"i18nn_06f19aa00900c2e1": "包括软件",
	"i18nn_22fe88e60adbafb1": "和服务",
	"i18nn_a8320eb62b59abe9": "均是在",
	"i18nn_7edccc097803a6b5": "按现状",
	"i18nn_200d41b16c4a78c9": "按现有",
	"i18nn_1e02c820196d1d59": "的基础上提供的",
	"i18nn_9cef3573f3925e97": "互易天下不对本站的运营及其包含在本网站上的信息",
	"i18nn_5eacb8fa64e8905f": "和服务作任何形式的",
	"i18nn_23acb0eaddb19069": "明示或默示的声明或担保",
	"i18nn_a6f61a0a98b035e4": "中华人民共和国法律另有规定的除外",
	"i18nn_da8bb1339f6bb02a": "如因不可抗力或其它本站无法控制的原因使本站销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息",
	"i18nn_583c0f6302128d13": "记录等",
	"i18nn_42cfc7b2859838a7": "互易天下会合理地尽力协助处理善后事宜",
	"i18nn_c76acd10925180f7": "协议更新及用户关注义务",
	"i18nn_396d9d81b8028d59": "根据国家法律法规变化及网站运营需要",
	"i18nn_71ab57571973f47f": "互易天下有权对本协议条款不时地进行修改",
	"i18nn_875a73804f3c06a9": "修改后的协议一旦被张贴在本站上即生效",
	"i18nn_43c8834e693d577b": "并代替原来的协议",
	"i18nn_86f09fc76744266d": "您可随时登陆查阅最新协议",
	"i18nn_7e3859ed93aa2cff": "您有义务不时关注并阅读最新版的协议及网站公告",
	"i18nn_a16474cf808d3717": "如您不同意更新后的协议",
	"i18nn_b81983eb6355dd7b": "可以且应立即停止接受互易天下依据本协议提供的服务",
	"i18nn_b2f70a947a4f8338": "如您继续使用本网站提供的服务的",
	"i18nn_d92c206b1fe1fff5": "即视为同意更新后的协议",
	"i18nn_821407e254dd6c00": "互易天下建议您在使用本站之前阅读本协议及本站的公告",
	"i18nn_ea3adaa4544cb4af": "如果本协议中任何一条被视为废止",
	"i18nn_0c5541318b646707": "无效或因任何理由不可执行",
	"i18nn_e64b61ddaf269ac1": "该条应视为可分的且并不影响任何其余条款的有效性和可执行性",
	"i18nn_79aff516affb1636": "任何经互易天下确认已经违反相关法律法规或本协议或互易天下使用规则某一项或多项规定的用户",
	"i18nn_1881093806c26ab5": "互易天下有权不经通知随时对相关内容进行删除",
	"i18nn_8815e67ded9ed035": "屏蔽",
	"i18nn_b2a368f1e04b4df8": "并视行为情节对违规帐号处以包括但不限于警告",
	"i18nn_8b214aeb6795d1f5": "限制或禁止使用部分或全部功能",
	"i18nn_9b69450aa736a152": "帐号封禁直至注销",
	"i18nn_c569de36eb902322": "决定是否给予暂停使用或终止使用的处理",
	"i18nn_2c427ade9cbf7292": "且因此造成的一切后果由您自行承担",
	"i18nn_828fa526a9adb7e4": "用户与互易天下基于交易合作签署的其他书面协议与本协议不一致的",
	"i18nn_172a2e0ac17bb8bf": "以双方书面签署的协议为准",
	"i18nn_8fdf57085475a475": "您理解并同意",
	"i18nn_dcb5adf4319ad531": "因您违反本协议或相关服务条款的规定",
	"i18nn_15351f34c7f1ef6e": "导致或产生第三方主张的任何索赔",
	"i18nn_00b71a9fcad1312a": "要求或损失",
	"i18nn_3b0ba10bd38ebda4": "您应当独立承担责任",
	"i18nn_87a9ae2f109fbb01": "互易天下因此遭受损失的",
	"i18nn_4ff40e75d2d61877": "您也应当一并赔偿",
	"i18nn_75ecb79dc6f5ea71": "法律管辖和适用",
	"i18nn_ff0abe69f2fc4d64": "本协议的订立",
	"i18nn_308ff9434d6bd57a": "执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律",
	"i18nn_00d7f9783b600d66": "但不包括其冲突法规则",
	"i18nn_a8e12205ffa12f70": "如发生本协议与适用之法律相抵触时",
	"i18nn_acde2cb292359539": "则这些条款将完全按法律规定重新解释",
	"i18nn_ad5a6dfbc56fcc23": "而其它有效条款继续有效",
	"i18nn_1ac0f8abb3d1a0fe": "如缔约方就本协议内容或其执行发生任何争议",
	"i18nn_63394f8be3f81666": "双方应尽力友好协商解决",
	"i18nn_3506c985d2c0da52": "协商不成时",
	"i18nn_d509449d0015d3c7": "任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼",
	"i18nn_97e49d00bcc4c17c": "其他",
	"i18nn_4a07259fcecc1325": "互易天下所有者是指在政府部门依法许可或备案的互易天下经营主体",
	"i18nn_fadbffb894d983df": "本协议中任何一条被视为废止",
	"i18nn_0ccb8c44387fd7ca": "本协议未明示授权的其他权利仍由互易天下保留",
	"i18nn_79a687415baa4dbf": "您在行使这些权利时须另外取得互易天下的书面许可",
	"i18nn_3f0af30c152f53d8": "互易天下如果未行使前述任何权利",
	"i18nn_b5bac76ee0722166": "并不构成对该权利的放弃",
	"i18nn_c0941f9bace00590": "互易天下尊重用户和消费者的合法权利",
	"i18nn_40106d96a3fb5cdb": "本协议及本站上发布的各类规则",
	"i18nn_99c54f78e5584cdf": "声明等其他内容",
	"i18nn_9a4bd82f308cdf28": "均是为了更好",
	"i18nn_d36f59b156616013": "更加便利的为用户和消费者提供服务",
	"i18nn_17379360b807e5e5": "本站欢迎用户和社会各界提出意见和建议",
	"i18nn_86ccd6fccf1c1e7f": "互易天下将虚心接受并适时修改本协议及本站上的各类规则",
	"i18nn_0f03037ecaf1990d": "找不到页面",
	"i18nn_ca5a01a5adf20fe7": "返回",
	"i18nn_e2ef45e045968e11": "导入",
	"i18nn_e8c8c5ee90b04380": "已上传",
	"i18nn_d54012286fece209": "下载地址",
	"i18nn_fb949604ce64a27d": "买家中心",
	"i18nn_e127ec3da1353c98": "收货账单",
	"i18nn_f5d43732e3f6cf4d": "附件",
	"i18nn_79fd9ff9de54ef02": "初始化上传控件",
	"i18nn_6e1b866b2add32bb": "上传回调",
	"i18nn_3144aad544eb9ac4": "上传成功",
	"i18nn_d63854ffd4e0f2df": "共上传",
	"i18nn_7f18cb0ceb569eb0": "个附件",
	"i18nn_ad4345dbaabe1479": "提交",
	"i18nn_bc868e024b80d2e3": "提交成功",
	"i18nn_de017dafc266aa03": "删除成功",
	"i18nn_2e58cb9b52e2a214": "已取消删除",
	"i18nn_9e5e4dfbc3ec6aa9": "提交信息",
	"i18nn_4195755cd53e871a": "查询成功",
	"i18nn_d74d0bd89431d6d5": "查询失败",
	"i18nn_b79ec1aa21d476b6": "异常件浏览",
	"i18nn_41413cddfa61538c": "操作提示",
	"i18nn_5beb6ed7e8fdff3e": "此为录入仓库的",
	"i18nn_16aa273f2941c9b5": "数据",
	"i18nn_e0f97fb9747905dc": "编码",
	"i18nn_209a1dc79c180718": "英文标题",
	"i18nn_9db48ed0e8ec64a6": "价值",
	"i18nn_96f1a4b17e75892d": "重量",
	"i18nn_38bc71c0dc55904b": "尺寸单位",
	"i18nn_0e60303b30d5d4b4": "长",
	"i18nn_6cd31871f8528dd5": "宽",
	"i18nn_93c9ea4a0e52c641": "高",
	"i18nn_004617dc47191cb8": "安全库存",
	"i18nn_184333c81babf2f1": "类型",
	"i18nn_abf9f4b8c93d641e": "订单号",
	"i18nn_4531476fa35570f0": "运单号",
	"i18nn_d7315fb8114eb446": "费用",
	"i18nn_34bf5c39dd2578eb": "修改弹窗",
	"i18nn_c0246c55b9cac963": "添加",
	"i18nn_1b4525c800280581": "正在提交",
	"i18nn_039b1175cdb03703": "支持关键字模糊搜索",
	"i18nn_66880274fe53fdb2": "建议上传尺寸不小于",
	"i18nn_38bae5210404815f": "的图",
	"i18nn_2bbd3825f5d84377": "等格式",
	"i18nn_c9494357a4632b3f": "大小建议不超过",
	"i18nn_c9b828ca934ba6c6": "只能是数字字母下划线",
	"i18nn_d5b3460b4b868e7b": "不超过",
	"i18nn_0c9acfecfc4ec2eb": "字符",
	"i18nn_ad8c1e33ebc7b51c": "建议不超过",
	"i18nn_b6bf0a07fe26f74f": "单位",
	"i18nn_2cba96917484569a": "长度",
	"i18nn_9e24e0e4745f4948": "宽度",
	"i18nn_c5fa0857bc0df2d6": "高度",
	"i18nn_adbec0a43bad1521": "查看图片",
	"i18nn_0200bd47bb4bb83d": "今天",
	"i18nn_84b73bfc6dada445": "昨天",
	"i18nn_e3f3138cf5ba3f6d": "一周前",
	"i18nn_c09a4ec2fe473b0a": "修改",
	"i18nn_306b9a84a664e731": "图片放大",
	"i18nn_16853bda54120bf1": "全部",
	"i18nn_7e14d2cd3996dcd2": "已提交",
	"i18nn_885c3cdb72cb2e0b": "已签收",
	"i18nn_c24587738eb25651": "跳转页面",
	"i18nn_22105cf6781b0f09": "打开新增编辑",
	"i18nn_794cc17f5d24dbe0": "弹窗",
	"i18nn_dc2f299dc3aeffb3": "重置",
	"i18nn_649d9798f7590713": "浅拷贝",
	"i18nn_e386c58d13efe5af": "对象属性的合并",
	"i18nn_5cbf7e07f157572f": "确定要确认通过该笔账单",
	"i18nn_fa91b99043676081": "打开编辑",
	"i18nn_5d43cbeb679e9830": "单条",
	"i18nn_1df24953a2a13e57": "多条",
	"i18nn_7b80e66b535a3732": "请先选择要操作的数据",
	"i18nn_ed492a621bbf4ecf": "点击状态为非出账弹出窗",
	"i18nn_e741d17b1fd891c2": "仅",
	"i18nn_ec54decc8e274bbc": "已出账",
	"i18nn_d6f109b7bee2f33f": "状态的数据进行",
	"i18nn_93f5ca01b006206c": "操作",
	"i18nn_002e3156942b6047": "请核实",
	"i18nn_d1ccda856600ae3a": "重置输入框",
	"i18nn_c91d861ccbaddcd5": "查询数据",
	"i18nn_138ce5945e0e273a": "分页",
	"i18nn_e8ec92802315a287": "确定要",
	"i18nn_e969f83400f78bc5": "通过该笔账单",
	"i18nn_db210f50b5fde38d": "更新特定数据",
	"i18nn_aefbfa41c779d472": "特定数据",
	"i18nn_c524cff2488f2ebd": "成功",
	"i18nn_7a4aff235c9a01dd": "失败",
	"i18nn_55a803f40c4b3790": "选择数据后回调",
	"i18nn_dd8b4f0bf9ce1e74": "查询数据字典",
	"i18nn_9742b74b5a4c77d4": "查询数据字典接口",
	"i18nn_5b14580d813b4a45": "信息撮合费",
	"i18nn_1b613e926a1e65dd": "信息撮合费明细",
	"i18nn_64fed2851f79d875": "客户编码",
	"i18nn_7b27c6b2a3f93412": "包装编号",
	"i18nn_e0c16ac094cc2bce": "产品代码",
	"i18nn_877c5a0e44a0eb07": "产品图片",
	"i18nn_9eeccc6d6f1ff32f": "产品名称",
	"i18nn_8e01866868930d09": "重量单位",
	"i18nn_ad4b0047e16d89ae": "单件重",
	"i18nn_57936a4767ded7f5": "体积单位",
	"i18nn_5cedfd4fa0b91b4f": "入库件数",
	"i18nn_ebe6f6c25130b09a": "仓租收费类型",
	"i18nn_c8239d27c661e123": "仓租单件",
	"i18nn_b23004db5349dfd2": "产品中文名",
	"i18nn_05141ac0139eaf01": "产品英文名称",
	"i18nn_791e36963e37617a": "自定义编码",
	"i18nn_198738759379a3c0": "海关编码",
	"i18nn_73d237ea940222ce": "申报中文名称",
	"i18nn_9894786aad05f849": "申报英文名称",
	"i18nn_fe28507421c4c3b3": "申报价值",
	"i18nn_11e41b2ec3d872ed": "单件重量",
	"i18nn_73bbf51487ec69e9": "共",
	"73bbf51487ec69e9": "共",
	"i18nn_0a8c7b53565de4f4": "个",
	"i18nn_b7a10fe4e9528362": "货柜号",
	"i18nn_ed47d0c198c61d01": "出发港口",
	"i18nn_d3d95bf498ae6cdb": "到达港口",
	"i18nn_04e92fdefcddec84": "出发日期",
	"i18nn_b5c03c6f1b9b1e28": "到达时间",
	"i18nn_b799f516e3940e3f": "装箱单类型",
	"i18nn_10229dd4c7509cdb": "如果不填的话系统会自动生成",
	"i18nn_4ff968abd6e2fc12": "编辑时此字段不可编辑",
	"i18nn_b954f8829728e9d2": "正在加载",
	"i18nn_31f887c1ada79a99": "产品编码",
	"i18nn_a332a38ed3832d9d": "入库编号",
	"i18nn_209fdd8f47e20fbf": "客户编号",
	"i18nn_18d7892498938435": "客户",
	"i18nn_ef6eeb5bf7c4a821": "选择入库数据",
	"i18nn_4c0e3495d9042ebf": "查看明细",
	"i18nn_e49d33421617c0da": "下载模板",
	"i18nn_bb8f8c1e0b6e7000": "打开入库选择框",
	"i18nn_f4a19c201427a0b1": "装箱编号",
	"i18nn_e513671dd2474a29": "到达日期",
	"i18nn_643e08ba53f72f39": "装箱类型",
	"i18nn_5e7740c52e22eefc": "编号",
	"i18nn_367f1e0c18ec661a": "装箱数量",
	"i18nn_b9d4d3f6183bf3c1": "装箱件数",
	"i18nn_1108b3e1ecd1fdce": "货代客人",
	"i18nn_e6bdd31025bb4f9f": "明细",
	"i18nn_9e22ab6de55df607": "打开附件上传",
	"i18nn_b713a5cc2e38eb89": "附件上传成功回调",
	"i18nn_4b038d5cbc643471": "发货",
	"i18nn_634b4be09ed29f37": "确定发货吗",
	"i18nn_cc62f4bf31d661e3": "提示信息",
	"i18nn_34f5f82ff9c257b5": "打开选择数据",
	"i18nn_fa4b8c97858f967d": "计算",
	"i18nn_a3b3a614f0e2b584": "打开导入弹窗",
	"i18nn_4dadf13ebce2167d": "未知的文件格式",
	"i18nn_c8e99e36cefd743a": "文件读取失败",
	"i18nn_770533a085119cc0": "装箱单明细",
	"i18nn_850ab41707d9ec89": "复核状态",
	"i18nn_ea71165e067be24e": "总重量",
	"i18nn_5ada761d1917f8bc": "总体积",
	"i18nn_a386a6933d52dad8": "产品中文名称",
	"i18nn_fe66b0deddea04d6": "仓租单件价格",
	"i18nn_3df520fd9dc4bdeb": "在库件数",
	"i18nn_fdd8426d7b8869c5": "未收货",
	"i18nn_619d7e563f879811": "收货中",
	"i18nn_1dfb3a065d3ffe39": "收货完成",
	"i18nn_4280e1909724fc0d": "我的",
	"i18nn_6a54fe4c349d623e": "装箱",
	"i18nn_f410ad11461131d1": "账期",
	"i18nn_68efabd029c860a9": "选择日期",
	"i18nn_e35175fcc579b438": "账单编号",
	"i18nn_b6c654901e9a6632": "请输入账单编号",
	"i18nn_f36cf87cddf3a966": "入库产品信息",
	"i18nn_b071e67922639ea1": "实际上架数",
	"i18nn_bb3ea0fc187db71d": "当前上架数",
	"i18nn_bb0b485a98f17aea": "上架总体积",
	"i18nn_c8927f1a24eb4f03": "当前总体积",
	"i18nn_4157ea5a8c62064b": "上架总重量",
	"i18nn_98d11164e0f1d3ba": "当前总重量",
	"i18nn_3a6eb04ef8f3327b": "库位",
	"i18nn_9fb9a5e4507379f6": "选择库位",
	"i18nn_b83597729ab29694": "选择客户",
	"i18nn_29991afa9781d554": "客户名称",
	"i18nn_6cf7440cec94c02d": "海关及申报信息",
	"i18nn_5f86feae97e3658f": "出口申报给海关的海关编码",
	"i18nn_045d8c4b6a56fc13": "有利于报关",
	"i18nn_cb16ed92e2156bec": "请尽量维护准确",
	"i18nn_3f0c9d48047a36b8": "申报给海关的商品价值",
	"i18nn_ebd4d78a10f62509": "出口申报给海关的中文商品名称",
	"i18nn_7486bac6ef7f5775": "出口申报给海关的英文商品名称",
	"i18nn_d48bb83a82237f60": "规格信息",
	"i18nn_f340f0ba85ea230d": "体积",
	"i18nn_fa70be657469e09a": "仓租信息",
	"i18nn_d552aa9f1c14363f": "保存编辑",
	"i18nn_0ddb67d8d6d01ad4": "保存新增",
	"i18nn_f5388c326e8504f1": "顶部提示导航",
	"i18nn_129bddcc1b0afba9": "仓配中心",
	"i18nn_01d515661b01a0b3": "互易首页",
	"i18nn_b4bced00356095dc": "退出",
	"i18nn_738246ccd0ca0224": "下拉菜单",
	"i18nn_2e35c13f129cf855": "黄金糕",
	"i18nn_d26ae04e63fe0940": "狮子头",
	"i18nn_fec31d0353b1a1db": "螺蛳粉",
	"i18nn_cff7518d379f060f": "双皮奶",
	"i18nn_0be9f3f483e1d46d": "蚵仔煎",
	"i18nn_c90f53f2b5dda904": "异常装箱单",
	"i18nn_2f3c98d5d7102a27": "开发中",
	"i18nn_12314fc8503ef537": "电商店铺配置",
	"i18nn_5646bd8c216616d8": "电商店铺",
	"i18nn_4de574b0302f8cf5": "异常件",
	"i18nn_095d28bd0cab80f5": "我的退货",
	"i18nn_8460cd05d27dbc2a": "我的预报",
	"i18nn_7260d7dba0268bbb": "我的转运批次",
	"i18nn_00b8e68f8e05f85b": "我的转运包裹",
	"i18nn_dbb873b021788040": "我的运费",
	"i18nn_11f52e2d7184cee5": "我的账单",
	"i18nn_ac662af32923cd88": "用户使用手册",
	"i18nn_ce764b74be1b9a90": "工单",
	"i18nn_2f7a4906f66bffd1": "我的工单",
	"i18nn_ab91102fbd3b1073": "访问组件实例",
	"i18nn_78d6576e0ca0ee79": "用户未登录",
	"i18nn_d0e490d0c3ca7eb0": "请联系管理员",
	"i18nn_6b57a5faf337b87b": "未知",
	"i18nn_40729ab35d7d0124": "厂家未认证或审核未通过",
	"i18nn_a01c17ca2e680a0a": "请从",
	"i18nn_30746594c861e3f9": "合伙人中心",
	"i18nn_cf03031ed0359ac6": "我的推荐",
	"i18nn_978549a11051cb4d": "模块进入",
	"i18nn_38f0e339b7be1133": "未知商户",
	"i18nn_5ae549ae0b885243": "立即去登录",
	"i18nn_e32f34fdec0dd065": "跳转",
	"i18nn_74708f3a8699305a": "登出",
	"i18nn_096e05703cad0846": "左边侧导航",
	"i18nn_52f541b927acacc6": "顶部下拉",
	"i18nn_e8564657bbe9ca53": "创建",
	"i18nn_e0c2e9f28f36e148": "装箱单",
	"i18nn_581bc71c027386cb": "快递单号",
	"i18nn_597b3ae5c6de34d0": "要展开的行",
	"i18nn_9ac8c5faf85033de": "数值的元素是",
	"i18nn_ed085b86f07e3e99": "值",
	"i18nn_c301de1e444a3e90": "待发货",
	"i18nn_fcf5923a042705f3": "待召回",
	"i18nn_190dca403b6f540a": "已取消",
	"i18nn_3cce40fc2b7d333c": "表格点击行展开",
	"i18nn_7414f31517552941": "里",
	"i18nn_cbc1a1fd9e6c9d70": "我们已经设置",
	"i18nn_663d98f9fed21ec3": "值设置为每行数据",
	"i18nn_3a671c04d1dc0d96": "选择行",
	"i18nn_c0a0adbd5c04ac55": "点击选中",
	"i18nn_6ebe6ec4eb502f95": "多选",
	"i18nn_2109f5a7ca56dc5b": "发货成功",
	"i18nn_dca4d33bdc72009f": "此为",
	"i18nn_d178c1b96a5987fe": "我的费用明细",
	"i18nn_a4eca6ff2588d791": "费用名称",
	"i18nn_3eb79cfe71bbae0b": "入库编码",
	"i18nn_15f3bfa87d2f503e": "付款",
	"i18nn_45d95c470c095145": "核销",
	"i18nn_62d1f13b854eb1f7": "账单管理",
	"i18nn_4c9736c24ed29b16": "用户账号",
	"i18nn_046efc85af065a99": "用户姓名",
	"i18nn_71e0c6669e3249b9": "所属厂家",
	"i18nn_c5832c81556ae8d3": "是否有效",
	"i18nn_e76d885ae1c74d4f": "否",
	"i18nn_360ff6d0a099ab59": "子账户账号",
	"i18nn_e82c9fff83ec2d91": "手机号",
	"i18nn_44beaa6c8136adc9": "子账户",
	"i18nn_da41bc0c21860fc2": "主账户子账户关系表",
	"i18nn_cad67c74ecedf941": "子账户名称",
	"i18nn_b9338a40e13a4b1b": "子账户状态",
	"i18nn_61432878e10a2483": "有效",
	"i18nn_8ef98f00486f7801": "无效",
	"i18nn_f5a9e0830d476523": "必须为手机号",
	"i18nn_4b7dc78116a958d3": "请输入子账户",
	"i18nn_4b168d52df99a996": "请输入主账户子账户关系表",
	"i18nn_a47da648bd79c2fc": "请输入子账户名称",
	"i18nn_3821259f4f037ccf": "请输入子账户状态",
	"i18nn_1d0edc05a3e3826a": "账单分页",
	"i18nn_f52a39ff3d95336f": "我的发货单",
	"i18nn_0f0d6fbc07d109be": "导入发货单",
	"i18nn_a2df50399ce1bf10": "发货编号",
	"i18nn_e44e9a291d80c593": "收件人名称",
	"i18nn_5acbec83efb27445": "仓库编号",
	"i18nn_b29bf013aaec9ce6": "收件人号码",
	"i18nn_c4913ab43009b365": "邮编",
	"i18nn_b166e4e8fe9513fa": "国家",
	"i18nn_f9514183570a10fe": "省",
	"i18nn_7cab79716236ebb0": "州",
	"i18nn_8db45b615b72b7a8": "地址一",
	"i18nn_5165f3ee9377af83": "出车方式",
	"i18nn_99b88de52e382ca4": "预计到车日期",
	"i18nn_74ed28f373ea14da": "商品编号",
	"i18nn_2ec0f364e8bbaa4c": "发货件数",
	"i18nn_0f859b9b45732564": "到达仓库",
	"i18nn_21140770c20d6f9b": "发货指令",
	"i18nn_79784145b384f8da": "下载提货单",
	"i18nn_a4bf1488f866249b": "置空不需要传的数据",
	"i18nn_73dd0d43f580bc68": "导入成功",
	"i18nn_6b3b02fd683263ca": "明晰",
	"i18nn_32f88191102e9648": "发货单明细",
	"i18nn_89413403ccaade4a": "到达国家",
	"i18nn_0607c7a5960734f1": "到达省",
	"i18nn_ba8ce6a27a2ce7ef": "到达城市",
	"i18nn_aefe1a569c9a9d4c": "到达地址一",
	"i18nn_daa23bc02afc02a7": "到达邮编",
	"i18nn_f566f50ef680920c": "工单类型",
	"i18nn_3e358d140504ab46": "工单内容",
	"i18nn_8200a25ab641ba97": "附件信息",
	"i18nn_8bafd5202547e50d": "日志",
	"i18nn_1f4c28db7c80a5f5": "富文本",
	"i18nn_a38569d545d8e238": "创建工单",
	"i18nn_89221ad15d2ec113": "文件名称",
	"i18nn_bf0a1c4ff90aadcb": "已分配",
	"i18nn_2f09651c43c2a375": "已处理",
	"i18nn_8039faf6d63be278": "退回",
	"i18nn_89d50ef8fe430e48": "选择或搜索商品类目",
	"i18nn_b9aa51e503858bf1": "建议上传尺寸不小于的图",
	"i18nn_a8f12f1452cf72c8": "商品类目变化",
	"i18nn_462f2e7b92066e43": "该类目商品基本属性",
	"i18nn_b93f7c8c4e2346f8": "保存成功",
	"i18nn_a28112c8cca3514f": "历史总库存",
	"i18nn_c5820dce4b05019f": "库存总价",
	"i18nn_6b3c6618251b6db5": "生成一维码失败",
	"i18nn_315a0042286142d5": "仅新增",
	"i18nn_25dd68f4fe19dc05": "不读取原记录",
	"i18nn_dc8d3ceec6b8c3cd": "全部上传成功回调",
	"i18nn_42b5b20194a2f1e3": "保存到后台有误时",
	"i18nn_31dba98ac03ecd2a": "重新执行",
	"i18nn_eb74668dabb99d8d": "文件上传及回调",
	"i18nn_ff6fd823ffab7e6b": "导出",
	"i18nn_1d5fb99b98e26365": "条形码",
	"i18nn_1903bd7b05bf1edb": "库存统计",
	"i18nn_d42355e6aef72227": "时间范围",
	"i18nn_9eaa906ebc446062": "入库时间",
	"i18nn_4d3765c415bb4462": "库龄",
	"i18nn_de53ab10322cd38a": "库存",
	"i18nn_61cac7cb94d81cfe": "统计时间",
	"i18nn_0e240c5802d17fea": "单件体积",
	"i18nn_cffd7c9783c11047": "请选择日期",
	"i18nn_f353b1ebbdc25be4": "模糊搜索",
	"i18nn_3ee4c9b76289e93a": "请输入关键字",
	"i18nn_54153ccc0c756e9f": "帮助文档",
	"i18nn_9fcdcb3a067903d8": "中文",
	"i18nn_bec36a3c4521e2a6": "下载帮助文档",
	"i18nn_337e08570aa0d4bb": "登录地址",
	"i18nn_7cfbd9eb6d861781": "维护自己的",
	"i18nn_245883c80f181c4a": "下载",
	"i18nn_e8a005a836fe3f74": "导入模板",
	"i18nn_de604a12d7c44f86": "并按照模板填写",
	"i18nn_cb117cbadbd7bca6": "的基本信息",
	"i18nn_84ac965d0c7ec965": "选择编辑的",
	"i18nn_3e0e3e20c87c032f": "模板文件",
	"i18nn_2c480b655ec65c73": "导入数据",
	"i18nn_c1a01d78ceb19a0e": "提交保存",
	"i18nn_127fc656c2c60c96": "提交入库装箱信息",
	"i18nn_4084248573b3c16b": "下载装箱单导入模板",
	"i18nn_f0eccb032002dce7": "并按照模板填写需要入库的基本信息",
	"i18nn_0fda388bb7e4efa6": "选择编辑的模板文件",
	"i18nn_3fa0edc86d3975ca": "申请提交后",
	"i18nn_ab0233b8eb35c316": "在仓库收货后",
	"i18nn_8a2a9726b2ba15c6": "会在",
	"i18nn_026bafd47c1c7b53": "菜单中看到库存数量",
	"i18nn_f925d9b48d8c1d45": "说明",
	"i18nn_e88cfdf1e83abbf8": "发货功能主要对应的是客户的出库申请",
	"i18nn_e0993db310d9ae88": "其中",
	"i18nn_36bcd7118257fbcc": "对应的是卡派转运业务",
	"i18nn_789610dd6a89b2fb": "仓库对",
	"i18nn_684a2afb069b6016": "一件代发",
	"i18nn_460eb62a18c2a2d8": "主要是仓库发货到消费客户",
	"i18nn_99426a7f0ce15317": "我的发货单指令",
	"i18nn_50ab009090a8fd27": "用户填入发货编号",
	"i18nn_151918b1d3959016": "一般是申请",
	"i18nn_22f9eed64eb44261": "入库的",
	"i18nn_1887667de3c3e159": "如果没有",
	"i18nn_037ffd217472355a": "按",
	"i18nn_61ea88052f6a708a": "为当天申请的次数",
	"i18nn_2c1c9a7a65719778": "出货的仓库",
	"i18nn_84a37e1d96fceb5b": "下拉选择",
	"i18nn_2697e56984504ef2": "有两种出车方式",
	"i18nn_cff3375bd6822fd8": "卡派是仓库自己派车送",
	"i18nn_62c9dfea123aa953": "自提为客户自己约车来取",
	"i18nn_0d1e1755421f2f56": "当为自提出车时",
	"i18nn_51627c0df8478273": "需要录入来提取单的大概时间",
	"i18nn_2335093ed77821fd": "填写好模板后",
	"i18nn_15fa80fc0e736518": "按顺序执行就行",
	"i18nn_03151bd9eb151c75": "步完成后",
	"i18nn_13647b4031487392": "如下图",
	"i18nn_1b6eeb3796def27e": "直接点击保存按钮",
	"i18nn_1949974dc095a263": "提交发货指令",
	"i18nn_bb346b646af55bd8": "点击",
	"i18nn_8730be85c2f45b7c": "导入代发单",
	"i18nn_e3acf0048c7a6c5e": "弹出导入界面",
	"i18nn_ab1510a145ebdd7b": "下载发货模板",
	"i18nn_fb021d2110d3bbba": "然后编辑发货模板",
	"i18nn_12b58cab1beafdb7": "选择编辑的发货模板",
	"i18nn_5d24be764d968b53": "读取数据并且预览",
	"i18nn_79d3e9d13fe8e0a0": "提交代发数据",
	"i18nn_1d6f1f17cb9ae0ff": "导入预览",
	"i18nn_1a1a5bd37aef792a": "提交代发指令",
	"i18nn_0b31c81f074b836e": "提交后不可删除",
	"i18nn_ef495fdad3f7156f": "是单条提交",
	"i18nn_4dc2da02fb9eefbe": "是批量提交",
	"i18nn_71e54c38a374d9dc": "只有库存充足",
	"i18nn_0c58aa47afa3ad82": "才能提交成功",
	"i18nn_f77f473d27f48bdc": "代理用户",
	"i18nn_556c0672dcecba13": "设置",
	"i18nn_b00576fed9b3a1f2": "账号",
	"i18nn_6ede9303c2d93ede": "解绑",
	"i18nn_2c38440ac06aae42": "绑定",
	"i18nn_2408b14cc2ddba03": "确定解除绑定吗",
	"i18nn_ef71790e2ba5798c": "待提取",
	"i18nn_61204dd04df0ad13": "已提取",
	"i18nn_851c121d787993a2": "库存统计明细",
	"i18nn_350662278f221eee": "库存统计汇总",
	"i18nn_ec153a16f143e276": "代理",
	"i18nn_fd012984453fc8bc": "秒后自动跳转",
	"i18nn_2ecc513d94e4ca25": "线上",
	"i18nn_1e24cf708a14ce81": "测试",
	"i18nn_d0923f3abe67b5cd": "测试新",
	"i18nn_88e500634af9eff9": "取消发货",
	"i18nn_4368252da5c6ce3f": "确定取消发货吗",
	"i18nn_d0a75d1ee7f93bb7": "正在打开授权页面",
	"i18nn_cc1f108900897a83": "请稍候",
	"i18nn_907aa9302190b8b6": "打开亚马逊授权",
	"i18nn_67c2289162532917": "获取授权信息",
	"i18nn_294ea7a0c08d79ad": "新",
	"i18nn_6b7b1b98db01410f": "正在授权",
	"i18nn_9255f35537b4fa0f": "请勿刷新此页面",
	"i18nn_c5f937578ab0c290": "授权成功",
	"i18nn_b2fcb58dd1716049": "授权成功后自动",
	"i18nn_4b0b80a8641a28d8": "跳转到",
	"i18nn_0698cce1a67a1ff6": "授权列表",
	"i18nn_5ad16932d8972191": "页面",
	"i18nn_d9e17eb65f5720f7": "查看授权列表",
	"i18nn_10e868dfd9e69b86": "无授权信息",
	"i18nn_fc7197a70838eeeb": "授权",
	"i18nn_7560acdefdcb54e2": "店铺",
	"i18nn_b7cb4598d6ff9057": "正在跳转",
	"i18nn_6b2f2af88c6e0080": "授权失败",
	"i18nn_5a1dc5ed6de34b73": "请关闭重试",
	"i18nn_37181c74559db182": "订单",
	"i18nn_58169349e34744e8": "选择日期时间",
	"i18nn_b17342e22700eb32": "选择账号",
	"i18nn_43ef2ec33ab0b489": "拉取订单",
	"i18nn_3f514bfb88a0f35b": "刷新商品信息",
	"i18nn_a1a651a01a9c6815": "刷新地址",
	"i18nn_fadd975428c8c511": "刷新购买人信息",
	"i18nn_377fa66b0b28ce31": "查看",
	"i18nn_26fb9a484faddf26": "拉取订单成功",
	"i18nn_6ce6dc58d2111cc9": "拉取订单失败",
	"i18nn_a33831a969cda728": "打单选择",
	"i18nn_b77563b9e5567c4a": "更新商品信息",
	"i18nn_fc089dc894530f12": "请先选择数据",
	"i18nn_10556bca2482ac06": "更新地址信息",
	"i18nn_a7926683203307f0": "更新买家信息",
	"i18nn_4326c21ea533d819": "更新别名",
	"i18nn_efc15adf80699301": "别名",
	"i18nn_2edfb7fc34a339ef": "打开授权弹窗",
	"i18nn_fe735bc696bccb15": "个文件",
	"i18nn_e5c5067678f4eba0": "类型匹配",
	"i18nn_04079755a623ba4d": "类型不匹配",
	"i18nn_f27a40383c5678a4": "多个文件",
	"i18nn_fe8bed302a3c7b11": "选会触发多次",
	"i18nn_d25a9b29d0bb390b": "重新登录",
	"i18nn_7c9f46a00e89d0e6": "并赋值",
	"i18nn_0e96bf9eabc042e1": "二次赋值",
	"i18nn_69972ac8a034ea10": "第一次赋值失败的时候",
	"i18nn_e93cde152c0070f7": "亚马逊账号帮助文档",
	"i18nn_37a8f56cb013d522": "产品授权",
	"i18nn_132d087e57aebb7d": "第一步",
	"i18nn_25e03120b83ee4ed": "完成",
	"i18nn_ee1d5c7dbd6cb012": "点击授权列表",
	"i18nn_8438576b80e032e5": "在页面中有授权按钮",
	"i18nn_cacc0e30a83291ca": "如",
	"i18nn_ac2d3a21f0abd506": "图",
	"i18nn_6e7dc8d081d4682f": "点击授权按钮",
	"i18nn_18df313d78befd4d": "然后在浏览器中登录",
	"i18nn_119df87dd841c733": "到达",
	"i18nn_5f1f56b8944041d9": "授权明细页面",
	"i18nn_6db4a3f3bcbb5b0b": "授权成功后获取授权",
	"i18nn_8cd90c18e5865c62": "授权中",
	"i18nn_95510f1b6c0b6217": "获取到",
	"i18nn_cf9ad4949e1328e7": "选择拉取的时间",
	"i18nn_ceb22e2ef5790be0": "时间限制未最多前",
	"i18nn_509c52c5117ea79c": "天开始",
	"i18nn_226f4248cd8f59c5": "选择授权账号",
	"i18nn_be84aaa170fd32bb": "然后使用刷新按钮",
	"i18nn_3f6f3197d37909e4": "分别获取",
	"i18nn_f9390da3c230d098": "配送地址等",
	"i18nn_86cbba37d7f69050": "获取订单后",
	"i18nn_34f6fc628893f509": "功能",
	"i18nn_c092102e06374272": "完成订单的自配送",
	"i18nn_324c42c209968b61": "自配送",
	"i18nn_2071c6e489a10eab": "拉取到订单",
	"i18nn_4bc64d9bd19c5601": "并且获取了配送地址后",
	"i18nn_d74f828bb2ce544e": "可以在",
	"i18nn_1594d840693215f5": "菜单中提交发货信息",
	"i18nn_d465868412828ea9": "由我们仓库完成订单配送",
	"i18nn_a40452d0897a05f2": "订单提取后",
	"i18nn_ed2f1bf0e1c29fc8": "系统会自动更新售卖库存",
	"i18nn_05dd214cd4839ae7": "保证商品信息准确",
	"i18nn_6235565b185f0725": "工单编号",
	"i18nn_1a88bd0f0d22ca01": "撤销发货",
	"i18nn_e7b4fae7cec06141": "收货完成时间",
	"i18nn_977ed6286b514ff6": "柜型",
	"i18nn_b5d15db0d6323e28": "只有全部为",
	"i18nn_1e2279a00c4336a4": "已发货",
	"i18nn_357a8f4e971d3c85": "数据才可操作",
	"i18nn_b1813b05de28548d": "总托数",
	"i18nn_e7b838a996147e21": "总箱数",
	"i18nn_85a10d3e5ea0e103": "每箱件数",
	"i18nn_bad53577db0da2d3": "总件数",
	"i18nn_ae802858dbd29efa": "外箱尺寸",
	"i18nn_89d99a1a68608f3b": "整箱重量",
	"i18nn_ebd257df8f7efc53": "面单导入",
	"i18nn_bd6d1ded173dc477": "上传自供面单",
	"i18nn_a363e5f23b1040fe": "校验",
	"i18nn_e712a380cef4e31e": "面单单号和文件名要相同才可以匹配",
	"i18nn_6c3dd9349932c839": "面单文件",
	"i18nn_5fefbf45c9ca6464": "上传并校验",
	"i18nn_ac71ea3f789a778c": "校验面单",
	"i18nn_582446272516f554": "匹配状态",
	"i18nn_cf0c41b879762cdf": "面单单号",
	"i18nn_70e058bc23d52c1e": "上一步",
	"i18nn_aaf7f7f3df61a6a5": "重新上传",
	"i18nn_a93e095e532cd424": "保存匹配成功的面单",
	"i18nn_f9104346c336651d": "面单保存成功",
	"i18nn_e5664b79012a5cc1": "您可以去",
	"i18nn_df43e4fc4ab96cfb": "模块列表查看",
	"i18nn_826ff6f5d853fee5": "面单",
	"i18nn_658d7f0cc43ab866": "去一件代发",
	"i18nn_b172f8fd2a3c5ce4": "我知道了",
	"i18nn_ddb207cbff45b38b": "上传成功回调",
	"i18nn_12d6e24b3a3cd441": "校验到后台有误时",
	"i18nn_4ce9979bfb6576d9": "保存",
	"i18nn_ae61193590bd513d": "单号",
	"i18nn_ad810b5b659860fe": "文件地址",
	"i18nn_7178a20888e962fc": "没有匹配的面单",
	"i18nn_b0c50b6ff9674173": "快递公司",
	"i18nn_1324a1d91ae3de53": "面单地址",
	"i18nn_daf169d1f7dceda0": "工单号",
	"i18nn_43a3586339251494": "日期",
	"i18nn_52a80831dd9e024b": "快递费用",
	"i18nn_65b7d1be808de93a": "收件人名",
	"i18nn_c3755bab459a2cbc": "公司",
	"i18nn_30e252ba35657802": "打开导入",
	"i18nn_e5462a1be765de05": "工单详情",
	"i18nn_14b5f4f572736efe": "工单日期",
	"i18nn_9b73b93c1607cd97": "提交工单",
	"i18nn_299f1f6fef7c640e": "查看一件代发",
	"i18nn_915541755261249f": "草稿",
	"i18nn_50f017673319c5db": "打开",
	"i18nn_c51542d282edda5b": "删除数据",
	"i18nn_3930df65e31d907b": "一件代发导入",
	"i18nn_a0f2924544ff3ad8": "操作仓库",
	"i18nn_58041bac1fe7346e": "是否可编辑",
	"i18nn_548f4ebaed747bd8": "不允许编辑",
	"i18nn_7375026510dd73e9": "入库装箱单",
	"i18nn_9f11af7bd0eda795": "发货单导入",
	"i18nn_a3b1e5efc70b46eb": "库位查询",
	"i18nn_f2727d808fbeb34d": "查看库位明细",
	"i18nn_036240f3e205cce2": "库位明细",
	"i18nn_785ee8efb6d09b2a": "打单明细",
	"i18nn_36edafcc55cf50f6": "面单查询",
	"i18nn_4ca88dd2e7071844": "撤销打单",
	"i18nn_06dd7cb65641390b": "出单状态",
	"i18nn_90429ac39f4f4634": "打单进度",
	"i18nn_48ebfc4319cbac59": "打单单号",
	"i18nn_1f90c9eb506ddf15": "取消运单",
	"i18nn_3c2605e54bb69e38": "打单费率",
	"i18nn_3d6502c491aca17a": "提交打单",
	"i18nn_d4d1e037938ae65e": "提交打单吗",
	"i18nn_1cb9e763fc0324fa": "提交打单成功",
	"i18nn_f0cb028847831431": "面单查询吗",
	"i18nn_d0bb84f55c074e32": "撤销打单吗",
	"i18nn_327144b46bd2854b": "查询面单",
	"i18nn_e6d7aa8c4f2fc0c7": "正在查询",
	"i18nn_32aff432f169204e": "查询面单信息",
	"i18nn_34e1e57771d4bd81": "已刷新",
	"i18nn_ab719d5d91ae7b5b": "查询面单失败",
	"i18nn_0da14d270576fc82": "查询面单接口",
	"i18nn_0b3917ac0797b5b3": "默认可选数据",
	"i18nn_762ffdac60685e7e": "一件代发明细",
	"i18nn_d99d790ec4383bfb": "操作时间",
	"i18nn_e02e5da233bd816b": "打单",
	"i18nn_db48f5ae63408e0f": "这是一段内容",
	"i18nn_7ce351f5edbbf8c0": "激活",
	"i18nn_f69f323dc7e9ec22": "洛杉矶始发",
	"i18nn_65deb1358842a262": "打单费率查询",
	"i18nn_77cf8937f28bff9b": "获取选中数据",
	"i18nn_6cf7f1b329fdc67f": "查询打单费率信息",
	"i18nn_ea8cd49587f7e54a": "查询查询打单费率信息",
	"i18nn_0bd52db433d771f1": "查询打单费率信息失败",
	"i18nn_0cdf81c9c7f64805": "查询打单费率信息接口",
	"7b1c2b1adc920d9c": "序号",
	"15b3627faddccb1d": "备注",
	"93f5ca01b006206c": "操作",
	"1e7246dd6ccc5539": "搜索",
	"16853bda54120bf1": "全部",
	"hytxs0000029": "列表数据有误",
	"hytxs0000030": "列表数据，请求失败！",
	"hytxs0000031": "提交有误,请检查提交数据！",
	"hytxs0000032": "查询数据字典失败,请重试",
	"hytxs0000033": "查询数据字典接口失败,请重试！",
	"47df8be257c59dde": "加载中...",
	"i18nn_abdbf97e2d5bdb25": "周日",
	"i18nn_c2acf27a87ce160a": "周一",
	"i18nn_48ecd1e7be525c75": "周二",
	"i18nn_62551a706d64f9c9": "周三",
	"i18nn_7c08f11a440bfb1c": "周四",
	"i18nn_38b82f33d30ed560": "周五",
	"i18nn_54904ecee1cf2823": "周六",
	"i18nn_66e04914310f2490": "巴黎",
	"i18nn_0883bea3583d9e51": "法国",
	"i18nn_731cf5a0b64e40df": "悉尼",
	"i18nn_1da458ff9d7a7995": "澳大利亚",
	"i18nn_ef1714c43c5bd374": "中文版",
	"i18nn_a429ebc180de1af2": "预览或下载互易仓中文版帮助文档",
	"i18nn_81066ec9baa79bac": "网站地图",
	"i18nn_a77d479d6331f8b5": "流程块",
	"i18nn_8f606b4d207d593c": "边框背景",
	"i18nn_8251de0b6f00b704": "边框颜色",
	"i18nn_4a9b3fd7efaf276c": "箭头",
	"i18nn_aed61e6845106632": "计算各角度和对应的",
	"i18nn_63d3cd696db1697d": "坐标",
	"i18nn_da10f81b7bb1c75b": "流程",
	"i18nn_6543f7501b1c0d2b": "环境",
	"i18nn_5292c42dddf02443": "线",
	"i18nn_08a66132125d21df": "轴起点",
	"i18nn_401ed99ee5d3944a": "旋转角度",
	"i18nn_5d9cf09b355c7846": "尖角",
	"i18nn_a4a5c442f196c734": "字",
	"i18nn_a069505e87bedd8e": "李大钊",
	"i18nn_eb08cee115c3a529": "陈独秀",
	"i18nn_0f864f84a5e810d6": "账户信息",
	"i18nn_004a25fe67f297c3": "余额",
	"i18nn_831ab31568a78285": "充值",
	"i18nn_7b7058faea31b99f": "添加充值记录",
	"i18nn_a06e84801d6582be": "充值金额",
	"i18nn_8c49a4bfd3a78dc2": "充值汇款材料",
	"i18nn_c4ac0560611963af": "账户流水",
	"i18nn_80d0adba3c341069": "记录类型",
	"i18nn_46c3f6e0f657e7a3": "费用类型",
	"i18nn_1466b1690d2ae404": "费用时间",
	"i18nn_7d2ffc27a09ff792": "开始日期",
	"i18nn_46f7be1133b3e2c6": "结束日期",
	"i18nn_380e1c07ce5a1f06": "后台确认通过后",
	"i18nn_92016b757a1d82a7": "为已充值数据",
	"i18nn_169a0780408f1cce": "账户",
	"i18nn_cd173f918d077f02": "记录名称",
	"i18nn_5706df6392c2b9ef": "记录金额",
	"i18nn_60f5b585fd34c2bd": "导出排序",
	"i18nn_c688937c592c594f": "最近一周",
	"i18nn_a2db995132295e05": "最近一个月",
	"i18nn_502cb8bd057f1605": "最近三个月",
	"i18nn_030010395a4e1d71": "分页的筛选项数据",
	"i18nn_41d2148209b5edc8": "充值记录",
	"i18nn_fdc34fd7121f9c48": "更新时间",
	"i18nn_7e06b928579ba3cd": "新增编辑",
	"i18nn_5c9f3500bc7fc4c2": "确认入账",
	"i18nn_2bfebf0495b9cdcb": "本页切换列表",
	"i18nn_5b7f75f3443354a2": "提交打单时间",
	"i18nn_50dcfc837d43b873": "精确搜索",
	"i18nn_61fc9e07fff97930": "服务类型",
	"i18nn_ab6994d16b9b4366": "运单费",
	"i18nn_5f6b842a6ff748ab": "出单时间",
	"i18nn_6267f3aedf895209": "编辑",
	"i18nn_16c5afb8e869abda": "附件查看",
	"i18nn_76cd201aeb72dba2": "打托明细",
	"i18nn_62a1adfe6207b3c5": "详情数据",
	"i18nn_862bfc135547f78e": "拣货单名称",
	"i18nn_0bd7d983fe70ea56": "拣货数",
	"i18nn_6d8cbf8c49d6a7e6": "拣货总体积",
	"i18nn_3e1652df125209de": "拣货总重量",
	"i18nn_9a42179fb9922dc1": "产品库位",
	"i18nn_fecdd1b2ab21bef3": "新增时",
	"i18nn_fde1f90f1930c21b": "为空",
	"i18nn_3193004ef6dd6bbf": "编辑时必传",
	"i18nn_442a9ac138407a28": "受益方",
	"i18nn_bb5ac0c49150f3b0": "合伙人类型",
	"i18nn_f7e0da8748d5c821": "分配方式",
	"i18nn_9df79d7120a0a741": "分配值",
	"i18nn_2345771b0cea8487": "生效时间",
	"i18nn_4647ba3fc60f0151": "入库日期",
	"i18nn_2aeed2ab99c446d7": "失效日期",
	"i18nn_10f91e600df6414f": "仅做显示",
	"i18nn_b44a4a27cac7433e": "未出单",
	"i18nn_27372599992ff988": "已出单",
	"i18nn_a0acfaee54f06458": "已完成",
	"i18nn_bcef26d98d690aba": "取消面单",
	"i18nn_d353bf2887ca0603": "服务费",
	"i18nn_3c4a40675f59cd1c": "费用合计",
	"i18nn_5e3158c7817c7d9c": "请先选择出车方式",
	"i18nn_07e0f8f59293e78d": "请先选择客户",
	"i18nn_91e192ea5925de92": "请先选择时间",
	"i18nn_886072e393710563": "打印",
	"i18nn_7b23bac6ffe353b9": "所有选中为已提交状态才可以审核",
	"i18nn_3e48b039f79c39a0": "条数据",
	"i18nn_7495b1a692c585b5": "吗",
	"i18nn_97939133a6d30da8": "取消成功",
	"i18nn_493d12b09fa54b95": "所有选中为待发货状态才可以发货",
	"i18nn_8d8b751c8f8c7dbc": "提交编辑",
	"i18nn_f96b62dfa31cde45": "确定提交",
	"i18nn_7f12d7a3ac88b55e": "修改运单",
	"i18nn_a13684cbb78b8e81": "商家账单列表",
	"i18nn_70678b883f1517f5": "提交数据",
	"i18nn_a7d2e953195a5588": "提交失败",
	"i18nn_3c144f7fa49aeaf8": "查询数据字典失败",
	"i18nn_66261f0ce9d34a49": "未知的文件",
	"i18nn_f880656430c365f3": "文件不能超过",
	"i18nn_9cdac116e11d2942": "请先选择文件",
	"i18nn_3d62ec1999ded0cb": "面单同步",
	"i18nn_b60460790c7f8a4b": "撤销成功",
	"i18nn_da15e060427e30de": "抱歉",
	"i18nn_daef27ebf42d4adb": "选择需要导出的列",
	"i18nn_3a7c1bda93906fe9": "全选",
	"i18nn_dd3438b8ee62f146": "导出所有页",
	"i18nn_055a45ea5fef0d11": "筛选项数据",
	"i18nn_9bf17a1c9af26b04": "不足",
	"i18nn_7fd7036a24def329": "条时",
	"i18nn_0d147cf4d1f9a421": "即导出全部",
	"i18nn_8dd194f4fe9000f0": "超过",
	"i18nn_45605a8587b5efab": "分批导出",
	"i18nn_660fe005150483a1": "最多导出",
	"i18nn_83a93e396379a993": "导出全部",
	"i18nn_be331b9c430a360b": "导出第",
	"i18nn_0d9a272b3124998c": "导出当前页",
	"i18nn_c432271c995f31a5": "确定导出",
	"i18nn_5fc8144f38fd1ebc": "上海",
	"i18nn_9c0ca340eff4fdce": "北京",
	"i18nn_cb2ec494b0a4d52c": "广州",
	"i18nn_b9a4aed64afd0cf9": "深圳",
	"i18nn_0dd4b237e92f6a8f": "起始条数",
	"i18nn_f5b1126b82ff45d7": "请求的条数",
	"i18nn_84adf48acbd6ad24": "列表",
	"i18nn_a1761c0e88af75ff": "数据有误",
	"i18nn_eb763bfab5d5a930": "快递面单明细",
	"i18nn_e716ca411b2142e3": "渠道编码",
	"i18nn_3ad90a648c6bfa99": "服务级别",
	"i18nn_310c8b0f4df16e08": "是否下单",
	"i18nn_82b1c8e36ef45bb8": "是否出账",
	"i18nn_728bf6a36a68e566": "打单进度状态",
	"i18nn_c6b76f2746d7dc6f": "打单流水号",
	"i18nn_e5c3fd867ea4c8e6": "账号编码",
	"i18nn_673767ab6ddb37cd": "运单费描述",
	"i18nn_778d871227234f32": "撤销打单成功",
	"i18nn_854a071f3cbf2761": "取消面单成功",
	"i18nn_526831ff87464f45": "快递面单",
	"i18nn_9422c7c846cae2ad": "线下充值",
	"i18nn_75087cd54a97247c": "运费查询",
	"i18nn_79de6d14b424271b": "导入运单",
	"i18nn_7cdf6a7f9798699b": "添加运单",
	"i18nn_85b1942b3831e5fc": "右边容器",
	"i18nn_6cdece641436d7ab": "状态",
	"i18nn_2b52b35b48065b9b": "至",
	"i18nn_e020fbe62b0c9833": "渠道代码",
	"i18nn_590686fdff40723b": "参考号",
	"i18nn_daa60c502de1462f": "物流单号",
	"i18nn_1f4c2f1c8fcb66b7": "订单日期",
	"i18nn_0a84f4853ab4d1ed": "运单费用",
	"i18nn_037f5845b7a88b16": "费用详情",
	"i18nn_b98aefdd52a12eee": "包裹信息",
	"i18nn_8004917a89ed807b": "重",
	"i18nn_7b16a04efec1728b": "保险金额",
	"i18nn_b7367a5ccf9facf3": "发件人",
	"i18nn_ab3ce045a4241cb0": "收件人",
	"i18nn_21fbd798a6ae3e52": "无签名",
	"i18nn_4851293ecdd539ed": "收件人信息",
	"i18nn_7b8ed340400a6e2b": "名",
	"i18nn_549a44bae725f4a2": "姓",
	"i18nn_ab653780491c0d4b": "公司名",
	"i18nn_8b90c4cfdf339af8": "二字码",
	"i18nn_0bbce74881c893df": "地址类型",
	"i18nn_a9cbcfc68126f75a": "商业地址",
	"i18nn_af200a78a2a44559": "住宅地址",
	"i18nn_6a4dc4a49612041e": "校验地址",
	"i18nn_479ea0356ec72989": "发件人信息",
	"i18nn_79dc0cb30753ec26": "发件人名称",
	"i18nn_594abd886182b662": "产品信息",
	"i18nn_5841c2904805147a": "中文名称",
	"i18nn_af76f74cc6174a6d": "英文名称",
	"i18nn_ab6ea90b9164b20a": "描述",
	"i18nn_903c192091ff3e9a": "海关代码",
	"i18nn_19252a10a7c4755e": "面单尺寸",
	"i18nn_7e80cfa637ec486b": "签名服务",
	"i18nn_f9a9e686569b8901": "计算结果",
	"i18nn_118a120c399e878f": "价格详情",
	"i18nn_1ffc6ecc6d0685b6": "确认收货时",
	"i18nn_107dd8e36bc7c397": "是否现场收货",
	"i18nn_8f3058060ca857e6": "确认收货",
	"i18nn_e137950d1897817e": "是否现场卸货",
	"i18nn_3ff6389dafddca27": "运单导入",
	"i18nn_87912cccd1e9b132": "装箱单打印",
	"i18nn_0c8c5123e2631ceb": "不传或传",
	"i18nn_3ece0cc00f1da8de": "已收货",
	"i18nn_15e0074dc8713a02": "必传",
	"i18nn_608b055c301999e8": "导入成功后回调",
	"i18nn_ae96feb59a4f25ea": "回调",
	"i18nn_4e5d40d707d90d7d": "淡季收费价格",
	"i18nn_d5beb7f89f0e6b61": "淡季折扣",
	"i18nn_b40390257aa6c985": "旺季收费价格",
	"i18nn_30e41d362a584108": "旺季折扣",
	"i18nn_fc72e5de884434f5": "收费方式写死掉",
	"i18nn_820a3a60bc317776": "格式化数据失败",
	"i18nn_f4bb5eaa25b5de81": "运单查询",
	"i18nn_96b95ef206fadadf": "打开费率查询",
	"i18nn_b0e16646a7aa97eb": "您选择了",
	"i18nn_b2330ac1b925cbcd": "请选择单条查询",
	"i18nn_4984348f709a96b5": "添加配置明细",
	"i18nn_9779e0a1682e0564": "删除配置明细",
	"i18nn_b9221c0e13214d9c": "请检查输入的数据",
	"i18nn_ea74b89efa9dbdb8": "查询费率",
	"i18nn_a72f4a9e1170c63b": "已校验",
	"i18nn_012206594fd339ef": "确认收货提交",
	"i18nn_d96afd0c0de3686c": "确定删除吗",
	"i18nn_e766a8b8bb6dbb48": "取消面单吗",
	"i18nn_87d3b81a6d6723d8": "批量复核",
	"i18nn_1ec1ab466a8bbbdc": "渠道代码值",
	"i18nn_ab546359d111c7f4": "导入运单模板",
	"i18nn_aa7237f43c313974": "参照",
	"i18nn_48e17cdf3460fb7f": "最小",
	"i18nn_3775920d44088b96": "件数",
	"i18nn_7e1de493fb204260": "格式化异常",
	"i18nn_d50e79aeea569249": "发货地仓库",
	"i18nn_8bf7c69409f84506": "收货地仓库",
	"i18nn_0f85a73e30bce3c5": "快递渠道",
	"i18nn_83b1b50eb00a9fb6": "第三方单号",
	"i18nn_71c07829df8e6708": "查看打单费率",
	"i18nn_8eb6e92d013f9555": "暂停",
	"i18nn_7a832466d6f61c60": "快递类型",
	"i18nn_13143652e467810d": "确定暂停吗",
	"i18nn_e89c7d698c8a29f3": "查询对应快递打单费率",
	"i18nn_f90b8bd6ccd76c9c": "查询接口",
	"i18nn_199f5bded0b23976": "退货",
	"i18nn_9e1f4b4b91d2dff7": "创建退货单",
	"i18nn_0ae081c3b8c4d4a1": "提交日期",
	"i18nn_dc710cffe6313bb5": "查看详情",
	"i18nn_a5ed1c98a5b6763f": "等待签收",
	"i18nn_c2baf39462aa98d2": "部分签收",
	"i18nn_4d4f1d7366ae2f12": "全部签收",
	"i18nn_868e09fc83c854d1": "退货明细",
	"i18nn_53b13e07568ca635": "商品",
	"i18nn_c6e98c83801b5198": "商品名称",
	"i18nn_158db9252e21d1a6": "关联单号",
	"i18nn_e4342b5989a0c5ac": "入库状态",
	"i18nn_6b1945319eb18d5f": "自提单",
	"i18nn_66f0e38b9285894b": "出发方式",
	"i18nn_c2e3d79e5e3050f0": "创建自提单",
	"i18nn_51177b2d7ce0d883": "自提单明细",
	"i18nn_bdc361ba04506136": "箱数",
	"i18nn_4b2b4820019ef897": "单箱数量",
	"i18nn_b19f7906dab47f9c": "派送方式",
	"i18nn_eb8af1b7e16e2069": "外箱长",
	"i18nn_64e6eb209a8a2cff": "外箱宽",
	"i18nn_2a02dbcab12c9ee8": "外箱高",
	"i18nn_55ed30102ca735c1": "箱毛重",
	"i18nn_b5b5d2437dca1e76": "仓库代码",
	"i18nn_fa6ae0aebfee7efa": "发货条码",
	"i18nn_32df9cb3d1681477": "箱唛",
	"i18nn_5fb84776e29f5b72": "操作备注",
	"i18nn_3374a2514ef7dc5d": "自提单编辑",
	"i18nn_589bc0f7e4769a3e": "库存趋势统计",
	"i18nn_dc75cd940064d714": "引入",
	"i18nn_74743cf1a9c41151": "核心模块",
	"i18nn_28d2fb938d6211b8": "核心模块提供了",
	"i18nn_e630af5f633f55f2": "使用必须要的接口",
	"i18nn_a17b20dd7c448e3c": "引入柱状图图表",
	"i18nn_074a3b8ff2bb7105": "图表后缀都为",
	"i18nn_12b2d886127fcf1d": "引入提示框",
	"i18nn_72d9fd3ed1651acb": "直角坐标系",
	"i18nn_09aff069f9e1394e": "数据集",
	"i18nn_2cf965200948068b": "内置数据转换器组件",
	"i18nn_7fc6196a61028e73": "组件后缀都为",
	"i18nn_fe22d580da31919f": "标签自动布局",
	"i18nn_c34d935dcb747d98": "全局过渡动画等特性",
	"i18nn_bb74af49cc463d6d": "渲染器",
	"i18nn_c815335fb089323d": "注意引入",
	"i18nn_f7f23935032a7fc4": "或者",
	"i18nn_fe3bc9f05bdd89a0": "是必须的一步",
	"i18nn_0afba3e48cca9065": "注册必须的组件",
	"i18nn_2b17c40739835a66": "基于准备好的",
	"i18nn_b9f264085ac2a89b": "实例",
	"i18nn_3eb5a90218d6c6d1": "绘制图表",
	"i18nn_89524efe3f5bdf69": "变化趋势",
	"i18nn_2594059036dfede5": "时间",
	"i18nn_2c5ae1686e1c4aa2": "趋势统计",
	"i18nn_90934a9c6e825ac3": "趋势统计详情",
	"i18nn_9c7e841fa06de079": "出库报表",
	"i18nn_63ee7265156e13ac": "统计方式",
	"i18nn_f551589093f4bbfb": "商品关键字",
	"i18nn_2b2633c44fbf02b8": "出库数量",
	"i18nn_716dcf4d38910c35": "出库时间",
	"i18nn_08ad990830d08ed0": "本月",
	"i18nn_e1a818330d2d1b44": "今年至今",
	"i18nn_9686abed4dde5fcc": "最近六个月",
	"i18nn_f762ff6f05589bdf": "收货",
	"i18nn_e36deb57ab8f614f": "自提出库",
	"i18nn_dad7cb726e8a6ccb": "非必须",
	"i18nn_c232abd55b5719fb": "按月",
	"i18nn_43d7de9d82345073": "按天",
	"i18nn_9144ddc68d05d528": "开始时间",
	"i18nn_27851bd33f35f524": "必须",
	"i18nn_8b5d709462c8e71e": "结束时间",
	"i18nn_4ab929e1484af865": "商品编码",
	"i18nn_a3b6f85b4a9530ff": "仓库编码",
	"i18nn_a2314420eff2c3a6": "统计类型",
	"i18nn_d9415a5983a38e84": "商品模糊",
	"i18nn_6ac98f81bd978520": "请先选择仓库",
	"i18nn_904c04c9f6cc2ce8": "仓租费用明细",
	"i18nn_96e86c7f83dd7561": "收费方式",
	"i18nn_9a02297fb30f5281": "最近七天",
	"i18nn_9bfcf061ccdd5c1c": "账单日期",
	"i18nn_512e5b1abd89d790": "仓租费用汇总",
	"i18nn_375acecdfac5c204": "费用日期",
	"i18nn_b83011b3bfea8797": "拆分",
	"i18nn_bcad6e616b8de5af": "第三方",
	"i18nn_4f26196e75e6eb1a": "第三方订单",
	"i18nn_b30717f74d1d91b3": "第三方订单编号",
	"i18nn_737061cefdb6d0af": "电商平台单号",
	"i18nn_b6b08a7daa1de1ab": "店铺名称",
	"i18nn_d2dd21a61c559695": "第三方平台",
	"i18nn_ab29edcb476d7186": "生成发货单",
	"i18nn_b7f95c7fddc0d602": "市",
	"i18nn_5b954c149f0b1bdf": "第三方平台名称",
	"i18nn_bb74b80c21dcc1a5": "推送日期",
	"i18nn_b49ec02534e4f9b9": "未下单",
	"i18nn_139eee335633c377": "已下单",
	"i18nn_386af249b524809e": "生产发货单",
	"i18nn_b12f88738181c217": "确定生成发货单吗",
	"i18nn_167e06b5d98fca7a": "已受理",
	"i18nn_181f014c35e15c8f": "其他工单",
	"i18nn_b56fdc27a5fdd70a": "必填",
	"i18nn_31ce64e52ed71628": "非必填",
	"i18nn_bf5ca810e576858e": "选择框",
	"i18nn_2e5e43583197a181": "父类编码",
	"i18nn_bf371176126571cf": "操作编码",
	"i18nn_5d77e5949de8eef9": "自提单导入",
	"i18nn_f6283c46ba925ab4": "发货仓库",
	"i18nn_ebcd402850731325": "导出全部时",
	"i18nn_9b303c33a756ca45": "不包含",
	"i18nn_a8e47905b55a7fd5": "一维码图片",
	"i18nn_083b4e410bb4174e": "如需导出一维码图片",
	"i18nn_8888f6735d4b5717": "请导出当前页",
	"i18nn_de548b6f239487e4": "入库",
	"i18nn_3155418abb05309e": "出库",
	"i18nn_52687a58ca0645c3": "新建拆分",
	"i18nn_9700e172aec17e89": "拆分编号",
	"i18nn_ce6f905c8859cf74": "取消拆分",
	"i18nn_3a6fdc59b1cd84c1": "要分拆的",
	"i18nn_6726a8914c9083a3": "分拆后的",
	"i18nn_382a3d27fb8dc70a": "预计分拆件数",
	"i18nn_0ae3aed4e3b5423d": "处理中",
	"i18nn_3fe2f60f8a8b9214": "入库中",
	"i18nn_0b8eb588850e50f4": "确定取消拆分吗",
	"i18nn_ab894a37bf61ef3b": "确定提交吗",
	"i18nn_786593149c0bf9f8": "新建",
	"i18nn_0863222bb0aafdce": "要分拆",
	"i18nn_a43cedadf897a6eb": "分拆后",
	"i18nn_7f1242a9fcde8c48": "替换影响链接的字符",
	"i18nn_82cdc82bd48e14e0": "美国标准时",
	"i18nn_2bb60de6c1743a1f": "美国夏令时",
	"i18nn_726c51702f70c486": "创建时间",
	"i18nn_6c837fa18c154f3e": "箱唛下载",
	"i18nn_c67a0216d186c836": "箱唛成功回调",
	"i18nn_cdfe6c485e90d1f5": "打印箱唛",
	"i18nn_45855d1e066e1764": "旋转",
	"i18nn_9c17e9aea46e0c8a": "第二步",
	"i18nn_71be22ba63b84f22": "输入旋转角度",
	"i18nn_267cd7e59f3b0549": "一行一个",
	"i18nn_86c917b47c5fd789": "且按照",
	"i18nn_72e7588c2285cfd8": "顺序",
	"i18nn_bd2ea60194a4a9dc": "输入",
	"i18nn_84858479e2b2e839": "可复制粘贴",
	"i18nn_400156a53446e154": "列数据",
	"i18nn_818f8e46c3baa256": "请输入单号",
	"i18nn_cb48ac138393dec7": "一行一个单号",
	"i18nn_ec9fcd75e3d2b843": "且按",
	"i18nn_fe072dfa8e77ac15": "顺序匹配",
	"i18nn_c84e312946608267": "共拆分出",
	"i18nn_e7318027d521dd7c": "第三步",
	"i18nn_423aaa7563e762bb": "预览和下载",
	"i18nn_34051a0fa4c8b001": "和文件名",
	"i18nn_d71aefb39490bc0d": "开始预览",
	"i18nn_d11719409666a169": "显示测试",
	"i18nn_dff5b3da08659f44": "第四步",
	"i18nn_bb77a02f2157ee4b": "下载并压缩",
	"i18nn_d5a306be96327a1c": "压缩并下载",
	"i18nn_1ca7506b642073ca": "输入单号",
	"i18nn_e61eff8fdd759f9d": "单号列表",
	"i18nn_4ba633ab624e491d": "压缩测试",
	"i18nn_afa4dddf5a6214d4": "默认其他",
	"i18nn_9a8a031ac6ac8473": "直接打印",
	"i18nn_05d718c95a72fa48": "请先升级或下载",
	"i18nn_086a73016c53d692": "异常",
	"i18nn_ddc9cdfdcae67820": "设置上次打印机的值",
	"i18nn_89df29c83fd68a9b": "解析成",
	"i18nn_797bcc6c4af7fa88": "解析后的数据",
	"i18nn_8ca9d59119d46247": "上传失败",
	"i18nn_4c6f792c953f85ae": "获取旋转后的的",
	"i18nn_c33c5939b8538193": "旋转成功",
	"i18nn_49ab0225e5564b8d": "处理失败",
	"i18nn_9be91633f0d169ea": "初始化打印机列表",
	"i18nn_105937855b43aef9": "正在准备",
	"i18nn_f9070b0f5b16a182": "展示",
	"i18nn_07334594acbc2790": "画布",
	"i18nn_b28d38de28413af9": "暂无拆分的文件",
	"i18nn_9c349f7ff56e5ca7": "文件压缩包",
	"i18nn_6db9264f0dd414cb": "暂无文件",
	"i18nn_4c370f83c891918a": "保存此次打印机的值",
	"i18nn_52f18910f3b96487": "文件分割",
	"i18nn_25687702e835ea72": "开始拆分",
	"i18nn_ebb6eb97b8a2260a": "预览加载预计共需要",
	"i18nn_7f7865ded377b432": "秒",
	"i18nn_b6bdc839bdc262f3": "每个文件",
	"i18nn_57998aee70aea45f": "秒左右",
	"i18nn_c09ef1afcae8a14b": "获取拆分后的的",
	"i18nn_352e53736a4c4cda": "拆分成功",
	"i18nn_3d6d2d670fa63b7c": "渠道",
	"i18nn_ab5902ee5c645e01": "不可用",
	"i18nn_a9e1d1c2c5eb6525": "刷新",
	"i18nn_8dfbe229da70319b": "指定特定渠道",
	"i18nn_23d809d3bce6b99a": "查询信息失败",
	"i18nn_7a22cefca1bca21d": "单个",
	"i18nn_fa4075a55f397b88": "列表信息",
	"i18nn_aaa9fd271d84c6e1": "信息失败",
	"i18nn_8be4f7e9a9fa89e2": "列表信息接口",
	"i18nn_f444ba8c0fa47753": "下载箱唛",
	"i18nn_3b513604a77443db": "箱唛打印",
	"i18nn_4045c7be6edea082": "客户模糊搜索",
	"i18nn_8f574078dfe6e56c": "请求",
	"i18nn_398644c781d3f039": "名称搜索",
	"i18nn_21b22a8018f3cba2": "批量下载面单",
	"i18nn_1cbfbf4afdd75282": "批量下载附件",
	"i18nn_2de6c4eb2e40cf1f": "请使用谷歌浏览器",
	"i18nn_c26d5c523b0b69d3": "没有面单可以下载",
	"i18nn_378153e9411327ea": "个面单",
	"i18nn_b7819b9d931008b0": "正在压缩",
	"i18nn_45b0c5bc56871f57": "查询费率结果",
	"i18nn_ff89821d70424147": "选择此渠道并保存",
	"i18nn_6f7b6a7194049eb4": "选择查询后的费率保存",
	"i18nn_89ca09994c8c4d74": "保存指定渠道",
	"i18nn_34b9e521d99ba4c5": "当前选择的",
	"i18nn_33736cef5230e85b": "为不可用状态",
	"i18nn_0c3e84f9f6aa0e52": "已审核",
	"i18nn_076b387e20700c4c": "只有草稿",
	"i18nn_31b95602f46fe26e": "审核状态才可以提交打单",
	"i18nn_b758e3984e5d5a86": "改变",
	"i18nn_21e42bbe9894aafc": "收货入库",
	"i18nn_194d733acdedb737": "一件代发操作",
	"i18nn_9695030363addb97": "一件代发出库",
	"i18nn_5151fd7875058ddf": "仓租",
	"i18nn_636d7192b222277c": "附加费",
	"i18nn_45eae388d108d4fe": "操作记录状态",
	"i18nn_82b5bd967d494be3": "操作类型",
	"i18nn_ceffcc237c2608b9": "合计",
	"i18nn_c2c4e3a31c61dc7f": "记录数",
	"i18nn_da35456de5403862": "合计金额",
	"i18nn_ed6ece9e43eef321": "种类",
	"i18nn_656e30fd838de0c2": "附加操作",
	"i18nn_b0a8a31be81514ba": "收费",
	"i18nn_16658a855f07d171": "金额",
	"i18nn_b45b4338ee939ad0": "详情弹窗",
	"i18nn_6afbe4c6dcf87a6c": "费用明细",
	"i18nn_851e87b9fe311af7": "打开选择框",
	"i18nn_7f995eb43e1fc92b": "按体积计算",
	"i18nn_02ad74c45e9271a0": "按重量计算",
	"i18nn_9cee201b82dbc9cb": "未开始",
	"i18nn_1ff1fd8ddb3e0e8d": "拣货中",
	"i18nn_a1f096b4fd6848be": "合计需要的参数",
	"i18nn_823f9bd6c61d2733": "默认体积",
	"i18nn_9c4659d873c8b417": "请先选择日期",
	"i18nn_cc1eef3b5ac02fff": "解决表格错位",
	"i18nn_199bdff7643b9385": "请求合计数据",
	"i18nn_bc118f4faee1209f": "合计查询失败",
	"i18nn_8bf94e1ab6927bd0": "打开详情弹窗",
	"i18nn_9c0ace2b5d32f74c": "请求数据有误",
	"i18nn_5599155c3578c611": "废弃",
	"i18nn_3acca92babc7df1c": "确定废弃吗",
	"i18nn_f66fc986e2ae222f": "废弃成功",
	"i18nn_33e215cab320c32f": "查看费用明细",
	"i18nn_ab7c1e41cdae24c5": "重新计算",
	"i18nn_9168144190f66f5d": "关联编号",
	"i18nn_6e9c4e33131b18a7": "申请数",
	"i18nn_e3de3b77067f6a24": "单数",
	"i18nn_5ffda681afa71861": "单件",
	"i18nn_621091be3bb350a9": "单价体积",
	"i18nn_14b9b51812811e15": "总费用",
	"i18nn_1595bdab4d7bf7c2": "当日出库情况",
	"i18nn_396cb19960e15651": "总单量",
	"i18nn_16d3ec8d8ecbac75": "已超时",
	"i18nn_7d814d9a1e592965": "未处理",
	"i18nn_4f5a6083d34bdccd": "共享文件",
	"i18nn_591c1b2fa3e699cc": "入库统计",
	"i18nn_af1a2cc986597e90": "申请入库",
	"i18nn_15aeae6cfcd5725a": "收货数",
	"i18nn_a967bc023b5157c4": "本月订单统计",
	"i18nn_5dfd274e7c6c5bc9": "出库情况",
	"i18nn_52fc37883d947301": "出库情况数据",
	"i18nn_2f47c3d499a6e740": "订单统计",
	"i18nn_b3851508825cae52": "入库申请",
	"i18nn_ee110e3c7304b62d": "其他服务",
	"i18nn_72917c44e787864a": "报价",
	"i18nn_55806c9c59b87e47": "询价",
	"i18nn_3b4e0cc90e264c6f": "提货",
	"i18nn_b5f81f005ec90ee8": "海外仓报价",
	"i18nn_d8f295864833fc57": "卡车尾程询价",
	"i18nn_19918f72bcdca413": "空柜预约",
	"i18nn_c0eb5cb77d8024b6": "文档",
	"i18nn_fdb7b865dfa87747": "资源库",
	"i18nn_d115f29ef6edb41f": "销售部",
	"i18nn_7f62b4bd2d0342f6": "卡车部",
	"i18nn_d4fa038149a29ca5": "港口提柜部",
	"i18nn_c4be5125be4a0bb5": "人事部",
	"i18nn_4bce523e57e94567": "运营中心",
	"i18nn_b5e2fe92fe54ace2": "总部电话",
	"i18nn_ab0778ba44a8f3c1": "卡车部电话",
	"i18nn_8861e53cbc04161f": "海外仓",
	"i18nn_4c6841ce9043c9fe": "互易仓",
	"i18nn_e0a806b3165119e6": "微信公众号",
	"i18nn_0f63cc67b9017a81": "微信扫一扫关注",
	"i18nn_c12ab070fe3cbeac": "微信扫一扫添加",
	"i18nn_f600a6d1dbcfce47": "微信号",
	"i18nn_b33f004fe453ef3b": "客服手机",
	"i18nn_a4365f7edaab7453": "系统优化反馈",
	"i18nn_7079c6a7861b3dca": "本地",
	"i18nn_74a5def3116ecd89": "分割",
	"i18nn_adea92839a4c22b0": "生成面单",
	"i18nn_a9eaee9d97fb6c43": "已暂停可以恢复",
	"i18nn_1a42987a0df962e9": "恢复",
	"i18nn_80175af49375b10a": "恢复暂停",
	"i18nn_df7bb549078d49cd": "恢复吗",
	"i18nn_5965027c91e708d0": "提交录入的运单",
	"i18nn_baf81ceae5191d42": "统计状态数量",
	"i18nn_d4a2f8ababb9fa73": "状态统计",
	"i18nn_31519f8b1453a53e": "出库类型",
	"i18nn_4429a015eeb36653": "托盘数量",
	"i18nn_ee57468801a2964d": "已撤销",
	"i18nn_2f9954db5ba06447": "拣货类型",
	"i18nn_7b1927e9f5c2b1a1": "拣货时间",
	"i18nn_6fba545981478392": "打托完成和出库完成图片",
	"i18nn_6c1a30d60ca12a5f": "打托完成的图片",
	"i18nn_8139c1fa7c3b121f": "出库完成的图片",
	"i18nn_82d8e302e3c71782": "拣货完成图片",
	"i18nn_87a1b283d9bd94b8": "出库完成图片",
	"i18nn_6dfc48f51729ae2d": "打托完成图片",
	"i18nn_7bc8a7fd7ad68c07": "按月统计的",
	"i18nn_88ef88455ebf24a9": "一件代发的",
	"i18nn_bebf7be502526462": "这里根据",
	"i18nn_211115f8977940c3": "填写你的跳转逻辑",
	"i18nn_4575044e90eb79cd": "按月统计",
	"i18nn_4a6ad688b6b0de73": "显示选择数据最后月的当月数据",
	"i18nn_6c29963675196a7f": "号至当月最后一号",
	"i18nn_64d8e8d588effa0b": "点击的值",
	"i18nn_4c7aa2bda11fa67f": "筛选传入的",
	"i18nn_1f9d21cb16e0c002": "最后月的值",
	"i18nn_52b4f481aab3e343": "订单按月统计",
	"i18nn_6e5d728ebf9703a8": "订单按日统计",
	"i18nn_7a702b4adddd528a": "点击按月统计",
	"i18nn_c44b77fdd0a0914d": "查询仓库",
	"i18nn_a0caf27419f2effd": "查询仓库失败",
	"i18nn_866ffc98aa735561": "服务报价",
	"i18nn_1318271bf151d3b1": "海外仓联系方式",
	"i18nn_eaf9e22fc96815be": "客服微信",
	"i18nn_76b639640b949a53": "财务账单",
	"i18nn_26f639f47de564bb": "溢出隐藏",
	"i18nn_5cb59a5613ecff7b": "不换行",
	"i18nn_30eddd41470d7893": "打开查看",
	"i18nn_373dcbacd938fcf3": "编辑时",
	"i18nn_d6cecadea664b088": "显示数据仅供参考",
	"i18nn_58830b2f3e6b548e": "建议重新上传模板",
	"i18nn_08b0ac0e59879bb8": "编辑保存时",
	"i18nn_93425399dd0a7b54": "建议按照",
	"i18nn_33325b78e48f38bb": "品类",
	"i18nn_98de889dff3208a6": "规格",
	"i18nn_23ad9574b8ec9299": "款式",
	"i18nn_6e13ec00e5b30fcf": "数字字母编码组成",
	"i18nn_e729e61f9c82621b": "且保持唯一",
	"i18nn_ea2e8aca44cc7471": "保持唯一",
	"c944a6686d996ab3": "仓库",
	"2ad108ab2c560530": "请选择",
	"hytxs0000001": "请输入",
	"5acbec83efb27445": "仓库编号",
	"7431e76029678ec7": "标题",
	"hytxs0000060": "客户名称",
	"209fdd8f47e20fbf": "客户编号",
	"184333c81babf2f1": "类型",
	"i18nn_90b7f7d6846dfdce": "导出表格",
	"46c3f6e0f657e7a3": "费用类型",
	"d7315fb8114eb446": "费用",
	"3775920d44088b96": "件数",
	"96e86c7f83dd7561": "收费方式",
	"9bfcf061ccdd5c1c": "账单日期",
	"hytxs0000035": "库位",
	"539728f5dd9cb065": "SKU名称",
	"cc62f4bf31d661e3": "提示信息",
	"4e9fc68608c60999": "关闭",
	"e33c9b93c36fd250": "删除",
	"5a9aefbc03c778f7": "请输入",
	"44bd089554515c4d": "加载中...",
	"i18nn_a332a3ceebd61228": "切换标签时",
	"i18nn_b31f6c49613389c2": "窗口改变时",
	"i18nn_7f4c86f986e10cea": "数据改变时",
	"i18nn_b0f975d8c101a08f": "表格重绘",
	"i18nn_acc7a553954902d7": "房子出现",
	"i18nn_b1b1b4f623b3e2f8": "是错位",
	"i18nn_c83ab8aa8360a6b4": "获取页面中已注册过",
	"i18nn_795e96d372b21378": "的所有的子组件",
	"i18nn_a3502344f06f5b38": "根据",
	"i18nn_51d903b15bb15ebc": "方法判断子组件是不是",
	"i18nn_762cf41ecbb3ee58": "执行",
	"i18nn_da8b893ddf2d9176": "方法",
	"i18nn_9927eadcc160b75c": "授权页面",
	"i18nn_4b1ce14cae162e13": "验证并获取回调",
	"i18nn_2ed49c526ba70768": "分开授权",
	"i18nn_44e6c18367861a4d": "验证",
	"i18nn_0f5c0ab6a4936ed4": "回调获取",
	"i18nn_59c724de61d75333": "授权回调页面",
	"i18nn_b9f099bf21b81e70": "关闭本页面",
	"i18nn_0b32aa344b9d3cd8": "回调页面",
	"i18nn_5791de4cb2735c47": "传递",
	"i18nn_7c6c9b6dfa3e651f": "您可以关闭本页面",
	"i18nn_9eca3546a05b0150": "如果有待上传文件",
	"i18nn_9a39ba5b35433d6e": "没有待上传文件",
	"i18nn_f11e64895a45d731": "回调空数组",
	"i18nn_e3460ccf4f5eba14": "以最后一次为待上传最终文件",
	"i18nn_5e74b9788b2ceab8": "清空待上传文件",
	"i18nn_aa3b760147bd92e6": "下标",
	"i18nn_a0ba0a35323754ae": "上标",
	"i18nn_eea71e4304e82893": "清空展示通知状态",
	"i18nn_b55874f01c0bc221": "账户余额",
	"i18nn_51fd0a603ed58073": "去充值",
	"i18nn_a9c4ab3208bd8aab": "查看充值记录",
	"i18nn_61bd9a8fcc2ea788": "温馨提示",
	"i18nn_f7547f82aa22dc24": "预付账户目前为线下充值",
	"i18nn_36098b1b6b9edf80": "请转账完毕后保留转账记录",
	"i18nn_7d3286d3ba74a4b9": "并线上申请",
	"i18nn_2b4508307cb41f8f": "我司收到申请后会第一时间处理",
	"i18nn_6ab4144b540a8a6a": "变更类型",
	"i18nn_b4e5993f97fe04ee": "撤销",
	"i18nn_35ec768075eb8f4e": "入库单号",
	"i18nn_9be246e60da2a0a2": "到仓日期",
	"i18nn_6f153d59e22a769f": "查看收货清单",
	"i18nn_10bc8a2b45aa5636": "增值/售后服务",
	"i18nn_a7e730af52ab52b1": "收货清单",
	"i18nn_2f19f86ff8be446f": "售后",
	"i18nn_4c673b62fe72622a": "未入库",
	"i18nn_75dd38674c2bbac2": "状态数据才可操作",
	"i18nn_3dc377fecc5acdb1": "收货列表",
	"i18nn_3e8b33480ff7e0f7": "外箱信息",
	"i18nn_913e044950ff558b": "外箱体积",
	"i18nn_228e58fddadbff82": "上传附件",
	"i18nn_a2b42f1c9bf76168": "当前角度",
	"i18nn_0abac39c74830494": "复位",
	"i18nn_a9e9d8651e405af1": "复位重置",
	"i18nn_dc197c8c018305f5": "自动加",
	"i18nn_f1c66f3de00226c1": "度",
	"i18nn_6b42f3e7ab6e4c7e": "更多筛选",
	"i18nn_c4ca802cf2230b0b": "筛选",
	"i18nn_559fed47b0e95407": "转运单号",
	"i18nn_a1399a08e08ff6b9": "打开更多筛选",
	"i18nn_622d18905c5266b4": "查询更多筛选",
	"i18nn_ecc3d56be2ae0baf": "其他参数",
	"i18nn_8ac049aa3fac8e9f": "保险",
	"i18nn_ade48dee835e25c0": "签名",
	"i18nn_a8980879d3a4c82a": "请输入并选择",
	"i18nn_e90caa8826c11146": "重新搜索默认数据",
	"i18nn_9ab1e759ed215b97": "客户模糊请求",
	"i18nn_0fdb98c494be76c7": "去除空格",
	"i18nn_e12ec0fbad09bac1": "关键词搜索数据有误",
	"i18nn_61d304ff72c5c155": "关键词搜索数据",
	"i18nn_0c901139f9896f14": "已删除",
	"i18nn_a87302a8cd63eae8": "模板已更新",
	"i18nn_26ad8f1f07292f65": "导出各个账单前",
	"i18nn_21bae2bd339406af": "数据越多越慢",
	"i18nn_5734a059a4622f20": "前",
	"i18nn_5d6f695ebedef0a4": "分批导出所有页",
	"i18nn_f71701ed55a2e712": "每批",
	"i18nn_8d8c79fcfaa246f5": "导出前",
	"i18nn_018de66d9d708dc7": "含合计",
	"i18nn_11c05b307e012228": "默认列表",
	"i18nn_68ceeb72fa5cbecf": "账单合计",
	"i18nn_ac64d1d6f380ef6a": "第二个页签放合计的数据",
	"i18nn_8921d880576fbe1e": "过滤条件合并",
	"i18nn_0468e55d7cd0bf0e": "仓租的筛选项",
	"i18nn_f4a17fe75237eecc": "加载全部账单数据",
	"i18nn_d33d0afe39b5b1a2": "仓租按日汇总",
	"i18nn_65b02ebff64ee1a6": "仓租明细",
	"i18nn_b4c3f9fe658d58d2": "数据正在加载",
	"i18nn_d55802bcbb5f2661": "模拟大数据量",
	"i18nn_36ed3657e60217a8": "数据已准备",
	"i18nn_7be1fb8d3e601725": "统计数据",
	"i18nn_d8644e8d60c8bd8a": "单价",
	"i18nn_de7090439acfce3f": "托",
	"i18nn_c65deeaa92c96268": "总金额",
	"i18nn_c1095f42b8f33816": "汇总数据",
	"i18nn_37f3ec4a54bf2dbc": "汇总数据已准备",
	"i18nn_4d7265155f2745f2": "汇总数据有误",
	"i18nn_d60bf020cc97d43d": "合计显示的数字",
	"i18nn_e8ad5e493b3a3030": "计算分页",
	"i18nn_cbd2e4eb12b71c1e": "不计算第一个分页",
	"i18nn_bf090996761c9056": "计算一共有多少分页",
	"i18nn_283071229a9a6a70": "计算分页的起始和结束",
	"i18nn_c1bdca8d2544fee9": "已上传附件",
	"i18nn_775723b177f5e711": "无上传文件",
	"i18nn_a0658db22ed941f6": "直接提交",
	"i18nn_b13659103f6bd76a": "触发回调",
	"i18nn_52dbbd0472a2f8a8": "再次提交时",
	"i18nn_b9618676820a7be7": "已有上传文件",
	"i18nn_671663add1f18f05": "若该订单已完成线下快递面单生成",
	"i18nn_8e258808e2a13395": "请在此处上传对应面单",
	"i18nn_b6f0167ad2fd3cee": "订单单号和文件名要相同才可以匹配",
	"i18nn_8f2e8ec212959586": "好的",
	"i18nn_7c3fc1953013745c": "物流轨迹查询",
	"i18nn_2279b6465da730e9": "物流公司",
	"i18nn_faf9f786f1c93a10": "易仓拉取查询",
	"i18nn_316e16926411681a": "渠道商",
	"i18nn_76e82afd9c2b6ca1": "提交时间",
	"i18nn_e4f53ced9388c487": "发货轨迹查询",
	"i18nn_7fa1fea309b0fd8d": "取消进行中",
	"i18nn_5be51b56e8802896": "发货单轨迹查询",
	"i18nn_c136c2ffbc49c6e7": "未知的快递渠道和单号",
	"i18nn_2bfaf322a81c4e8c": "选择地址",
	"i18nn_f1e789e4700e15e3": "选择发件人地址",
	"i18nn_554342775fea1a93": "需要传的字段",
	"i18nn_d81f561e7fe6dacc": "发货地址不可编辑",
	"i18nn_f13b5a3276612c1e": "发货地址",
	"i18nn_97ec3bb319c68844": "接口",
	"i18nn_4d04102b6435ddef": "上传数据回调",
	"i18nn_8b9618de2e85645e": "快递号",
	"i18nn_380912455c6e2f06": "可多个查询",
	"i18nn_0deaa0a62ac136b6": "销售合伙人服务费",
	"i18nn_d88844033912c1fc": "报错信息",
	"i18nn_2678054e53eeefdd": "报错编码",
	"i18nn_094903a0e1dd5849": "物流轨迹",
	"i18nn_f2896032570c4d41": "地址信息",
	"i18nn_eca5c29ad1a845c1": "目的地址",
	"i18nn_93ba6023a4128d75": "托运人信息",
	"i18nn_e29ea9e7cb029d6c": "服务信息",
	"i18nn_ca9b892a79dfa7ee": "服务命令信息",
	"i18nn_50a95a5f20c112cf": "包裹标识",
	"i18nn_38482e62a277f304": "最新状态详情",
	"i18nn_5360545085fcff1f": "其他详情",
	"i18nn_86ba00a2be935c6d": "判断",
	"i18nn_e7959874088344ea": "有没有值监听路由变化",
	"i18nn_e83442620acf37fb": "查询参数缺失",
	"i18nn_d9145c2cbe66f3dd": "请主动切换至账单明细查询查看",
	"i18nn_a3a09445e8b04a4f": "授权测试",
	"i18nn_e9970bf947cf72e9": "请输入快递号",
	"i18nn_06ef5de26edd9e88": "交货信息",
	"i18nn_d721bca263c00924": "日期节点",
	"i18nn_5c98d407627f383d": "账单汇总",
	"i18nn_5203265398bc2ae1": "账单明细",
	"i18nn_0d35fa57b3aab0f7": "按客户汇总",
	"i18nn_e19ca5d2e0c83887": "查看汇总明细",
	"i18nn_ac53fd8c761abf7e": "汇总明细",
	"i18nn_2f281726defd00db": "查看账单明细",
	"i18nn_cf84be5a2f8c584b": "按仓库汇总",
	"i18nn_588fde1c3de565ab": "统计图",
	"i18nn_e8c72262204a35e8": "所有费用汇总统计",
	"i18nn_2c98a37551a345ca": "全部汇总数据查询失败",
	"i18nn_edecf12fe0855801": "按仓库费用汇总统计",
	"i18nn_1b210b98ae740911": "按仓库汇总数据查询失败",
	"i18nn_bf3e50d53670c583": "按客户费用汇总统计",
	"i18nn_0c72f3afd482899d": "按客户汇总数据查询失败",
	"i18nn_a3e55db0637a9a7b": "跳转到账单",
	"i18nn_18b633e513af5fbe": "刷新客户汇总",
	"i18nn_8d8924dbda6971a9": "搜索按客户汇总数据",
	"i18nn_9468bb0475de16b2": "按仓库的客户费用汇总统计",
	"i18nn_44c439345b1a2d97": "按仓库的客户汇总数据查询失败",
	"i18nn_642e98272f60f411": "按客户的仓库费用汇总统计",
	"i18nn_cf698047bdf5efae": "按客户的仓库汇总数据查询失败",
	"i18nn_66e4a92601ee37e3": "总数据",
	"i18nn_a528df5f63d891d5": "按仓库汇总数据",
	"i18nn_59a0a5ddc2dd4a09": "只有数据长度一致才显示",
	"i18nn_07d4648dfc7810d5": "仓库账单",
	"i18nn_e730a6add110453e": "开始查询渲染子组件数据",
	"i18nn_c2f3531c65acc369": "合并计算的导出",
	"i18nn_2cac9b8ca9e73c23": "的列名和",
	"i18nn_677c64de2a9f928d": "格式化计算后的数据",
	"i18nn_723f3d8f11c640b1": "托盘最大承重",
	"i18nn_06b5d5b29cf6518c": "立方",
	"i18nn_949033ac59c59100": "计算方式",
	"i18nn_c726626501fc8bc1": "导出明细",
	"i18nn_748bca3d68c277f5": "计费周期",
	"i18nn_3258bbe488af3e79": "天",
	"i18nn_7d6f758a93cb33b5": "公告",
	"i18nn_a923152a83c4a077": "物流索赔须知",
	"i18nn_028ab03ad6e22c6b": "所有尾程物流相关的索赔必须在发货后",
	"i18nn_e8c6923ccbdd2223": "天内提交到",
	"i18nn_b5be565e0f3f0447": "索赔表格及流程可以向",
	"i18nn_20050bcc9ae951ce": "索取",
	"i18nn_1c13ad39cb6e4d37": "超过时间未申请的尾程物流将无法向",
	"i18nn_0bd3479de0572734": "提出索赔",
	"i18nn_56ba0012c41d3d6f": "通知",
	"i18nn_8adb10f3af5ebc2f": "悬浮",
	"i18nn_caf2eff6e258a06d": "售后服务",
	"i18nn_74e7a7ab1181812c": "售后记录",
	"i18nn_4dc6f04018e0f73b": "问题类型",
	"i18nn_9666c59aedbfa70e": "模块类型",
	"i18nn_3af42243b3f54e9b": "创建方",
	"i18nn_f4c5829c71fcd8bf": "进度节点数",
	"i18nn_627df7b14df32cdb": "申请人",
	"i18nn_e1911e9360047dcb": "更新",
	"i18nn_10a6a2a6124bbb9a": "查看进度",
	"i18nn_f80dedf7c601059f": "增值服务",
	"i18nn_2b65059530ec3ed5": "受理中",
	"i18nn_1fb4422ebabb847a": "正常结束",
	"i18nn_0eb9b2e0d01ad12b": "异常结束",
	"i18nn_dd3266ec627e31e4": "打开新增",
	"i18nn_02f57850a3029222": "本页切换",
	"i18nn_57d71ea1aa426464": "查看等",
	"i18nn_8f8b0799ae9827e0": "便于客服对接",
	"i18nn_f085596674018f3e": "关联",
	"i18nn_c62590f388b2ce71": "服务描述",
	"i18nn_466677324278a9a1": "概述",
	"i18nn_805c8f776e461e39": "详细描述",
	"i18nn_ad36057ff6a7ce6b": "添加附件",
	"i18nn_74f0f436fa58dfe4": "详情说明富文本",
	"i18nn_3d23fd1011cdc075": "详情说明",
	"i18nn_9aeffaaba1370ae7": "是否从模块进入",
	"i18nn_7d44b93791468bc3": "计算费用明细的金额",
	"i18nn_75f524bffcbcff15": "删除附件",
	"i18nn_2ba96cbfad324580": "弹窗更新",
	"i18nn_5184f7a4033b4969": "请选择客户",
	"i18nn_1118edcaeb4c3679": "请选择仓库",
	"i18nn_dad95a93a594366e": "注销",
	"i18nn_994cb374e601fab3": "编辑成功",
	"i18nn_77c109efc99c0028": "编辑详情的数据",
	"i18nn_2fc1c1e30d69c8c7": "服务进度",
	"i18nn_3c7beea052b8230c": "新增进度",
	"i18nn_354482714c59064a": "更多内容",
	"i18nn_722871fda2c6fb4e": "打开留言",
	"i18nn_aae671b49dde2bba": "基础信息",
	"i18nn_0f6916ca53218662": "新增服务进度",
	"i18nn_b95047968d1358d4": "进度状态",
	"i18nn_450e8e72d3a52ce2": "详情富文本",
	"i18nn_d6ab1c29b36f35ae": "请选择提交状态",
	"i18nn_786280a019b8a1b7": "服务详细描述",
	"i18nn_efbfa769d113b9fb": "请输入留言信息",
	"i18nn_52a8c38184d84581": "系统单号",
	"i18nn_32b164c53fa35e6d": "模块名称",
	"i18nn_b3e62dc191a3289e": "退货增值服务",
	"i18nn_f1d6776242105f03": "增值类型",
	"i18nn_6373cb1d1204d580": "自提",
	"i18nn_81f6eaf23b9c56eb": "快递",
	"i18nn_c39fd3d48389bea7": "入库预报",
	"i18nn_9dc7842043356291": "中转",
	"i18nn_f2fbaba1a43b29f6": "已废弃",
	"i18nn_1ba6fbd520bc16df": "增值服务记录",
	"i18nn_3f2ee05e57ec8c0b": "批量生成面单",
	"i18nn_8dc3003658b13cca": "文件处理",
	"i18nn_b4296642bcc9f428": "退货仓库",
	"i18nn_e63fc078a7baf7c9": "快递信息",
	"i18nn_e83443dd6c718cf9": "物流轨迹状态",
	"i18nn_41d020489ecacbf0": "物流更新日期",
	"i18nn_3601b5a98803f4d7": "出单方式",
	"i18nn_198d8fd115bfc3d9": "自供面单",
	"i18nn_15bc1e82df3428d8": "系统出单",
	"i18nn_2582c3a44f7596c2": "客服记录",
	"i18nn_68ab250470bd3e4f": "选择批量打单",
	"i18nn_b74df69000311063": "批量打单",
	"i18nn_fdbfdd6b62e582b1": "批量打单支持的服务级别为",
	"i18nn_157f5b25af1adf94": "如果打单失败",
	"i18nn_89d5f61bf8d059a1": "可以进行单条打单",
	"i18nn_52def4f590ebd639": "选择适用的服务级别",
	"i18nn_7d9664098f76ed4f": "详情查看",
	"i18nn_df8b4d2c8fd5548e": "去详情",
	"i18nn_1ca0e2fc087a178a": "未知的快递公司和单号",
	"i18nn_5715d8eb76147f98": "处理",
	"i18nn_a544f42463e5840e": "确定拦截吗",
	"i18nn_ec6e072b75b892b6": "打开弹窗选择",
	"i18nn_44242c07a1f17e31": "只有状态为草稿",
	"i18nn_8f3b691fb1928c4a": "已审核才可以提交打单",
	"i18nn_9c63dfa3ec2d8024": "批量提交打单",
	"i18nn_7e94b4495b945416": "不同打单渠道",
	"i18nn_9404be77588b0259": "条数据吗",
	"i18nn_04c1c1452cb3359f": "确定提交这",
	"i18nn_422d8aa4b5756fa4": "确定提交打单",
	"i18nn_48e4efd421dad5b7": "退货单号",
	"i18nn_3e764f7ae7f5e850": "退货物流单号",
	"i18nn_ef712dc1dd7c6dac": "签收照片",
	"i18nn_dc628291530d00b9": "签收附件",
	"i18nn_8291ced1c9213734": "兼容旧",
	"i18nn_3874de45d4018f3d": "查看签收附件",
	"i18nn_b1467e06606a8471": "实际签收数量",
	"i18nn_18c1c1a3ea76e9d3": "托盘总数",
	"i18nn_f88b93f4fd02974c": "托盘总标数",
	"i18nn_82dd2957184fa298": "出库附件",
	"i18nn_b3cc9e7df0c87798": "备货完成附件",
	"i18nn_b8906d9824b52a7b": "留言板",
	"i18nn_55a13021a8604d26": "留言",
	"i18nn_94d68bad9f60445f": "打托",
	"i18nn_2bea3f02bd4a8c8c": "附件预览",
	"i18nn_67572c1c4fdfeab2": "查看出库附件",
	"i18nn_0c0af12b8fb80f26": "查看备货完成附件",
	"i18nn_ac3f40a45db7c05a": "标签数",
	"i18nn_f7e99b8c203e2b07": "订单详情",
	"i18nn_92f2ebbe14794f38": "一件代发详情",
	"i18nn_148c3451c7861e8f": "批次",
	"i18nn_71a647a484c3f5c2": "发货列表",
	"i18nn_fc7e87b5dfa6e4b8": "代发数量",
	"i18nn_d5d972e6063a3180": "长度计算",
	"i18nn_dfd180c9fc784ac6": "收件人地址",
	"i18nn_15da46e90a2c3e3b": "异常件处理",
	"i18nn_44280840307aa95c": "自提单",
	"i18nn_5d4c76c6431c06ef": "按年",
	"i18nn_dae8d92a749abc4e": "修改密码",
	"i18nn_cd85c52ecc16ffe2": "转运管理",
	"i18nn_1f1bd51a9a045025": "转运入库",
	"i18nn_65fe75ea422bdc50": "转运出库",
	"i18nn_0950e42c6423c2a7": "转运库存",
	"i18nn_90faee419c9a32ae": "易仓",
	"i18nn_a861c2514faa683b": "配置",
	"i18nn_020c236df168c388": "售后管理",
	"i18nn_90db8de2b4a16c9d": "易仓平台订单拉取",
	"i18nn_3f9e9a6fe6f4f971": "易仓订单状态",
	"i18nn_cd18d57f3bd7779f": "一行输入一个",
	"i18nn_68a6337017ca7d67": "销售单号",
	"i18nn_a456e74e3c8a95f2": "拉取",
	"i18nn_79205a47cd5d529c": "拉取最新订单",
	"i18nn_e99e48ab45c5e800": "更新发货仓库",
	"i18nn_5138d5a49a5cc5df": "刷新发货仓库",
	"i18nn_e9d55d41fee9a273": "撤销发货单",
	"i18nn_09124c6c5acf6c8a": "易仓平台",
	"i18nn_bdf9dc871938ad08": "是否同步",
	"i18nn_2d78a37c0efc2723": "易仓订单拉取",
	"i18nn_43e1cb7bb0962388": "删除订单",
	"i18nn_adfbc9ddacfd7f42": "确定撤销发货单吗",
	"i18nn_50f004ee0fb07dbb": "请先选择代理客户",
	"i18nn_a6abf1dd9e065e27": "新建配置",
	"i18nn_b306361a5aeda4e8": "账号备注",
	"i18nn_0e3b6b0b53dd94e8": "易仓用户名",
	"i18nn_8af36bb95bcac474": "易仓密码",
	"i18nn_ecb75613330d89fa": "服务商",
	"i18nn_27aaf0520109d72e": "服务商编码",
	"i18nn_44b27e0a8476a256": "更新仓库",
	"i18nn_ea7a53fd80d5cb23": "打开仓库更新",
	"i18nn_73fc38e67381ae73": "外箱标识",
	"i18nn_c8773d8d74202801": "托数",
	"i18nn_70b5c883b227851b": "箱子长",
	"i18nn_0a389c3a5e3938d3": "箱子宽",
	"i18nn_5c31dfdb8f4781ec": "箱子高",
	"i18nn_9e168db87b716866": "单箱重量",
	"i18nn_c5ef0b068e92e23d": "箱内",
	"i18nn_8a43886ff356ed78": "箱子标识",
	"i18nn_53fa7ff1ea383b2f": "箱子数",
	"i18nn_91263f8fa1a94da9": "箱子重",
	"i18nn_20c39ac8a6aeb87d": "单元格右击展示菜单",
	"i18nn_382c5bafd600d2bd": "下面插入行",
	"i18nn_8b456c5396713d9a": "上面插入行",
	"i18nn_77054b0e5ea4d30d": "左侧插入列",
	"i18nn_1b2106ded89310ba": "右侧插入列",
	"i18nn_f04cf5507ecc5774": "移除该行",
	"i18nn_327aa3849b3d5c68": "移除该列",
	"i18nn_f7f39a6e6d9d26cb": "只读",
	"i18nn_0607472de4525358": "对齐方式",
	"i18nn_ea37e4e88aada736": "边框",
	"i18nn_29f67f0644a78bf3": "复制",
	"i18nn_6b7fbcd658f0ccdb": "剪切",
	"i18nn_c420ac9915ca288d": "清空所有单元格数据",
	"i18nn_e92e98bf79f5667a": "更新列表配置",
	"i18nn_9c86859a48e19852": "列关联隐藏",
	"i18nn_950f48667a7ca29e": "列关联显示",
	"i18nn_d2e73c04a7bba3a2": "数据字典格式化",
	"i18nn_ffdca5df72a21b0e": "初始化和加载数据",
	"i18nn_d65010e7be5abfa7": "数组转对象",
	"i18nn_536653d9057b6713": "获取数据",
	"i18nn_f3da92af9fe55ee7": "清空数据",
	"i18nn_4e4e17df00c7c66c": "如果有传值",
	"i18nn_6a800c36981ed32f": "无值",
	"i18nn_ce1923a0c6c05200": "数据字典",
	"i18nn_4e4bec1fd140fcf7": "入库箱数",
	"i18nn_48bc6e8b56cba243": "在库箱数",
	"i18nn_0ddc3b0b28d12b29": "锁定箱数",
	"i18nn_51c7caf81e915eb4": "可用箱数",
	"i18nn_53f9f6f694a5b0a8": "单箱尺寸",
	"i18nn_202e232a73f0abe1": "查看出库明细",
	"i18nn_b1d3482f4406f513": "出库明细",
	"i18nn_7f6c721b57ed0e7b": "出库编号",
	"i18nn_08e5062e3affb676": "贴标数",
	"i18nn_8af1fd820046d3c1": "入库仓库",
	"i18nn_1db6434a8c2ba647": "送仓方式",
	"i18nn_e1e2b21c7b4b951f": "转运方式",
	"i18nn_33a85575edeaacaa": "入库托盘数",
	"i18nn_3d3bc466f13154d4": "柜号",
	"i18nn_696eca4149742942": "是否展示箱子内产品",
	"i18nn_46b944e39511234b": "附加服务",
	"i18nn_9e9277ad9980fd7d": "预计到仓",
	"i18nn_f904142c2f2c8106": "创建转运出库",
	"i18nn_ed74196efa90cded": "编辑转运单",
	"i18nn_004bc5eedbcc75e0": "新增转运单",
	"i18nn_35f54f309c93f9e8": "已接收",
	"i18nn_4413cff8f8a5be70": "部分转运",
	"i18nn_d58a804e17a620ae": "已转运",
	"i18nn_6c1ebb1581a6828e": "选择要运送的货物",
	"i18nn_5caaadc380029e3f": "本次转运箱数",
	"i18nn_d4b6bcb26128130e": "已入库明细",
	"i18nn_e1363c8a04ba532e": "在库数",
	"i18nn_678824ae3065f35b": "锁定数",
	"i18nn_94fb3830c62f2c1c": "可用数",
	"i18nn_fd6620afef3fab05": "出库仓库",
	"i18nn_e1d76a34da86da92": "是否",
	"i18nn_1272ee58ec6ee1ad": "申请号",
	"i18nn_7110aa855f2767a1": "物流号",
	"i18nn_e98a6f2582037336": "装运类型",
	"i18nn_c301b10948889cb5": "计划出库日期",
	"i18nn_e520453b3f22ad66": "地址选择",
	"i18nn_59764ba919e3d70d": "地址名称",
	"i18nn_fa4d50bc26e39b26": "外箱标",
	"i18nn_4de0a112bb772da8": "入库明细",
	"i18nn_7fba76c83eec6d09": "选项",
	"i18nn_e186babc2e9cadf4": "保存并提交",
	"i18nn_b2d4fecb6418af59": "添加的选择的数据",
	"i18nn_3041594b4f6bcf64": "是否存在重复",
	"i18nn_98607e1e7d59bf2c": "存在",
	"i18nn_a8bcab50b1eccec0": "已自动过滤重复数据",
	"i18nn_b66b629b8930650f": "先提交附件",
	"i18nn_355cfd645261c0b5": "回调之后提交新增或者编辑方法",
	"i18nn_26968953ae05b4b7": "出库的",
	"i18nn_a68426dce989b497": "仓库号",
	"i18nn_5ebc284d0e1e5cf4": "请选择要运送的货物",
	"i18nn_cbebee9d601695d5": "货源类型",
	"i18nn_6e33a5e062b119ff": "是否展示箱内物品",
	"i18nn_6ae7ca889532ec40": "缓存",
	"i18nn_ead881df31939a6e": "原数据",
	"i18nn_62a3b0f685e9962c": "表格选择的数据",
	"i18nn_9b0825015c2c49d9": "展示类型",
	"i18nn_96fb4a4cdd7de04b": "跳转到第一页",
	"i18nn_ee62c49d9dae1b96": "转运入库明细",
	"i18nn_7c27cb7b39aa1d82": "已转运入库的货物",
	"i18nn_0bc1b8fb20441d5e": "获取已经选择的数据",
	"i18nn_885206600df4fb3d": "编辑转运入库",
	"i18nn_f6272b352b3f3061": "新增转运入库",
	"i18nn_1f6b9a150926ba64": "到仓时间",
	"i18nn_7836f25126b0e111": "若存在多个物流单号时",
	"i18nn_d9081425db9f2c5f": "请用英文逗号将物流单号分开",
	"i18nn_21d58958f280d153": "快速转运",
	"i18nn_ffe3865fea625e35": "查询地址",
	"i18nn_266578312b060df9": "转运货物信息",
	"i18nn_1baf81dc415067f6": "可批量复制",
	"i18nn_925eaf37d939c1f4": "中数据填入",
	"i18nn_a449b9611b8c347c": "可鼠标右键表格编辑",
	"i18nn_b05fb36dbc990e3b": "保存时请删除空行",
	"i18nn_2b3b632fb77f0712": "可以复制并粘贴",
	"i18nn_705fc0e58821d525": "中数据",
	"i18nn_6172b2d3ca888226": "鼠标右键可添加",
	"i18nn_898fe40df4262eea": "编辑表格",
	"i18nn_6aada61bec5df499": "保存时请删除空白行",
	"i18nn_2a52107e50015b89": "双击可选择或选中数据",
	"i18nn_2063a37475d39272": "添加箱子",
	"i18nn_17768c25d95d04ca": "转运类型",
	"i18nn_7669a6b7a38f1189": "初始化附件上传",
	"i18nn_2b4625e017315798": "改变列隐藏",
	"i18nn_b6cb663839a53fd9": "转运转运单号",
	"i18nn_b1131a0cc3945b5f": "出库单号",
	"i18nn_eed89121cdfb33c3": "备货完成日期",
	"i18nn_54bfc3e9958727d8": "出库完成日期",
	"i18nn_dcc830098ae4c9fd": "待出库",
	"i18nn_a342c84e359f0d02": "已出库",
	"i18nn_95fb447f1661affd": "编辑出库",
	"i18nn_bab35eeb688f9eae": "新增出库",
	"i18nn_7cebbad6657bdbf4": "预计到货日期",
	"i18nn_d420ca5d51e2fc22": "要运送的货物",
	"i18nn_b498df927d973f01": "去选择",
	"i18nn_9d3249981866af60": "货物来源",
	"i18nn_9618edd493c9d6ea": "当前共",
	"i18nn_1ab5fae021b6c8ff": "旋转要运送的货物",
	"i18nn_53976ac09b57e51e": "转运入库的数据",
	"i18nn_87daa7865e42c641": "系统库存",
	"i18nn_b801e4587837fe15": "清空已选择的货物",
	"i18nn_402840e712e7659c": "打开选项抽屉",
	"i18nn_f217cfe6c6291a89": "请选择出库仓库",
	"i18nn_9de263ffbe229226": "请选择货物来源",
	"i18nn_378542a521437fed": "查询对应仓库数据",
	"i18nn_4e36adb4a6d6f9ff": "转运的货物",
	"i18nn_4958725d5bd39384": "库存的货物",
	"i18nn_c996cb376f253cb5": "批量导入",
	"i18nn_844521f361060167": "单条录入",
	"i18nn_7c5912ab3762973a": "批量录入",
	"i18nn_a476975987294b07": "退货管理",
	"i18nn_429b4e0f15fd551d": "系统问题",
	"i18nn_f37cb25b8f6a5486": "可下载模板后",
	"i18nn_a6278bd3526e47e6": "直接导入",
	"i18nn_23b9e07b17dfefc2": "也可以复制粘贴",
	"i18nn_3644a90f9f13f0b8": "工单信息",
	"i18nn_2a714652d0b17e0d": "创建增值服务",
	"i18nn_32a62371f9d0aca8": "订单号",
	"i18nn_8b9b30a5bc0eab3a": "邮件",
	"i18nn_3e429a7c7cf3cab2": "地址二",
	"i18nn_976cf9b5668838a0": "州简称二字码",
	"i18nn_245a46426329f070": "追踪单号",
	"i18nn_8f5ce149c71f00d3": "快递简称",
	"i18nn_488e817afcd3b95e": "是否签名",
	"i18nn_d7aef993e73ebf60": "发货信息",
	"i18nn_388b46f93fbfbf4d": "追踪信息",
	"i18nn_9d3382d5a74e6e34": "自供面单时必填",
	"i18nn_b2b1ac4f554665b1": "保险和签名服务",
	"i18nn_0ffca7433738453f": "只用于平台出单",
	"i18nn_b74bad1a7a5403b1": "入库信息",
	"i18nn_35b7a8c5deb39056": "外箱规格",
	"i18nn_1697895cfac3573c": "当一箱一件时",
	"i18nn_cc492a06237b3169": "外箱规格可不填",
	"i18nn_25528063f1b6a596": "赋值",
	"i18nn_10c015e3241e842e": "州简称",
	"i18nn_e366dc49c78c8e3d": "收货地址",
	"i18nn_514377f02a8481e6": "保存并提交",
	"i18nn_1e9e4c26111fc0e7": "是否仅预览",
	"i18nn_87d44ee5d0d34147": "是否仅仅预览",
	"i18nn_9cdb5c847968eb64": "可直接导入",
	"i18nn_563846f3c8bdd98f": "外箱规格可以不用填写",
	"i18nn_a31d32ac4015d196": "跟踪号",
	"i18nn_6aa78c268e87527b": "货物单号",
	"i18nn_108c7dc6debca017": "柜型",
	"i18nn_43cebc877265943a": "外箱规格单位",
	"i18nn_4843108e7e424145": "外箱毛重",
	"i18nn_f56c111456a351be": "到仓日期",
	"i18nn_4c72d6edbaf60363": "入库单",
	"i18nn_a1ef1454c217a90d": "手动录入",
	"i18nn_07cac86ffb75046b": "发货编号",
	"i18nn_e6e818680d77e014": "条码箱唛",
	"i18nn_6d8f9b0c398319ff": "是否含电池",
	"i18nn_e02b1be61269eb71": "产品已创建成功",
	"i18nn_65faa0f7b990ed30": "请联系客服并等待审核",
	"i18nn_b0493e82a0426f5c": "创建入库预报",
	"i18nn_a946a88e4f41f9c6": "秒后将自动返回",
	"i18nn_726dda93aca054e6": "离开之前",
	"i18nn_b9ee0408e39cb451": "建议按照数字字母编码组合",
	"i18nn_5d819cd0462cbe89": "数字",
	"i18nn_dfa40fe9cb22797d": "字母",
	"i18nn_1786e81d2ba2694a": "组合",
	"i18nn_d9df5070c410e81d": "系统将根据",
	"i18nn_418709fadc1e6c54": "生成标准",
	"i18nn_cef61d13aefc83cf": "仓库将按标准",
	"i18nn_411fdc693cb46984": "未审核",
	"i18nn_528f3ff5da537927": "获得用户权限菜单",
	"i18nn_48fea1870aa235fd": "获得用户权限菜单成功",
	"i18nn_725d750832726cc3": "获得用户权限菜单失败",
	"i18nn_747b33bfa850b075": "获得用户权限菜单请求失败",
	"i18nn_fb7df0fd07958d28": "按照",
	"i18nn_e11679a643689255": "选择面单文件",
	"i18nn_70bd0f4327eda581": "重新提交数据",
	"i18nn_caed6cfc34b968aa": "收件人名称一",
	"i18nn_f9c0ee1156f1e254": "收件人名称二",
	"i18nn_aa2bc6dc3cafeb11": "州二字码",
	"i18nn_2dfd6348829d984f": "发件人名称一",
	"i18nn_e03f8082906c4de8": "发件人名称二",
	"i18nn_edb1b11e6c5ceb01": "最小十位",
	"i18nn_c481683ec9fca419": "操作类型编码",
	"i18nn_94a6bbd4c69bda08": "费用类型编码",
	"i18nn_51c53d67a11837ae": "费用名称编码",
	"i18nn_d8917510efdc82c9": "子账号管理",
	"i18nn_f3df8675ebf8aeb6": "子账号列表",
	"i18nn_22ffd923fd102ddf": "子账号菜单",
	"i18nn_c1cb84ac1ef13c77": "设置菜单",
	"i18nn_766bfac938d98e04": "平台订单拉取",
	"i18nn_898a2833fbeaf9e4": "州省",
	"i18nn_604a0b11e916f387": "密码",
	"i18nn_7a806e59f1e812b7": "费用类型配置",
	"i18nn_4b2d7b585777fa99": "主账户",
	"i18nn_76dc341edac65885": "业务类型",
	"i18nn_c5400c966a9be32d": "操作模块",
	"i18nn_cdd4901a665110a9": "是否子账户",
	"i18nn_2489ac08975334e4": "客户或公司名称",
	"i18nn_e4842b8507dd415c": "公司简称",
	"i18nn_3e98a7801f9d8a6d": "归属仓库",
	"i18nn_f5765bd7956321f2": "查看密码",
	"i18nn_8a2be54a2e609dcc": "子账号账户",
	"i18nn_9ea9185268cf2519": "是否自动账号",
	"i18nn_e78b4579ca11c47f": "子账户简称",
	"i18nn_8664edb3936db8b0": "是否自动生成子账号",
	"i18nn_4438ee6b6104e469": "子账号",
	"i18nn_f1e02877d27204c8": "打开账号选择弹窗",
	"i18nn_c4418aa4798740f5": "权限配置",
	"i18nn_d02053ea2ba9ff5d": "客户菜单配置",
	"i18nn_67cfb454fd187462": "表单提示",
	"i18nn_0b6ece8a034c7796": "可添加一个权限组",
	"i18nn_df812a0da6bcd476": "并为其命名",
	"i18nn_7a9be273020481b8": "方便添加管理员时使用",
	"i18nn_d4573844836678d9": "可在标题处全选所有功能或根据功能模块逐一选择操作权限",
	"i18nn_124961995dbe464b": "提交保存后生效",
	"i18nn_7732c57a7fb0fe19": "子用户",
	"i18nn_e4fce3e8821ac4e3": "管理后台",
	"i18nn_1848b65a9a76cccc": "基础配置",
	"i18nn_e40dab1482ecbe86": "权限组",
	"i18nn_01f47f2bd0c79f92": "菜单",
	"i18nn_8931a531d877d8f7": "的数组",
	"i18nn_3f92cfa1112482ad": "请输入权限组",
	"i18nn_b4bb025e18029ea2": "数组去重",
	"i18nn_1b34adde1d3c15c2": "删除数组中存在的另一个数组",
	"i18nn_cb4296d31d8d364b": "判断数组中是否存在某值",
	"i18nn_9146c3ccdbe784d6": "节点选择值改变",
	"i18nn_b30018eb83010fec": "节点选择",
	"i18nn_40a663fc001afe0b": "一级菜单",
	"i18nn_fdc6f9307b183261": "是否选中",
	"i18nn_12a4499cf846736f": "子节点",
	"i18nn_1fe3d7b3eb1db3d8": "选中",
	"i18nn_7a5458f8a98af422": "全部选中下面子节点",
	"i18nn_f6b7b613680677c5": "取消选中",
	"i18nn_f979c31ebd810e6e": "全部取消选中下面子节点",
	"i18nn_5edf9f72008ffba3": "菜单选择",
	"i18nn_7d689a2358e2dca2": "点击父节点",
	"i18nn_781134873aebe2a7": "存在子节点",
	"i18nn_7b4a77d9be8217c9": "点击子节点",
	"i18nn_dbdf33f6dce59a29": "展开",
	"i18nn_58f25b8feab039d3": "把勾选的值设置为显示",
	"i18nn_d47f3e0b7855dd5f": "提交有误",
	"i18nn_6a944013cd8a67ef": "查询菜单接口",
	"i18nn_e582b4c8c2db445e": "展开项",
	"i18nn_86ac14b63f2ae88d": "非展开项不可选择",
	"i18nn_48557aa1d205c240": "是仓库菜单",
	"i18nn_2c34971d7f66bc6e": "二级菜单",
	"i18nn_5d6fde2581708559": "查询菜单接口失败",
	"i18nn_bba8d0a8bb054bf5": "查询详情接口",
	"i18nn_d5610f2f4144069b": "查询菜单",
	"i18nn_5c4a773bafcfe7bd": "勾选",
	"i18nn_4fd3a7662429a107": "显示的值",
	"i18nn_4c2c80cd8bb476a3": "用户菜单配置",
	"i18nn_b7f118c3e8c6d135": "查询用户详情失败",
	"i18nn_6783c8d6f7c9a7e8": "才有柜型",
	"i18nn_9f88e3e6b1e719bd": "选择渠道",
	"i18nn_0b2cd5cfcfebe08e": "易仓库存同步",
	"i18nn_0640e3814d107002": "现有库位库存",
	"i18nn_68b3bb1ef6931076": "当前锁定库存",
	"i18nn_d91fd63094e82992": "当前有效库存",
	"i18nn_dfd9cde6fbd425c7": "是否可同步",
	"i18nn_3bae5212138a7193": "差异数",
	"i18nn_ddecfa4a8d6bb743": "易仓可用数",
	"i18nn_73e14fb8e120ce61": "库存来源",
	"i18nn_07048291fdb26b6e": "同步",
	"i18nn_62519f438e8f64b5": "是否回传单号",
	"i18nn_296b2c6a46764af7": "是否自动同步",
	"i18nn_d165fe5a9a65fb8c": "同步方法",
	"i18nn_219c1f2a0f4526d5": "子菜单",
	"i18nn_50b0646769862d34": "勾选父级菜单",
	"i18nn_0d81640498b8d063": "查看附件",
	"i18nn_54e4d834b7e2c26a": "盘亏",
	"i18nn_38624a6aa0667143": "盘盈",
	"i18nn_fd445581cff561bf": "增值服务工单",
	"i18nn_dd697b8fa767da8b": "待客服处理",
	"i18nn_42d98013e99e7380": "待仓库处理",
	"i18nn_0174ec83eeb25914": "确定撤销吗",
	"i18nn_2d34f0565cfc4473": "选择已有库存的",
	"i18nn_f0a1ff9d4d865b10": "是否已经存在此",
	"i18nn_650abf9b436446ba": "选择库存",
	"i18nn_89304b37afa2f1b0": "当前",
	"i18nn_578842e17fea3b6a": "线路一",
	"i18nn_cb143acd6c929826": "中国",
	"i18nn_4fd9ca7a902c7edb": "线路二",
	"i18nn_09b2a7a32b3c8db1": "美国",
	"i18nn_4b52c5caf5562c6e": "打单数据",
	"i18nn_ec5cc7f6883ccae7": "选择的渠道",
	"i18nn_7845bbfff6467299": "所有渠道",
	"i18nn_03277940e613487c": "当前操作的数据",
	"i18nn_00b325af374656d9": "渠道查询",
	"i18nn_e04e7bab111a879b": "旧",
	"i18nn_ace012504bc529e0": "消息",
	"i18nn_c3525eb110db58aa": "更多",
	"i18nn_6e961a14e09d9784": "消息通知列表",
	"i18nn_b40debd910bb74f2": "通知对象",
	"i18nn_2528332bfcac14b5": "消息类型",
	"i18nn_db1f861ffd10f028": "通知标题",
	"i18nn_bd0db0ad240cc83b": "内容",
	"i18nn_7df9df0664f60cd8": "内容查看",
	"i18nn_44f6051765cf57e0": "选择已有的",
	"i18nn_824815ceb3f6c6db": "已选择",
	"i18nn_1ff0f0c0745be010": "已显示",
	"i18nn_f7fac56017a0ca84": "更多请",
	"i18nn_34ef906a3a954ad8": "更多请搜索",
	"i18nn_9834e54432d0c672": "加载完成",
	"i18nn_08231eb7a4fcf2a3": "不再加载",
	"i18nn_1bab2e4f2c65c40f": "入库打托数",
	"i18nn_5471f398d8acf5fc": "第一条直接录入",
	"i18nn_4ddd2d2b6323a567": "其他插入新数据",
	"i18nn_87f891127ffff1f8": "仓库货量统计",
	"i18nn_c1b676e21af630d2": "总库存",
	"i18nn_158ecb8cbcabc069": "费用业务",
	"i18nn_3107835382623271": "请用英文逗号分隔多个物流单号",
	"i18nn_fc554f12d8734681": "是否启用",
	"i18nn_f4af8c42d7478c6f": "更新目的地",
	"i18nn_c7948588841d75cc": "库存同步",
	"i18nn_65ff76c08629a2c8": "复核箱数",
	"i18nn_74c2b69060be9bfe": "收货清单列表",
	"i18nn_579f43c8c657bbbc": "目的仓库编号",
	"i18nn_81a7c94b5c510304": "目的",
	"i18nn_8b3dbf4f5793930b": "目的地",
	"i18nn_f5ca68b04fa47a59": "是否填写目的地",
	"i18nn_c6bf739e696c2bfc": "下次选择的名称",
	"i18nn_af48c37c06629ec5": "出库箱数",
	"i18nn_f88c31ed3971ca4d": "步骤",
	"i18nn_1408336bd263e043": "出库列表",
	"i18nn_4118399786a5251a": "库存列表",
	"i18nn_54be52f6d5b4641a": "出库中",
	"i18nn_8e23aeb472e03f34": "待出库列表",
	"i18nn_6feb2a7c40070fa2": "打单信息",
	"i18nn_80755d56db88fca2": "请填写出库箱数",
	"i18nn_f608e17fc712987a": "在库库存",
	"i18nn_92958dfd9227a641": "尾程信息",
	"i18nn_2fc069db0549ff40": "库存详情",
	"i18nn_f1c73718e1c8fcb7": "入库来源",
	"i18nn_1829e02aab962bd5": "收货编号",
	"i18nn_c62b0d9279f5e7cd": "第三方库存信息",
	"i18nn_560595129574513f": "处理日志",
	"i18nn_e46ef79830a8e08c": "查看处理日志",
	"i18nn_cddbe40f9813ab94": "签收时间",
	"i18nn_9db0c5231c087a3e": "让世界厂家交互起来",
	"33bae59bb784a109": "抱歉，提交失败,请重试！",
	"i18nn_889b3ef0590d9f54": "启用",
	"i18nn_b6a8725d78c586be": "未上线快递运单",
	"i18nn_8f89632c68d3b9a2": "延期天数",
	"i18nn_7689a837889ed035": "快递运单",
	"i18nn_28c0ca2da0ad71dc": "未上线",
	"i18nn_e00cd157c0ac4d1f": "新增地址",
	"i18nn_4672c6113ffede32": "预约出库",
	"i18nn_cc335ba8f4b2cc0d": "预约编号",
	"i18nn_a29209bd396e4bdd": "预约方式",
	"i18nn_5cd591b578350972": "地址编号",
	"i18nn_934695e82afbe756": "出库指令是否下达",
	"i18nn_1d9d50fca9ffeb39": "预约是否完成",
	"i18nn_c5e66971134b7300": "预约完成时间",
	"i18nn_b2d95e887d5425b7": "预约详情",
	"i18nn_994551e2ae3176ca": "明细列表",
	"i18nn_dae2f81921b3b3c0": "编辑预约",
	"i18nn_d992d16df3748947": "新增预约",
	"i18nn_59550e3d8c8088f9": "预约",
	"i18nn_e93cb6cc8d5978ed": "请输入密码",
	"i18nn_9cb1c9c51e7503d6": "确认密码",
	"i18nn_7f7ce15a429dbf34": "请确定密码",
	"i18nn_b458f03c43a90ee9": "拣货单号",
	"i18nn_56b48b3b6de43bde": "分批转运入库",
	"i18nn_e69eafd9946571ed": "分批转运出库",
	"i18nn_5ddc19a148f5e5c1": "退货入库",
	"i18nn_886ea69af099215d": "预约托数",
	"i18nn_611f87335433e0c3": "到仓方式",
	"i18nn_b2e9a0ce00229898": "可明确操作指令",
	"i18nn_b195f5d99d869b3f": "比如",
	"i18nn_43cef0e6de330163": "贴标要求",
	"i18nn_137d90eaafe33693": "贴标数量",
	"i18nn_0f03549000bc5f2d": "贴标方式",
	"i18nn_b95b66c11ead8aac": "标",
	"i18nn_54f17bfe03d79b8a": "箱标",
	"i18nn_1d5ba91957adcff9": "托盘标",
	"i18nn_c8c17c7e3fbf62f1": "等",
	"i18nn_34cc4b37bb2d93a7": "打托要求",
	"i18nn_4721a0daf4241a40": "是否需要拍照",
	"i18nn_6c1e4bd2907c0a3d": "体积测量",
	"i18nn_10abe7527d9d0f26": "重量测量等其他需要仓库操作的事项",
	"i18nn_fa02547543cff0f7": "是否换标",
	"i18nn_c617dfa4c0b5ecb4": "系统编号",
	"i18nn_43f2b49eef6bda66": "预约明细",
	"i18nn_515bc077445e40dc": "预约状态",
	"i18nn_61c33362d88eb0ba": "来源",
	"i18nn_b7a786a287eb00e4": "是否预约",
	"i18nn_fb6105f4b294a9d0": "提货时间",
	"i18nn_b7f9db36db186f72": "预约操作",
	"i18nn_ea881f28aae703af": "预约类型",
	"i18nn_67a39834582ad99a": "暂无数据",
	"i18nn_e69b8beb7706725b": "收货日期",
	"i18nn_da1ca7369f4bde15": "预约单",
	"i18nn_68fd8440c5a9dd35": "提货完成",
	"i18nn_2bcdd4344979655f": "预约完成",
	"i18nn_e90a876d882d595a": "展开预约明细",
	"i18nn_42274ccae6a91180": "预约出库编号",
	"i18nn_db9b1bd30ea188f8": "打印拆柜单",
	"i18nn_e99e3c2ab95512c3": "拆柜完成",
	"i18nn_2928fee14cabb006": "请后续操作",
	"i18nn_13247dc8e601ddef": "转运入库柜号",
	"i18nn_40859a22ab42e313": "到仓库日期",
	"i18nn_b6e1497fc95f6691": "完成日期",
	"i18nn_f87d23ab43f0b299": "可预约出库列表",
	"i18nn_888c8eeffccf0077": "显示零库存",
	"i18nn_012297a66930e480": "确定更新密码吗",
	"i18nn_c0d09a392d8acf54": "更新成功",
	"i18nn_6724e693fadf9a09": "是否去重新登录",
	"i18nn_b781fc794861218f": "拣货方式",
	"i18nn_84599b8cfb3aa3b9": "仓库货量体积",
	"i18nn_90056eff057da3d0": "仓库货量重量",
	"i18nn_79d40c126a46dd61": "请",
	"i18nn_56aa8ea768a4c6bd": "鼠标右键",
	"i18nn_17700f3f4a7ee5f6": "单元格",
	"i18nn_78897452dfd5c501": "如需更多操作",
	"i18nn_f1b23358832dfa49": "查看上方",
	"i18nn_12f42056f558e6f6": "如果是私人地址",
	"i18nn_05a8d5e71aabbcdd": "请按格式填写",
	"i18nn_5eb29146a398224e": "卡派",
	"i18nn_bfcb068b25558f57": "预约派送是",
	"i18nn_96b7fef9ab4cc937": "仓库时",
	"i18nn_af757a29a5046ebe": "未知的索引数据",
	"i18nn_f6f129c36d0c471e": "不能超过",
	"i18nn_cc6963cfe06fc064": "个批次",
	"i18nn_e19edf6996055dc5": "确定删除该条吗",
	"i18nn_771752e23324e1c4": "更新地址失败",
	"i18nn_77c91da599d5b4b6": "创建成功",
	"i18nn_5b177a1b00c63503": "港口提柜",
	"i18nn_9a716c393c296ea8": "尾程卡派",
	"i18nn_600c6fad90d0d7b1": "账单状态",
	"i18nn_15a021996904c423": "确认状态",
	"i18nn_8468ac93161085d6": "优先操作",
	"i18nn_27e24ff9191e254d": "有",
	"i18nn_19ec51d5bd5fc832": "没有",
	"i18nn_7a29cab1a4b8f231": "是否优先操作",
	"i18nn_20650eaf339e1fe6": "联系人",
	"i18nn_c45d595e849aec6a": "添加预约单",
	"i18nn_7f0e36c0c32152b9": "自提单号",
	"i18nn_a1c919aef2dec6d4": "新增明细",
	"i18nn_d256e16919f5fceb": "预约数据",
	"i18nn_4f5f83fc6780a29c": "选择货物明细",
	"i18nn_197e2039cf365afa": "仓库打托数",
	"i18nn_83125000f5142ed1": "入仓前托盘数",
	"i18nn_6fd4956e676cec49": "合并",
	"i18nn_0529f4753fb3c5a0": "大小",
	"i18nn_61ebcde89251413e": "确认合并",
	"i18nn_facd05c043886557": "合并部分行",
	"i18nn_1861b71eeabd88b9": "不合并",
	"i18nn_54dc58d9d0377bb5": "删除失败",
	"i18nn_b8b6bfc4485da611": "相关",
	"i18nn_5b16dd13f1bc0e1c": "多订单号搜索",
	"i18nn_33bb5e57aba192e1": "订单号输入",
	"i18nn_b92065474c13bf38": "一行输入一个订单号",
	"i18nn_b5511889be42a3da": "确定并搜索",
	"i18nn_da7eef74bb8e0f6d": "仓库完成时间",
	"i18nn_98ea9b63e41e8543": "长宽高",
	"i18nn_d12a2c11456e7f47": "到车日期",
	"i18nn_c5378920e015b12f": "选择面单",
	"i18nn_b539914bf0af5314": "校验并匹配",
	"i18nn_470fa451f57e6fff": "识别运单号自动匹配",
	"i18nn_2428648cafd20f21": "上传匹配的面单",
	"i18nn_eb680d44b667d34e": "根据运单号识别",
	"i18nn_53f9c1e720d261f9": "可自动分割面单至各个订单",
	"i18nn_39df26e0051d7903": "确认保存",
	"i18nn_349ee56d6d895249": "文件最大",
	"i18nn_1af19e9a72a235ba": "提交并校验",
	"i18nn_379c907d12f5c9f5": "匹配成功",
	"i18nn_a5d008a482d2b005": "所属页数",
	"i18nn_61d278478046ceaf": "文件流",
	"i18nn_b14b20e17d756cb2": "匹配失败",
	"i18nn_cc3cb77aad104caa": "重新选择",
	"i18nn_7c9926ac60a726ac": "上传匹配成功的面单",
	"i18nn_8433ab19cdbad17e": "上传状态",
	"i18nn_933692e63faf4555": "查看匹配",
	"i18nn_f524f7dafdd60a7a": "保存已匹配的面单",
	"i18nn_b109726c562cc092": "无匹配的数据",
	"i18nn_0bf3695809741cae": "未知的",
	"i18nn_247c878546702010": "根据运单号自动识别并保存",
	"i18nn_dfaa3ac89594cfe8": "上传完后",
	"i18nn_e7ce2fa6da443509": "请等待结果",
	"i18nn_8054e6a00f7fb9c7": "预计",
	"i18nn_ced2ef6d0ce80ac1": "秒内匹配完成",
	"i18nn_12fde6f4ac0bd2a7": "文件越大等待越长",
	"i18nn_926b52e41c45d835": "上传并匹配",
	"i18nn_8aad52f19406c72d": "历史匹配记录",
	"i18nn_d426ae19e98559b5": "总页数",
	"i18nn_75247a5e05331f7f": "匹配详情",
	"i18nn_46d9c96bfde09a4a": "请选择文件",
	"i18nn_60c521726fa38f44": "最多显示",
	"i18nn_9e0c510eaed20bc7": "刷新匹配结果",
	"i18nn_e5e53c1aa28e6682": "文件名匹配订单号",
	"i18nn_127af9ed95c3c6be": "按文件名匹配订单号",
	"i18nn_2fe088ec1114c539": "按运单号自动匹配",
	"i18nn_3d189e6fa5541753": "确定同步导入",
	"i18nn_8b66deca86e6d66d": "请输入二字码",
	"i18nn_40ac872c0aa6ff41": "条形码下载",
	"i18nn_6c2519a4169a3bd4": "下载提示",
	"i18nn_83130aa2fa9470ae": "数据量大时",
	"i18nn_f57015be50941d22": "浏览器会卡住",
	"i18nn_e1214c4d8b4e9626": "请稍等",
	"i18nn_1c4cee3493d17175": "等待时间过长时",
	"i18nn_35c67777896ab842": "建议关闭页面重新进入",
	"i18nn_c121c5915b23c201": "然后筛选后",
	"i18nn_e1c83961e7a8f928": "分批下载",
	"i18nn_f5e108e4d43c2a4b": "只下载前",
	"i18nn_c11ab9cc6f2492e6": "请筛选后",
	"i18nn_4ea50b32216e5504": "码过长时",
	"i18nn_dc88ac4413b2bf37": "生成的",
	"i18nn_fb870f042cdc0b90": "码图片也会很长",
	"i18nn_7ce36744aeec211a": "会缺失",
	"i18nn_e4f56cecd0453bbf": "建议下载",
	"i18nn_375809e1d450d562": "或直接右键该码保存为图片",
	"i18nn_5f7f4143c0564b54": "加载第",
	"i18nn_7215a6d646ce357e": "当前可下载第",
	"i18nn_90aa9ed0f2de2aa5": "操作说明",
	"i18nn_110b67a13dd38b1e": "原",
	"i18nn_788d9bdcf8ff660e": "原数量",
	"i18nn_8544666ef2803c20": "如需下载",
	"i18nn_ea80f4411a8408b8": "目标打印机",
	"i18nn_1cd45ccce46f619e": "另存为",
	"i18nn_6f9c5b165f3bc662": "还可以去掉",
	"i18nn_f54ebccbce73a3d6": "页眉页脚",
	"i18nn_7cbe47cdcaa28905": "列表数据",
	"i18nn_cba3f5ca1278aa85": "加载中",
	"i18nn_c38a2f9ec3b8d17b": "查询数据字典接口失败",
	"i18nn_4bf00570023be25f": "邀请人账号",
	"i18nn_94a8da065245abe4": "账号类型",
	"i18nn_474da87fdb795187": "必须为邮箱",
	"i18nn_2fca45d67fdcae33": "邮箱格式有误",
	"i18nn_d49a93bb265a311d": "验证码",
	"i18nn_3f6074f3db84a466": "将文件拖到此处",
	"i18nn_59bcca694e60fcfd": "或点击",
	"i18nn_43c20b6ea93b4b20": "是否新品",
	"i18nn_784a9e148dbadc4a": "箱唛设计",
	"i18nn_a85af4ba66084d28": "尺寸",
	"i18nn_d79e2ed6a2fc66fd": "显示",
	"i18nn_a3ed58117fd81192": "显示客户",
	"i18nn_ee7bdbe4f27e38df": "附加信息",
	"i18nn_4b5536ea9955714b": "记录编号",
	"i18nn_287b771e159a7dc2": "查看工单",
	"i18nn_9461d15f7ba8ad99": "地址拼接",
	"i18nn_8c7713cae892514d": "匹配统计",
	"i18nn_b3c2b18eb57891e5": "智能匹配运单号并拆分",
	"i18nn_3a1e63b67d26386a": "同步信息",
	"i18nn_9ac9d5c058d1465d": "互易单号",
	"i18nn_7a6f218c034d0ecd": "三方仓库编号",
	"i18nn_81c10442a4bd4247": "三方编码",
	"i18nn_18f412662f2b18ba": "工单明细",
	"i18nn_829a74d12dc6905b": "校验查询",
	"i18nn_47c222b26ca4ce78": "条码设计",
	"i18nn_72d35d210669abb2": "预报数量",
	"i18nn_4567a206c45dc3f4": "实收数量",
	"i18nn_d8d0c7c9b9ee194d": "收货附件",
	"i18nn_084d695b245685c3": "移至异常",
	"i18nn_735df2dca58c4f9b": "重新提交",
	"i18nn_c7e739a5ba70db03": "确定移至异常吗",
	"i18nn_412f179fdcd6871c": "确定重新提交吗",
	"i18nn_46ed817ece2a5540": "已锁定库存",
	"i18nn_0f4a445be54ed8ae": "锁定状态",
	"i18nn_e1a3fd479469bc3b": "预报仓",
	"i18nn_0772a37dc4042001": "发货明细",
	"i18nn_67d0b6c398583e40": "锁定数量",
	"i18nn_ebddf036087d3618": "锁定类型",
	"i18nn_c301b22bd36255c8": "处理状态",
	"i18nn_d764dccdd0a96427": "已锁定库存明细"
};