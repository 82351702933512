import { render, staticRenderFns } from "./BillTransshipmentFast.vue?vue&type=template&id=3fb3544c&scoped=true"
import script from "./BillTransshipmentFast.vue?vue&type=script&lang=js"
export * from "./BillTransshipmentFast.vue?vue&type=script&lang=js"
import style0 from "./BillTransshipmentFast.vue?vue&type=style&index=0&id=3fb3544c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fb3544c",
  null
  
)

export default component.exports